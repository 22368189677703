import React, { Component } from 'react';
import * as Ant from 'antd';


class Tabs extends Component {
    constructor(){
        super()
        this.state = { size: 'small' };
        this.onChange = this.onChange.bind(this)
    }
    
    onChange (e) {
        this.setState({ size: e.target.value });
    }
    
    render() {
        const { size } = this.state;

        const { TabPane } = Ant.Tabs;

        return (
            <Ant.Card>
                <Ant.Tabs defaultActiveKey="1" size={size} renderTabBar={()=><div> here</div>}>
                    {this.data.map(()=>

                        <TabPane tab="Tab 1" key="1">Content of tab 1</TabPane>
                    )}
                </Ant.Tabs>
            </Ant.Card>
        );
    }
}

export default Tabs