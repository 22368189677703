
import store, {actionTypes} from 'store'
import {universities} from '../apiClient'
import debounce from 'debounce'

export const setFilter = (target, payload)=>{
    store.dispatch({
        type: 'schools/filter/set',
        target,
        payload
    })
    fetchSchools()
}

export const setFilters = (...actions)=>{
    for(let action of actions){
        const [target, payload] = action
        store.dispatch({
            type: 'schools/filter/set',
            target,
            payload
        })        
    }
    fetchSchools()
}

export const setFilterDebounce = (target, payload)=>{
    store.dispatch({
        type: 'schools/filter/set',
        target,
        payload
    })
    debounce(fetchSchools, 1200)
}

export const resetFilters = ()=>{
    store.dispatch({
        type: 'schools/filter/reset'
    })
    fetchSchools()
}




export const fetchSchools = ()=>{
    const {schoolsFilter} = store.getState()
    
    universities(schoolsFilter).then((request)=>{
        store.dispatch({
            type: actionTypes.SET_SCHOOLS,
            payload: request.data
        })
    })
}