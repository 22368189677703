/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 *
 * @see You can view component api by: https://github.com/ant-design/ant-design-pro-layout
 */
import MainLayout from 'components/MainLayout'
import { Layout, Menu, Modal } from 'antd';
import { UserOutlined, LaptopOutlined, NotificationOutlined } from '@ant-design/icons';
import React from 'react';
import { GithubOutlined, AreaChartOutlined } from '@ant-design/icons';
// import RightContent from '@/components/GlobalHeader/RightContent';
import Logo from '../Logo';
import './index.less'
import { withRouter } from 'react-router-dom';
import {sessionActions} from '../../../../actions'
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default withRouter(({children, history, match})=>{
    const handleSelect = (option)=>{
        if(option.key != "logout"){
            history.push(`/${option.key}`)
        }
    }

    let currentPage = 'admin';
    if(match.path.split('/')[1] == 'users') currentPage = 'admin/users'

    const handleLogout=()=>{
        Modal.confirm({
            title: 'Logout',
            content: 'Are you sure you want to log out?',
            onOk() {
                sessionActions.logout()
            },
            onCancel() {
                // console.log('Cancel')
            }
        })
    }

    return (
        <Layout>
            <Header className="header">
                <Logo />
                <Menu onSelect={handleSelect} mode="horizontal" defaultSelectedKeys={['admin']} style={{float: 'right'}}>
                    <Menu.Item key="admin">Admin</Menu.Item>
                    <Menu.Item key="scores">Your Scores</Menu.Item>
                    <Menu.Item key='logout' onClick={handleLogout}>Logout</Menu.Item>
                </Menu>
            </Header>
            <Layout>
                <Sider width={200} className="site-layout-background" style={{minHeight: '100vh'}}>
                    <Menu
                        onSelect={handleSelect}
                        mode="inline"
                        defaultSelectedKeys={[currentPage]}
                        // defaultOpenKeys={}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        <Menu.Item key="admin"><AreaChartOutlined /> Dashboard</Menu.Item>
                        <SubMenu key="admin/users" icon={<UserOutlined />} title="Users">
                            <Menu.Item key="admin/users"> All / Search</Menu.Item>
                            <Menu.Item key="admin/users/invite">Invitation Code</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Content
                        className="admin-site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
})