import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Alert } from 'antd'
import {sessionActions} from 'actions'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { Redirect, withRouter } from 'react-router-dom'
const FormItem = Form.Item

class AccountFieldsForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      confirmDirty: false
    };
    
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
    this.compareToFirstPassword = this.compareToFirstPassword.bind(this);
    this.validateToNextPassword = this.validateToNextPassword.bind(this);
    this.resetError = this.resetError.bind(this);
    this.formRef=React.createRef()
  }

  handleOnSubmit(values) {
  
    sessionActions.register(values).then(()=>{
      this.props.history.push('/schools')
    })
  }

  handleConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword(rule, value, callback) {
    return new Promise((resolve, reject)=>{

      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        reject('Passwords must be the same.');
      }  else{
        resolve(value)
      }
    })
  }

  validateToNextPassword(rule, value) {
    return new Promise((resolve, reject)=>{

      const form = this.form;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm']).then((values)=>{
          resolve(values)
        }).catch(reject)
      }
      resolve()
    })
  }

  resetError(e) {
    if (this.props.session.registerError) {
      sessionActions.reset();
    }
  }
  get form(){
    return this.formRef && this.formRef.current
  }
  render() {
    let registerAlert; 
    if (this.props.session.registerError) {
      registerAlert = <Alert type='error' message='Email is already registered. Please login.' />; 
    } 
    return (
      <Form ref={this.formRef} onFinish={this.handleOnSubmit} onFinishFailed={console.error}>
        {registerAlert}
        <br />
        <FormItem
          name='email'
          rules={[{ 
            required: true, type: 'email', message: 'Please input a valid email.' 
          }]}
        >
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }}  />} 
                  placeholder='Email' 
                  onChange={this.resetError} />
        </FormItem>
        <FormItem
          name='password'
          rules={[
            { 
              required: true, message: 'Please input a valid password.' 
            }, 
            // {
            //   validator: this.validateToNextPassword,
            // },
            {
              validator: this.compareToFirstPassword
            }
          ]}
        >
            <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}  />} 
                   placeholder='Password' 
                   type='password' />
        </FormItem>
        <FormItem
          name='confirm'
          rules={[
            { 
              required: true, message: 'Please input a valid password.' 
            }, {
              validator: this.compareToFirstPassword,
            }]
          }
        >
            <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
                   placeholder='Confirm password' 
                   type='password'
                   onBlur={this.handleConfirmBlur} />
        </FormItem>
        <FormItem>
          <Button
            type='primary'
            htmlType='submit'
            size='large'
          >
            Register
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const AccountFields = AccountFieldsForm;

export default withRouter(connect((state) => state)(AccountFields));