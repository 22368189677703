import store, {actionTypes} from 'store'

import {universities} from '../apiClient'

export const setSchools = (school)=>{
    universities().then((request)=>{
        store.dispatch({
            type: actionTypes.SET_SCHOOLS,
            payload: request.data
        })
    })
}

setSchools()