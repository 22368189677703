import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Alert } from 'antd'
import { LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import {sessionActions} from '../../actions/index'
const FormItem = Form.Item

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {}
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChange(e) {
    if (this.props.session.loginError) {
      sessionActions.reset();
    }
  }

  handleOnSubmit(values) {
    if(values.password == values.passwordReset){
      sessionActions.setPassword(values).then(()=>{
        this.props.history.push('/login')
      })
    }else{
      this.setState({
        passwordsMustMatch: true
      })
    }
  }
  render() {
    return (
      <Form onFinish={this.handleOnSubmit}>
        {this.state.passwordsMustMatch && (
          <Alert type='error' message='Passwords must be the same..' />
        )}
        <br />
        <FormItem
          name='password'
          rules={[{ required: true, message: 'Please input a valid password.' }]}
        >
          <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' type='password' onChange={this.handleOnChange} />
        </FormItem>
        <FormItem
          name='passwordReset'
          rules={[{ required: true, message: 'Please input a matching password.' }]}
        >
          <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' type='password' onChange={this.handleOnChange} />
        </FormItem>
        <FormItem>
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            size='large'
          >
            Reset Password
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const CustomizedForm = LoginForm

export default connect((state) => state)(CustomizedForm);