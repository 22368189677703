import React from 'react'
import { connect } from 'react-redux'
import { Form, Select, Divider, Col, Row, Steps, Card, Input, Tabs, Radio } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';

import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity, Upload, Button } from 'components/elements'
import {Link} from 'components/elements'
import moment from 'moment'
import {surveyActions} from '../../../../actions'
import _ from 'lodash'
import * as utils from 'utils'
import {favorites} from 'actions'
const TabPane = Tabs.TabPane
const Step = Steps.Step
const Option = Select.Option;
const RadioGroup = Radio.Group;
class Academics extends React.Component {
  constructor(props){
    super(props)

    let APCount = 0;
    let APExams = Object.keys(props.survey.academics || {}).reduce((acc, a)=>{
      if(a.includes('apExamsScore')){
        let id = a.replace('apExamsScore', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(APExams.length != 0){
      APCount = _.sortBy(APExams, 'id').reverse()[0].id
    }
    
    let SATCount = 0;
    let SATTests = Object.keys(props.survey.academics || {}).reduce((acc, a)=>{
      if(a.includes('satExamsScore')){
        let id = a.replace('satExamsScore', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(SATTests.length != 0){
      SATCount = _.sortBy(SATTests, 'id').reverse()[0].id
    }

    this.state = {
      end: 10,
      SATTests,
      APExams,
      APCount,
      SATCount,
      SATACTTest: 'SAT'
    }

    this.moreSchools = this.moreSchools.bind(this)
    this.addToFavorites = this.addToFavorites.bind(this)
    this.removeFromFavorites = this.removeFromFavorites.bind(this)
    this.calculateOdds = this.calculateOdds.bind(this)
    this.addToSATTests = this.addToSATTests.bind(this)
    this.addToAPExams = this.addToAPExams.bind(this)
    this.removeAPExam = this.removeAPExam.bind(this)
    this.removeSATTest = this.removeSATTest.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSATACT = this.handleSATACT.bind(this)
  }
  get formRef(){
    return this.props.formRef
  }
  moreSchools(e){
    e.preventDefault()

    this.setState({
      end: this.state.end + 10 
    })
  }
  addToFavorites(school){
    this.props.addToFavorites(school)
  }
  removeFromFavorites(school){
    this.props.removeFromFavorites(school)
  }
  calculateOdds(){
    this.props.history.push('/academics')
  }
  addToSATTests(type){
    const SATCount = this.state.SATCount + 1
    this.setState({
      SATTests: [...this.state.SATTests, {id: SATCount}],
      SATCount
    }, ()=>{
      this[`SAT-${type}-${SATCount}`].input.focus()
    })
  }
  addToAPExams(type){
    const APCount = this.state.APCount + 1
    this.setState({
      APExams: [...this.state.APExams, {id: APCount}],
      APCount
    }, ()=>{
      this[`AP-${type}-${APCount}`].input.focus()
    })
  }
  removeSATTest(id){
    let SATTests = [...this.state.SATTests]
    SATTests = SATTests.filter((a)=> a.id !== id)

    this.setState({
      SATTests
    })
  }
  removeAPExam(id){
    let APExams = [...this.state.APExams]

    APExams = APExams.filter((a)=> id !== a.id);
    
    this.setState({
      APExams
    })
  }
  handleSubmit(values){
    this.props.handleSubmit(values)
  }
  handleSATACT(e){
    this.setState({
      SATACTTest: e
    })
  }

  componentWillReceiveProps(nextProps){
    let APCount = this.state.APCount
    let APExams = this.state.APExams
    const _APExams = Object.keys(_.get(this, 'props.survey.personalInformation', {})).reduce((acc, a)=>{
      if(a.includes('apExamsScore')){
        let id = a.replace('apExamsScore', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    const nextAPExams = Object.keys(_.get(nextProps, 'survey.personalInformation', {})).reduce((acc, a)=>{
      if(a.includes('apExamsScore')){
        let id = a.replace('apExamsScore', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(_APExams.join(' ') !== nextAPExams.join(' ')){
      APExams = nextAPExams
    }

    if(APExams.length != 0){
      const _APCount = _.sortBy(APExams, 'id').reverse()[0].id
      const __APCount = _.sortBy(APExams, 'id').reverse()[0].id
      if(_APCount < __APCount){
        APCount = __APCount
      }else{
        APCount = _APCount
      }
    }

    this.setState({
      APExams,
      APCount
    })
  }
  get favorites(){
    return _.get(this, 'props.favorites', [])
  }
  render() {
    const selectedNames = this.favorites.map((school)=> school.name)
    let data = _.get(this, 'props.schools', [])
    data = data.filter((school)=>
      !selectedNames.includes(school.name)
    ).slice(0, this.state.end)

    const grades =[
      {name: 9, value: 9},
      {name: 10, value: 10},
      {name: 11, value: 11},
      {name: 12, value: 12}
    ]
    
   const rank =   [
      {
        name: 'Top 1%',
        value: 1
      },
      {
        name: 'Top 5%',
        value: 5
      },
      {
        name: 'Top 10%',
        value: 10
      },
      {
        name: 'Top 25%',
        value: 25
      },
      {
        name: 'Top 50%',
        value: 50
      },
      {
        name: 'Below 50%',
        value: '< 50'
      }
    ]

    const year = parseInt(moment().format('YYYY'))
    const years = utils.times(5).map((i)=>({ name: year + i, value: year + i}))
    
    return (
  
        <Form name={'Academics'}>
          <Row gutter={20}>
            <Col sm={{span: 24}}>
              <Card>
                <h1 style={{textAlign: 'center'}}>Academics</h1>
                <br />
                <br />
                <h2 className='text-center'>High School</h2>
                <br />
                <h3>Name</h3>
                <Form.Item
                  name='name'
                  rules={[{ required: true, message: 'Please input a valid value.' }]}
                  initialValue ={_.get(this, 'props.survey.academics.name')}
                >
                  <Input placeholder='High School Name' />
                </Form.Item>
                <h3>City</h3>
                <Form.Item
                  name='city'
                  rules={[{ required: true, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.academics.city')}
                >
                  <Input placeholder='High School City' />
                </Form.Item>
                <h3>High School State</h3>
                <Form.Item
                  name='state'
                  initialValue={_.get(this, 'props.survey.academics.state')}
                >
                  <States fullWidth={true} type='primary' />
                </Form.Item>
                <h3>Grade</h3>
                <Form.Item
                  name='grade'
                  rules={ [{ required: true, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.academics.grade')}
                >
                    <Select placeholder={'Grade'}>
                      {grades.map((grade, i)=>
                        <Option key={i} value={grade.value}>{grade.name}</Option>
                      )}
                    </Select>
                </Form.Item>
                <h3>Graduation Year</h3>
                <Form.Item
                  name='graduationYear'
                  rules={[{ required: true, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.academics.graduationYear')}
                >
                    <Select placeholder='Graduation Year'>
                      {years.map((year, i)=>
                        <Option key={i} value={year.value}>{year.name}</Option>
                      )}
                    </Select>
                </Form.Item>
                <br />
                <h2 className={'text-center'}>High School Grades and Class Rank</h2>
                <br />
                <h3>Unweighted GPA</h3>
                <Form.Item
                  name='gpa'
                  rules={[{ required: true, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.academics.gpa')}
                >
                  <Select placeholder='GPA' >
                    <Option key={`gpa-4.0`} value='4.0'>4.0</Option>
                      {_.times(2).map((d)=>
                        _.times(10).map((dd)=>
                          <Option key={`gpa-${3 - d}.${9 - dd}`} value={`${3 - d}.${9 - dd}`}>{`${3 - d}.${9 - dd}`}</Option>
                        )
                      )}
                    </Select>
                </Form.Item>
                <h3>Class Rank</h3>
                <Form.Item
                  name='classRank'
                  rules={[{ required: true, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.academics.classRank')}
                >  
                  <Select placeholder='Class Rank'>
                    {rank.map((ranking, i)=>
                      <Option key={i} value={ranking.value}>{ranking.name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <br />
                <h2 className='text-center'>SAT / ACT Tests</h2>
                <br />
                <Card className='no-padding'>
                  <Tabs defaultActiveKey="SAT"  onChange={this.handleSATACT} state={{ size: 'large' }}>
                    <TabPane tab="SAT" key="SAT">
                      <h3>Highest Verbal Score</h3>
                      <Form.Item
                        name='satHighestLanguageScore'
                        rules={[{ required: this.state.SATACTTest === 'SAT', message: 'Please input a valid value.' }]}
                        initialValue={_.get(this, 'props.survey.academics.satHighestLanguageScore')}
                      >
                        
                        <Input placeholder='Highest Language Score' />
                      </Form.Item>
                      <h3>Highest Math Score</h3>
                      <Form.Item
                        name='satHighestMathScore'
                        rules={[{ required: this.state.SATACTTest === 'SAT', message: 'Please input a valid value.' }]}
                        initialValue={_.get(this, 'props.survey.academics.satHighestMathScore')}
                      >
                        <Input placeholder='Highest Math Score' />
                      </Form.Item>
                    </TabPane>
                    <h3>Highest Composite Score</h3>
                    <TabPane tab="ACT" key="ACT">
                      <Form.Item 
                        name='act'
                        rules={[{ required: this.state.SATACTTest === 'ACT', message: 'Please input a valid value.' }]}
                        initialValue={_.get(this, 'props.survey.academics.act')}
                      >

                        <Input placeholder='Highest Composite Score' />
                      </Form.Item>
                    </TabPane>
                  </Tabs>
                </Card>
                <br />
                <Row gutter={50}>
                  <Col lg={24} xs={24}>
                    <div className='text-center'>
                      <h2>AP Exams</h2>
                    </div>
                    <br />
                    {/* <Upload placeholder='AP Exams' /> */}
                    {this.state.APExams.map((row, i)=>{
                      return (
                        <Row gutter={12} key={`ap-${row.id}`}>
                          <Col span={2} xs={24}>
                            <Button type='unstyled' onClick={this.removeAPExam.bind(null, row.id)}>
                              <CloseCircleOutlined />
                            </Button>
                          </Col>
                          <Col xs={18}>
                            <Form.Item
                              name={`apExamsSubject${row.id}`}
                              rules={[{ required: true, message: 'Please input a valid value.' }]}
                              initialValue={_.get(this, `props.survey.academics.apExamsSubject${row.id}`)}
                            >
                              <Input placeholder='Subject' ref={(a)=>{ this[`AP-subject-${row.id}`] = a}} />
                            </Form.Item>
                          </Col>
                          <Col xs={6}>
                            <Form.Item
                              name={`apExamsScore${row.id}`}
                              rules={[{ required: true, message: 'Please input a valid value.' }]}
                              initialValue={_.get(this, `props.survey.academics.apExamsScore${row.id}`)}
                            >
                              <Input placeholder='Score' ref={(a)=>{ this[`AP-score-${row.id}`] = a}} />
                            </Form.Item>
                          </Col>
                        </Row>    
                      )
                    })}
                    <Row gutter={12}>
                      <Col span={2} xs={24}>
                      </Col>
                      <Col span={16} xs={18}>
                        <Form.Item>
                          <Input placeholder='Subject' onFocus={this.addToAPExams.bind(null, 'subject')} />
                        </Form.Item>
                      </Col>
                      <Col span={6} xs={6}>
                        <Form.Item>
                          <Input placeholder='Score' onFocus={this.addToAPExams.bind(null, 'score')} />
                        </Form.Item>
                      </Col>
                    </Row>    
                  </Col>
                </Row>
                <h2 className='text-center'>Curriculum Difficulty</h2>
                <br />
                <div className='text-center'>
                  <Form.Item
                    name='curriculumDifficulty'
                    rules={[{ required: true, message: 'Please input a valid value.' }]}
                    initialValue={_.get(this, 'props.survey.academics.curriculumDifficulty')}
                  >
                    <RadioGroup>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </RadioGroup>
                  </Form.Item>
                </div>
                <br />
                An important factor in gaining admission to the best and most selective colleges is the rigor and difficulty level of your high school curriculum. An example of the highest level of a rigorous curriculum is someone who has taken multiple Advanced Placement courses beginning in 11th grade, and received the highest grades consistently, while maintaining multiple extra-curricular activities after school.
                <br />
                <br />
                <div className='text-center'>
                  <Button className='ant-btn ant-btn-primary' htmlType='submit'>
                    Cancel
                  </Button>
                  &nbsp;
                  &nbsp;
                  <Button className='ant-btn ant-btn-primary' htmlType='submit'>
                    Save
                  </Button>
                </div>
              </Card>  
            </Col>
          </Row>
        </Form>
    )
  }
}

const connected = connect(()=>({
  addToFavorites: favorites.addToFavorites,
  removeFromFavorites: favorites.removeFromFavorites
}))(Academics)
export default connected
