import React from 'react'
import Dropdown from '../Dropdown'

export default (props = {})=>{
    let data = [];
    for(let i = 40; i !== 20; i-- ){
        const value = `${i}`.split('').join('.')
        data.push({
            value: value,
            name: value
        })
    }

    return <Dropdown 
        data={data}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        type={props.type} 
        value={props.value}
        placeholder={props.placeholder || 'Select GPA'}
    />
}