import React from 'react'
import { connect } from 'react-redux'
import { Radio, Form, Icon, Divider, Col, Row, Steps, Card, Input, Tabs, Checkbox, DatePicker, Select, Switch } from 'antd'
import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity, Upload, Button } from 'components/elements'
import MainLayout from '../../components/MainLayout'
import SectionContent from 'components/SectionContent'
import Panel from './Panel'
import {Link} from 'components/elements'
import moment from 'moment'
import * as utils from 'utils'
import {favorites, surveyActions} from 'actions'
const TabPane = Tabs.TabPane
const Step = Steps.Step

const {RangePicker} = DatePicker;

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;


class Academics extends React.Component {
  constructor(){
    super()

    this.state = {
      end: 10,
      SATTests: [],
      APExams: [],
      SATCount: 0,
      APCount: 0,
      value: 1,
      languages: [],
      languageID: 0
    }


    this.handleLeadership = this.handleLeadership.bind(this)
    this.addLanguage = this.addLanguage.bind(this)
    this.removeFromLanguage = this.removeFromLanguage.bind(this)
    this.handleFirstPersonToCollege = this.handleFirstPersonToCollege.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  moreSchools(e){
    e.preventDefault()

    this.setState({
      end: this.state.end + 10 
    })
  }
  addToFavorites(school){
    this.props.addToFavorites(school)
  }
  removeFromFavorites(school){
    this.props.removeFromFavorites(school)
  }
  calculateOdds(){
    this.props.history.push('/academics')
  }
  addToSATTests(type){
    const SATCount = this.state.SATCount + 1
    this.setState({
      SATTests: [...this.state.SATTests, {id: SATCount}],
      SATCount
    }, ()=>{
      this[`SAT-${type}-${this.state.SATTests.length - 1}`].input.focus()
    })
  }
  addToAPExams(type){
    const APCount = this.state.APCount + 1
    this.setState({
      APExams: [...this.state.APExams, {id: APCount}],
      APCount
    }, ()=>{
      this[`AP-${type}-${this.state.APExams.length - 1}`].input.focus()
    })
  }
  removeSATTest(i){
    const SATTests = [...this.state.SATTests]
    SATTests.splice(i , 1);

    this.setState({
      SATTests
    })
  }
  removeAPExam(i){
    const APExams = [...this.state.APExams]
    APExams.splice(i, 1);
    
    this.setState({
      APExams
    })
  }
  handleLeadership(values){
    this.setState({
      value: values.reduce((a, _, i)=> a + 1, 1)
    })
  }
  componentWillUpdate(_,a){
    
  }
  addLanguage(){
    const languageID = this.state.languageID + 1
    this.setState({
      languages: [...this.state.languages, {id: languageID}],
      languageID
    }, ()=>{
      this[`language-${this.state.languages.length - 1}`].input.focus()
    })
  }
  removeFromLanguage(i){
    const languages = [...this.state.languages]
    languages.splice(i , 1);
    
    this.setState({
      languages
    })
  }
  handleFirstPersonToCollege(value){
    this.setState({
      firstPersonToCollege: value
    })
  }
  handleSubmit(e){
    e.preventDefault()
    
    this.props.form.validateFieldsAndScroll((err, values)=>{
      if(!err){
        surveyActions.setSurveyValues({
          familyHistory: values
        })
        if(this.props.session.id){
          surveyActions.saveSurveyValues().then(()=>{
            this.props.history.push('/scores')
          })
        }else{
          this.props.history.push('/login')
        }
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    const selectedNames = this.props.favorites.map((school)=> school.name)
    let data = this.props.schools
    data = data.filter((school)=>
      !selectedNames.includes(school.name)
    ).slice(0, this.state.end)

    const grades =[
      {name: 9, value: 9},
      {name: 10, value: 10},
      {name: 11, value: 11},
      {name: 12, value: 12}
    ]
    
   const rank =   [{
      name: 'Top 1',
      value: 1
    },
    {
      name: 'Top 5',
      value: 5
    },
    {
      name: 'Top 10',
      value: 10
    },
    {
      name: 'Top 25',
      value: 25
    },
    {
      name: 'Top 50',
      value: 50
    },
    {
      name: 'Not Available',
      value: -1
    },
    {
      name: 'Decline to Answer',
      value: -2
    }]

    const year = parseInt(moment().format('YYYY'))
    const years = utils.times(5).map((i)=>({ name: year + i, value: year + i}))

    const plainOptions = ['Apple', 'Pear', 'Orange'];
    const options = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange' },
    ];
    const optionsWithDisabled = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange', disabled: false },
    ];    

    return (
      <MainLayout>
        <Form onSubmit={this.handleSubmit}>
          <Card>
            <Steps  size="small" current={4}>
              <Step title="Your Schools" />
              <Step title="Academics" />
              <Step title="Leadership" />
              <Step title='Personal Information' />
              <Step title='Legacy Information' />
            </Steps>
            <br />
            <br />
            <Link to='/login' className='ant-btn ant-btn-primary'>
              Next (Login / Signup)
            </Link>
            <br />
            <br />
            Want to make changes? &nbsp;
            <Link to='/personal-information'>
              Previous
            </Link>
          </Card>
          <br />
          <Row gutter={20} style={{margin: '0px 10px'}}>
            <Col sm={{span: 24}}>
              <Card>
                <h3>
                  First Generation            
                </h3>
                Are you the first person in your immediate family to apply to college? &nbsp;
                {getFieldDecorator('firstPersonToCollege', {
                  rules: [{ required: false, message: 'Please input a valid value.' }],
                })(
                  <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={this.handleFirstPersonToCollege} />
                )} 
                <br />
                <br />

                {this.state.firstPersonToCollege || (
                  <React.Fragment>
                    <h3>
                      Legacy
                    </h3>
                    Have one or both of your parents attended one of the schools you're applying to?
                    <br />
                    
                    <Row gutter={18}>
                      <Col lg={{span: 12}}>
                        <div className='text-center'>
                          <br />
                          <br />
                          <strong>
                            Father
                          </strong>
                        </div>
                        {getFieldDecorator("father", {})(
                          <Checkbox.Group style={{ width: "100%" }}>
                            {this.props.favorites.map((favorite)=>(
                              <Row>
                                <Col>
                                  <Checkbox value={favorite.id}>{favorite.name}</Checkbox>
                                </Col>
                              </Row>
                            ))}
                          </Checkbox.Group>
                        )}
                      </Col>
                      <Col lg={{span: 12}} >
                        <div className='text-center'>
                          <br />
                          <br />
                          <strong className='text-center'>
                            Mother
                          </strong>
                        </div>
                        {getFieldDecorator("mother", {})(
                          <Checkbox.Group style={{ width: "100%" }}>
                            {this.props.favorites.map((favorite)=>(
                              <Row>
                                <Col>
                                  <Checkbox value={favorite.id}>{favorite.name}</Checkbox>
                                </Col>
                              </Row>
                            ))}
                          </Checkbox.Group>
                        )}
                      </Col>
                    </Row>
    
    
                  </React.Fragment>
                )}

                {/* <Checkbox value={1}>
                  Are you the first person in your family to apply to college?
                </Checkbox> */}

                {/* Have one or both of your parents attended one of the schools you're applying to? */}

                <br />
                <div className='text-center'>
                  <Button className='ant-btn ant-btn-primary' htmlType='submit'>
                    Next (Login / Signup)
                  </Button>
                </div>
              </Card>  
            </Col>
          </Row>
        
        </Form>
      </MainLayout>
    )
  }
}

const connected = connect((state)=>(
  state
), ()=>({
  addToFavorites: favorites.addToFavorites,
  removeFromFavorites: favorites.removeFromFavorites
}))(Academics)
export default connected;
