import store, {actionTypes} from 'store'
import apiClient from '../apiClient'
import _ from 'lodash'

export const getScoreValues = ()=>{
    const state = store.getState()
    const {academics, personalInformation} = _.get(state, 'session.answers.data.survey', {})
    
    if(academics && personalInformation){
        return apiClient.scores().then((results)=>{
            store.dispatch({
                type: actionTypes.SET_SCORE_VALUES,
                payload: results.data
            })
        })
    }
}