import React, {Component, Fragment} from 'react'
import { Layout, Row, Col, Button, AutoComplete, Input, Form} from 'antd'
import {connect} from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import {debounce} from 'debounce'

import './index.less'
import { filterActions, sessionActions } from 'actions'
import LandingHeader from '../../components/LandingHeader'
import AppFooter from '../../components/AppFooter'
import _ from 'lodash'

const Option = AutoComplete.Option;

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchValue: ''
    }

    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleOnSubmit(e) {
    e.preventDefault() 
    filterActions.setFilter('name', this.state.searchValue)
    this.props.history.push('/your-schools')
  }
  handleSearch(value) {
    this.setState({
      searchValue: value
    })
    this.nameSearch(value)
  } 
  nameSearch = debounce(value => {
    filterActions.setFilter('name', value)
  }, 200)
  handleSelect(value, option) {
    this.setState({
      searchValue: option.props.text
    })
  }  
  handleSubmit(values){
    sessionActions.setInvitationCode(values.invitationCode).catch((error)=>{
      if(error.response.status == 404){
        this.setState({
          errors: [`Invitation Code Invalid`]
        })
      }
    }).then(()=>{
      this.props.history.push('/register')
    })
  }
  render() {
    if (this.props.session.id) {
      return <Redirect to={{ pathname: '/scores' }} />
    }
    
    // const options = (dataSource || []).map(school => 
    //   <Option key={`${school.name}-${school.id}`} text={school.name} >
    //     {school.name} 
    //   </Option>
    // )
    return (
      <Layout> 
        <div className="hero"> 
          <LandingHeader />
          <div className="main">
            <div className="main-content">
                <div>
                  <Fragment>
                    <Row gutter={{md:8}}>
                      <Col xs={{span:22, offset:1}} md={{span:24, offset:0}}>
                        <br />
                        <br />
                        <br />
                        <label className='errors'>
                          {(this.state.errors || []).join('. ')}
                        </label>
                        <Form onFinish={this.handleSubmit}>
                          <Input.Group compact className='invitation-collect'>
                            <Form.Item
                              name='invitationCode'
                              initialValue={_.get(this, 'props.app.url.query', {}).invitationCode || this.props.invitationCode}
                              style={{ width: 'calc(100% - 100px)'}}
                            >
                              <Input placeholder='Please enter invitation code' style={{borderRadius: '6px 0px 0px 6px'}} />
                            </Form.Item>
                            <Button htmlType={'submit'} style={{ 
                              width: '100px', 
                              marginTop: '0px', 
                              height: '44px', 
                              borderTopLeftRadius: '0px', 
                              borderBottomLeftRadius: '0px', 
                              boxShadow: 'none', 
                            }}>Submit</Button>
                          </Input.Group>
                        </Form>
                      </Col>
                    </Row>
                    <br />
                    <div style={{textAlign: 'center'}}>
                      <strong>
                        <a href='mailto: info@admissions.company'>Request an Invitation</a>
                        <span style={{color: '#888'}}>&nbsp; &#9679; &nbsp;</span>
                        <a href='https://admissions.company/'>More Information</a>
                      </strong>
                    </div>
                  </Fragment>
                </div>
            </div> 
          </div>
        </div>
        <AppFooter />
      </Layout>  
    )
  }
}

export default withRouter(connect((state)=> state)(Home))