module.exports = [
    `I am the life of the party.`,
    `I feel little concern for others.`,
    `I am always prepared.`,
    `I get stressed out easily.`,
    `I have a rich vocabulary.`,
    `I don't talk a lot.`,
    `I am interested in people.`,
    `I leave my belongings around.`, 
    `I am relaxed most of the time.`,
    `I have difficulty understanding abstract ideas. `,
    `I feel comfortable around people.`,
    `I Insult people.`,
    `I pay attention to details.`,
    `I worry about things.`,
    `I have a vivid imagination.`,
    `keep in the background.`,
    `I sympathize with others' feelings.`,
    `I make a mess of things.`,
    `I seldom feel blue.`,
    `I am not interested in abstract ideas.`,
    `I Start conversations.`,
    `I am not interested in other people's problems.`,
    `I get chores done right away.`,
    `I am easily disturbed.`,
    `I have excellent ideas.`,
    `I have little to say.`,
    `I have a soft heart.`,
    `I often forget to put things back in their proper place.`,
    `I get upset easily.`,
    `I do not have a good imagination.`,
    `I talk to a lot of different people at parties.`,
    `I am not really interested in others.`,
    `I like order.`,
    `I change my mood a lot.`,
    `I am quick to understand things.`,
    `I don't like to draw attention to myself.`,
    `I take time out for others.`,
    `I shirk my duties.`,
    `I have frequent mood swings.`,
    `I use difficult words.`,
    `I don't mind being the center of attention.`,
    `I feel others' emotions.`,
    `I follow a schedule.`,
    `I get irritated easily.`,
    `I spend time reflecting on things.`,
    `I am quiet around strangers.`,
    `I Make people feel at ease.`,
    `I am exacting in my work.`,
    `I often feel blue.`,
    `I am full of ideas`
]