import {
  APP_LOGIN_SET,
  APP_LOGOUT_SET,
} from '../constants/actionTypes'
import parse from 'url-parse';

export default (state = {}, action) => {
  switch (action.type) {
    case APP_LOGIN_SET:
      return {
        ...state,
        token: action.token,
        url: parse(window.location.href, true)
      }
    case APP_LOGOUT_SET:
      return {
        ...state,
        token: null,
        url: parse(window.location.href, true)
      }
    default:
      return {
        ...state,
        url: parse(window.location.href, true)
      }
  }
}
