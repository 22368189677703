import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Home from '../Home'
import Schools from '../Schools'
import Login from '../Login'
import ResetPassword from '../ResetPassword/index'
import PasswordReset from '../PasswordReset'
import Register from '../Register'
import Privacy from '../Privacy'
import Terms from '../Terms'
import Eula from '../Eula'
import NotFound from '../NotFound'
import Dashboard from '../Dashboard'
import UserList from '../UserList'
import User from '../User/index'
import UserForm from '../UserForm'
import Academics from '../Academics'
import Leadership from '../Leadership'
import Profile from '../Profile'
import LegacyInformation from '../LegacyInformation'
import Scores from '../Scores'
import TheBigFive from '../TheBigFive'
import PrivateRoute from '../../components/PrivateRoute'
import AuthenticatedRoute from '../../components/AuthenticatedRoute'
import './index.less'

export default class App extends React.Component {
  render() {
    return (
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/reset-password' component={ResetPassword} />
          <Route exact path='/password-reset' component={PasswordReset} />
          <Route exact path='/' component={Home} />
          <PrivateRoute exact path='/schools' component={Schools} />
          <PrivateRoute exact path='/register' component={Register} />
          <PrivateRoute exact path='/academics' component={Academics} />
          <PrivateRoute exact path='/leadership' component={Leadership} />
          <PrivateRoute exact path='/profile' component={Profile} />
          <PrivateRoute exact path='/legacy-information' component={LegacyInformation} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/eula' component={Eula} />
          <PrivateRoute path='/the-big-five/' component={TheBigFive} />

          <AuthenticatedRoute exact path='/scores' component={Scores} />
          <AuthenticatedRoute exact path='/admin/users' component={UserList} />
          <AuthenticatedRoute exact path='/admin/users/create' component={props => <UserForm {...props} type='create' />} />
          <AuthenticatedRoute exact path='/admin/users/:id' component={User} />
          <Route component={NotFound} />
        </Switch>
    )
  }
}
