import * as invitations from './invitations'
import * as admins from './admins'
import * as users from './users'
import * as scores from './scores'
import * as surveys from './surveys'
import * as favorites from './favorites'

export default {
    invitations,
    admins,
    users,
    scores,
    surveys,
    favorites
}