import store, {actionTypes} from 'store'
import apiClient from '../apiClient'
import * as scores from './scores';

export const getFavorites = ({userID})=>{
    return apiClient.favorites.fetch({userID}).catch((results)=>{
      console.log('error')  
    })
}

export const setFavorites = ({userID, favorites})=>{
    if(store.getState().favorites.length <= 15){
        return apiClient.favorites.set({
            userID,
            favorites
        }).then(()=>{
            scores.getScoreValues()
        })
    }else{
        return Promise.reject()
    }
}

export const removeFromFavorites = (favorite)=>{
    store.dispatch({
        type: actionTypes.REMOVE_FAVORITE,
        payload: favorite
    })
    if(store.getState().session.id){
        apiClient.addToFavorites({favorites: store.getState().favorites}).then(()=>{
            scores.getScoreValues()
        })
    }
}