import React from 'react'
import { Layout } from 'antd'
import DocumentView from '../../components/DocumentView'
import EulaContent from '../../assets/legal/EulaContent'
import AppFooter from '../../components/AppFooter'
import LandingHeader from '../../components/LandingHeader'

class Eula extends React.Component {
  render() {
    return (
      <Layout>
        <LandingHeader />
        <DocumentView content={EulaContent} />
        <AppFooter />
      </Layout>
    );
  }
}

export default Eula;