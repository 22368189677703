import store, {actionTypes} from 'store'
import apiClient from '../apiClient/index'
import * as scores from './scores';

export const getFavorites = ()=>{
    apiClient.favorites().then((results)=>{
        store.dispatch({
            type: actionTypes.SET_FAVORITES,
            payload: results.data
        })
    })
}

export const addToFavorites = (favorite)=>{
    if(store.getState().favorites.length <= 15){
        store.dispatch({
            type: actionTypes.ADD_FAVORITE,
            payload: favorite
        })
        if(store.getState().session.id){
            apiClient.addToFavorites({favorites: store.getState().favorites}).then(()=>{
                scores.getScoreValues()
            })
        }
    }
}

export const removeFromFavorites = (favorite)=>{
    store.dispatch({
        type: actionTypes.REMOVE_FAVORITE,
        payload: favorite
    })
    if(store.getState().session.id){
        apiClient.addToFavorites({favorites: store.getState().favorites}).then(()=>{
            scores.getScoreValues()
        })
    }
}