import React, {Component} from 'react'
import Dropdown from '../Dropdown'
import {  Select } from 'antd'
import {UsaStates} from 'usa-states'
import _ from 'lodash'
const Option = Select.Option
const states = ((new UsaStates).states || []).map(({name, abbreviation})=>({
    name: name,
    value: abbreviation
}))
export default class extends Component {
    constructor(props){
        super(props)
        this.state = { 
            states,
            value: props.value
        }
        this.handleSearch = this.handleSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleSearch(value){
        this.setState({
            states: states.filter(({name})=>
                name.toLowerCase().includes(value.toLowerCase())
            )
        })
    }
    handleChange(value){
        this.setState({
            value
        }, ()=>{
            _.invoke(this.props, 'onChange', value)
        })
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.value != this.props.value){
            this.setState({
                value: nextProps.value
            })
        }
        if(nextProps.defaultValue != this.props.defaultValue){
            this.setState({
                value: nextProps.defaultValue
            })
        }
    }
    render(){
        return (
            <Select 
                data={this.state.states}
                showSearch
                onChange={this.handleChange}
                // fullWidth={this.props.fullWidth}
                type={this.props.type} 
                value={this.state.value}
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder || 'Select a State'}
                onSearch={this.handleSearch}
                filterOption={false}
            >
                {this.state.states.map(({name, value}, i)=>(
                    <Option value={value} key={`state-${i}`}>
                        {name}
                    </Option>
                ))}
            </Select>
        )
    }
}