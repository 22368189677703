import User from './User'
import Admin from './Admin'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
const yields = ()=>(
    <div>
        <BrowserRouter>
            <Switch>
                <Route path='/admin' component={Admin} />
                <Route path='/' component={User} />
            </Switch>
        </BrowserRouter>
    </div>
)


export default yields