import React from 'react'
import { Form, Divider, Card, Input, Tabs, Icon } from 'antd'
import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity } from 'components/elements'
import { connect } from 'react-redux';
import {favorites} from 'actions'
import {Route} from 'react-router-dom'
import { MinusOutlined } from '@ant-design/icons';

export default connect((state)=>(state),()=>({
    addToFavorites: favorites.addToFavorites,
    removeFromFavorites: favorites.removeFromFavorites
}))((props)=>{

  const TabPane = Tabs.TabPane
  return (
    <Form>
        {props.favorites.map((school, i)=>
            <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} key={`favorites-${i}`}>
                <br />
                <Route exact path='/' render={()=>(
                    <MinusOutlined
                        onClick={props.removeFromFavorites.bind(null, school)}
                        className='pointer danger-color'
                    />
                    
                )} /> 
                {school.name}
            </div>
        )}
    </Form>
  )
})