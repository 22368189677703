import React from 'react'
import Dropdown from '../Dropdown'

export default (props = {})=>{
    let data = [
      {
        name: "Highly Selective (less than or equal to 20%)",
        value: 5 
      },
      {
        name: "Very Selective (between 21% to 30%)",
        value: 4 
      },
      {
        name: "Selective (between 31% to 40%)",
        value: 3 
      },
      {
        name: "Somewhat Selective (between 41% to 50%)",
        value: 2
      },
      {
        name: "Non-selective (greater than or equal to 51%",
        value: 1
      }
    ];

    return <Dropdown 
        data={data}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        type={props.type} 
        value={props.value}
        placeholder={props.placeholder || 'Choose Selectivity Level'}
    />
}