import React from 'react'
import { Card, Layout, Row, Col, notification } from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Form from './Form'

import AppFooter from '../../components/AppFooter'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import './index.less'
import GoogleLoginButton from './GoogleLoginButton'
import getParams from 'lib/getParams'

class Login extends React.Component {
  constructor(props){
    super(props)
    this.state = {}
  }
  componentDidMount(){
    if(!this.state.alerts && getParams().error){
      this.setState({
        alerts: true
      }, ()=>{
        notification.error({
          message: 'Unable to Log In',
          description: getParams().error,
        });
      })
    }
  }
  render() {
    let redirectUrl = "//localhost:3333/google/redirect"
    if(window.location.host.includes("mitty.app")){
      redirectUrl = "//admissions-company.appspot.com/google/redirect"
    }else if(window.location.host.includes("admissions-company-staging.web.app")){
      redirectUrl = "//staging-dot-admissions-company.appspot.com/google/redirect"
    }
    if (this.props.session.id) {
      return <Redirect to={{ pathname: '/scores' }} />
    }
    let renderGoogle = true
    if(window.location.search.includes("google=noshow")){
      renderGoogle = false
    }

    return (
      <Layout>
        <div className="hero">
          <Layout.Header className="landing-header">
            <div className="logo">
              <Link to='/'>
                <Logo />
              </Link>
            </div>
          </Layout.Header>

          <div className="main-content login">
            <div className="main" style={{display: "block", maxWidth: "800px", margin: "0px auto"}}>
              <Card className='login-form' style={{textAlign: "center"}}>
                <div className='header'>
                  <h1>Login To Mitty</h1>
                </div>
                <Row>
                  <Col xs={{span: 24}} lg={{span: 11}}>
                    <label>
                      Continue with email
                    </label>
                    <Form
                      onSubmit={this.props.handleFormOnSubmit}
                      formFieldValues={this.props.formFieldValues}
                      isLoading={this.props.isLoginLoading}
                    />
                    <br />
                    <br />
                    {renderGoogle && (
                      <>
                        <Col xs={{span: 24}} lg={{span: 0}} style={{textAlign: "center"}}>
                          <label>
                            Continue with External Account
                          </label>
                          <br />
                          <br />
                        </Col>
                        <Col xs={{span: 24}} lg={{span: 0}}>
                          <div>
                            <a href={redirectUrl}>
                              <div id="container" class="haAclf" style={{padding: "2px 10px"}}>
                                <GoogleLoginButton />
                              </div>
                            </a>
                          </div>
                        </Col>
                      </>
                    )}
                    <br />
                    <br />
                    <br />
                    Need an account?
                    <br />
                    <br />
                    <a href="mailto: support@mitty.app">Request an Invitation</a>
                  </Col>
                  {renderGoogle && (
                    <>
                      <Col xs={{span: 0}} lg={{span: 4}} style={{textAlign: "center"}}>
                        <div className="or-border">
                        </div>
                        <div style={{textAlign: "center"}} className="or">
                          OR
                        </div>
                      </Col>
                      <Col xs={{span: 0}} lg={{span: 9}}>
                        <label>
                          Continue with External Account
                        </label>
                        <div>
                          <a href={redirectUrl}>
                            <div id="container" class="haAclf" style={{padding: "2px 10px"}}>
                              <GoogleLoginButton />
                            </div>
                          </a>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
            </div>
          </div>
          <AppFooter />
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = ()=> ({})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
