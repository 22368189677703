export default `
  <h1 class="c1" id="h.gjdgxs"><span>End-User License Agreement (&quot;Agreement&quot;)</span></h1>
  <p class="c1"><span class="c0">Last updated: January 01, 2019</span></p>
  <p class="c1"><span class="c0">Please read this End-User License Agreement (&quot;Agreement&quot;) carefully before
      clicking the &quot;I Agree&quot; button, downloading or using Mitty (&quot;Application&quot;).</span></p>
  <p class="c1"><span class="c0">By clicking the &quot;I Agree&quot; button, downloading or using the Application, you
      are agreeing to be bound by the terms and conditions of this Agreement.</span></p>
  <p class="c1"><span class="c0">This Agreement is a legal agreement between you (either an individual or a single
      entity) and Admissions Company, LLC and it governs your use of the Application made available to you by
      Admissions Company, LLC.</span></p>
  <p class="c1"><span class="c0">If you do not agree to the terms of this Agreement, do not click on the &quot;I
      Agree&quot; button and do not download or use the Application.</span></p>
  <p class="c1"><span class="c0">The Application is licensed, not sold, to you by Admissions Company, LLC for use
      strictly in accordance with the terms of this Agreement.</span></p>
  <h2 class="c2"><span class="c4">License</span></h2>
  <p class="c1"><span class="c0">Admissions Company, LLC grants you a revocable, non-exclusive, non-transferable,
      limited license to download, install and use the Application solely for your personal, non-commercial purposes
      strictly in accordance with the terms of this Agreement.</span></p>
  <h2 class="c2"><span class="c4">Restrictions</span></h2>
  <p class="c1"><span class="c0">You agree not to, and you will not permit others to:</span></p>
  <p class="c5 c3"><span class="c0">license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose
      or otherwise commercially exploit the Application or make the Application available to any third party.</span></p>
  <p class="c5 c3"><span class="c0">copy or use the Application for any purpose other than as permitted under the above
      section &#39;License&#39;.</span></p>
  <p class="c3 c5"><span class="c0">modify, make derivative works of, disassemble, decrypt, reverse compile or reverse
      engineer any part of the Application.</span></p>
  <p class="c1 c3"><span class="c0">remove, alter or obscure any proprietary notice (including any notice of copyright
      or trademark) of Admissions Company, LLC or its affiliates, partners, suppliers or the licensors of the
      Application.</span></p>
  <h2 class="c2"><span class="c4">Intellectual Property</span></h2>
  <p class="c1"><span class="c0">The Application, including without limitation all copyrights, patents, trademarks,
      trade secrets and other intellectual property rights are, and shall remain, the sole and exclusive property of
      Admissions Company, LLC.</span></p>
  <h2 class="c2"><span class="c4">Your Suggestions</span></h2>
  <p class="c1"><span class="c0">Any feedback, comments, ideas, improvements or suggestions (collectively,
      &quot;Suggestions&quot;) provided by you to Admissions Company, LLC with respect to the Application shall remain
      the sole and exclusive property of Admissions Company, LLC.</span></p>
  <p class="c1"><span class="c0">Admissions Company, LLC shall be free to use, copy, modify, publish, or redistribute
      the Suggestions for any purpose and in any way without any credit or any compensation to you.</span></p>
  <h2 class="c2"><span class="c4">Modifications to Application</span></h2>
  <p class="c1"><span class="c0">Admissions Company, LLC reserves the right to modify, suspend or discontinue,
      temporarily or permanently, the Application or any service to which it connects, with or without notice and
      without liability to you.</span></p>
  <h2 class="c2"><span class="c4">Updates to Application</span></h2>
  <p class="c1"><span class="c0">Admissions Company, LLC may from time to time provide enhancements or improvements to
      the features/functionality of the Application, which may include patches, bug fixes, updates, upgrades and other
      modifications (&quot;Updates&quot;).</span></p>
  <p class="c1"><span class="c0">Updates may modify or delete certain features and/or functionalities of the
      Application. You agree that Admissions Company, LLC has no obligation to (i) provide any Updates, or (ii)
      continue to provide or enable any particular features and/or functionalities of the Application to you.</span></p>
  <p class="c1"><span class="c0">You further agree that all Updates will be (i) deemed to constitute an integral part
      of the Application, and (ii) subject to the terms and conditions of this Agreement.</span></p>
  <h2 class="c2"><span class="c4">Third-Party Services</span></h2>
  <p class="c1"><span class="c0">The Application may display, include or make available third-party content (including
      data, information, applications and other products services) or provide links to third-party websites or services
      (&quot;Third-Party Services&quot;).</span></p>
  <p class="c1"><span class="c0">You acknowledge and agree that Admissions Company, LLC shall not be responsible for
      any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance,
      legality, decency, quality or any other aspect thereof. Admissions Company, LLC does not assume and shall not
      have any liability or responsibility to you or any other person or entity for any Third-Party Services.</span></p>
  <p class="c1"><span class="c0">Third-Party Services and links thereto are provided solely as a convenience to you and
      you access and use them entirely at your own risk and subject to such third parties&#39; terms and conditions.</span></p>
  <h2 class="c2"><span class="c4">Privacy Policy</span></h2>
  <p class="c1"><span class="c0">Admissions Company, LLC collects, stores, maintains, and shares information about you
      in accordance with its Privacy Policy, which is available at https://mitty.app/privacy. By accepting this
      Agreement, you acknowledge that you hereby agree and consent to the terms and conditions of our Privacy Policy.</span></p>
  <h2 class="c2"><span class="c4">Term and Termination</span></h2>
  <p class="c1"><span class="c0">This Agreement shall remain in effect until terminated by you or Admissions Company,
      LLC.</span></p>
  <p class="c1"><span class="c0">Admissions Company, LLC may, in its sole discretion, at any time and for any or no
      reason, suspend or terminate this Agreement with or without prior notice.</span></p>
  <p class="c1"><span class="c0">This Agreement will terminate immediately, without prior notice from Admissions
      Company, LLC, in the event that you fail to comply with any provision of this Agreement. You may also terminate
      this Agreement by deleting the Application and all copies thereof from your mobile device or from your computer.</span></p>
  <p class="c1"><span class="c0">Upon termination of this Agreement, you shall cease all use of the Application and
      delete all copies of the Application from your mobile device or from your computer.</span></p>
  <p class="c1"><span class="c0">Termination of this Agreement will not limit any of Admissions Company, LLC&#39;s
      rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of
      your obligations under the present Agreement.</span></p>
  <h2 class="c2"><span class="c4">Indemnification</span></h2>
  <p class="c1"><span class="c0">You agree to indemnify and hold Admissions Company, LLC and its parents, subsidiaries,
      affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand,
      including reasonable attorneys&#39; fees, due to or arising out of your: (a) use of the Application; (b)
      violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</span></p>
  <h2 class="c2"><span class="c4">No Warranties</span></h2>
  <p class="c1"><span class="c0">The Application is provided to you &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; and
      with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law,
      Admissions Company, LLC, on its own behalf and on behalf of its affiliates and its and their respective licensors
      and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with
      respect to the Application, including all implied warranties of merchantability, fitness for a particular
      purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of
      performance, usage or trade practice. Without limitation to the foregoing, Admissions Company, LLC provides no
      warranty or undertaking, and makes no representation of any kind that the Application will meet your
      requirements, achieve any intended results, be compatible or work with any other software, applications, systems
      or services, operate without interruption, meet any performance or reliability standards or be error free or that
      any errors or defects can or will be corrected.</span></p>
  <p class="c1"><span class="c0">Without limiting the foregoing, neither Admissions Company, LLC nor any Admissions
      Company, LLC&#39;s provider makes any representation or warranty of any kind, express or implied: (i) as to the
      operation or availability of the Application, or the information, content, and materials or products included
      thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or
      currency of any information or content provided through the Application; or (iv) that the Application, its
      servers, the content, or e-mails sent from or on behalf of Admissions Company, LLC are free of viruses, scripts,
      trojan horses, worms, malware, timebombs or other harmful components.</span></p>
  <p class="c1"><span class="c0">Some jurisdictions do not allow the exclusion of or limitations on implied warranties
      or the limitations on the applicable statutory rights of a consumer, so some or all of the above exclusions and
      limitations may not apply to you.</span></p>
  <h2 class="c2"><span class="c4">Limitation of Liability</span></h2>
  <p class="c1"><span class="c0">Notwithstanding any damages that you might incur, the entire liability of Admissions
      Company, LLC and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of
      the foregoing shall be limited to the amount actually paid by you for the Application.</span></p>
  <p class="c1"><span class="c0">To the maximum extent permitted by applicable law, in no event shall Admissions
      Company, LLC or its suppliers be liable for any special, incidental, indirect, or consequential damages
      whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information,
      for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the
      use of or inability to use the Application, third-party software and/or third-party hardware used with the
      Application, or otherwise in connection with any provision of this Agreement), even if Admissions Company, LLC or
      any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential
      purpose.</span></p>
  <p class="c1"><span class="c0">Some states/jurisdictions do not allow the exclusion or limitation of incidental or
      consequential damages, so the above limitation or exclusion may not apply to you.</span></p>
  <h2 class="c2"><span class="c4">Severability</span></h2>
  <p class="c1"><span class="c0">If any provision of this Agreement is held to be unenforceable or invalid, such
      provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent
      possible under applicable law and the remaining provisions will continue in full force and effect.</span></p>
  <h2 class="c2"><span class="c4">Waiver</span></h2>
  <p class="c1"><span class="c0">Except as provided herein, the failure to exercise a right or to require performance
      of an obligation under this Agreement shall not effect a party&#39;s ability to exercise such right or require
      such performance at any time thereafter nor shall be the waiver of a breach constitute waiver of any subsequent
      breach.</span></p>
  <h2 class="c2"><span class="c4">For U.S. Government End Users</span></h2>
  <p class="c1"><span class="c0">The Application and related documentation are &quot;Commercial Items&quot;, as that
      term is defined under 48 C.F.R. &sect;2.101, consisting of &quot;Commercial Computer Software&quot; and
      &quot;Commercial Computer Software Documentation&quot;, as such terms are used under 48 C.F.R. &sect;12.212 or 48
      C.F.R. &sect;227.7202, as applicable. In accordance with 48 C.F.R. &sect;12.212 or 48 C.F.R. &sect;227.7202-1
      through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software
      Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only
      those rights as are granted to all other end users pursuant to the terms and conditions herein.</span></p>
  <h2 class="c2"><span class="c4">Export Compliance</span></h2>
  <p class="c1"><span class="c0">You may not export or re-export the Application except as authorized by United States
      law and the laws of the jurisdiction in which the Application was obtained.</span></p>
  <p class="c1"><span class="c0">In particular, but without limitation, the Application may not be exported or
      re-exported (a) into or to a nation or a resident of any U.S. embargoed countries or (b) to anyone on the U.S.
      Treasury Department&#39;s list of Specially Designated Nationals or the U.S. Department of Commerce Denied
      Person&#39;s List or Entity List.</span></p>
  <p class="c1"><span class="c0">By installing or using any component of the Application, you represent and warrant
      that you are not located in, under control of, or a national or resident of any such country or on any such list.</span></p>
  <h2 class="c2"><span class="c4">Amendments to this Agreement</span></h2>
  <p class="c1"><span class="c0">Admissions Company, LLC reserves the right, at its sole discretion, to modify or
      replace this Agreement at any time. If a revision is material we will provide at least 30 days&#39; notice prior
      to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</span></p>
  <p class="c1"><span class="c0">By continuing to access or use our Application after any revisions become effective,
      you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to
      use the Application.</span></p>
  <h2 class="c2"><span class="c4">Governing Law</span></h2>
  <p class="c1"><span class="c0">The laws of Maryland, United States, excluding its conflicts of law rules, shall
      govern this Agreement and your use of the Application. Your use of the Application may also be subject to other
      local, state, national, or international laws.</span></p>
  <p class="c1"><span class="c0">This Agreement shall not be governed by the United Nations Convention on Contracts for
      the International Sale of Good.</span></p>
  <h2 class="c2"><span class="c4">Contact Information</span></h2>
  <p class="c1"><span class="c0">If you have any questions about this Agreement, please contact us.</span></p>
  <h2 class="c2"><span class="c4">Entire Agreement</span></h2>
  <p class="c1"><span class="c0">The Agreement constitutes the entire agreement between you and Admissions Company, LLC
      regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements
      between you and Admissions Company, LLC.</span></p>
  <p class="c1"><span class="c0">You may be subject to additional terms and conditions that apply when you use or
      purchase other Admissions Company, LLC&#39;s services, which Admissions Company, LLC will provide to you at the
      time of such use or purchase.</span></p>
`