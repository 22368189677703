import {Layout} from './elements'
import InviteUsers from "./templates/InviteUsers" 
import SearchUsers from "./templates/Users/index" 
import Formula from "./templates/Formula" 
import Scores from "./templates/Scores" 
import EditUsers from "./templates/EditUsers" 
import Dashboard from "./templates/dashboard" 
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import Authentications from './templates/Authentications'

export default ()=>(
    <Layout>
        <Route exact path='/admin' component={Dashboard} />
        <Route path='/admin/users/:id/edit' component={EditUsers} />
        <Route exact path='/admin/users/invite' component={InviteUsers} />
        <Route exact path='/admin/users' component={SearchUsers} />
        <Route exact path='/admin/formula' component={Formula} />
        <Route exact path='/admin/users/:id/details' component={Scores} />
        <Route exact path='/admin/users/:id/authentications' component={Authentications} />
    </Layout>
)