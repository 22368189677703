import store from '../store'
import * as ActionTypes from '../constants/actionTypes'
import * as apiClient from '../apiClient/index'
import * as lib from 'lib'

const getState = ()=>{
    let options = {answers: {}}
       
    if(Object.keys(store.getState().survey).length == 2){    
        options.answers.survey = store.getState().survey
    }
    
    if(store.getState().favorites.length !== 0){
        options.answers.favorites = store.getState().favorites
    }

    return options
}

export const login = values => {
  let options = getState();

  apiClient.login({
    email: values.email,
    password: values.password,
    ...options
  })
    .then(({data = {}} = {}) => {
      store.dispatch({ type: ActionTypes.APP_LOGIN_SET, payload: data })
    }, (error) => {
      store.dispatch({ type: ActionTypes.APP_LOGIN_ERROR, payload: {loginError: true} })
    });
}

const invitationCode = sessionStorage.getItem('invitationCode')
if(invitationCode){
  store.dispatch({ 
    type: 'invitationSet', payload: invitationCode
  })
}
export const setInvitationCode =  (values)=>(
  apiClient.verifyInvitationCode(values).then(()=>{
    store.dispatch({ 
      type: 'invitationSet', payload: values 
    })
    sessionStorage.setItem('invitationCode', values)
  })
)

export const register = values => {
    let options = getState();
    const invitationCode = Object(store.getState()).invitationCode
    
    return apiClient.register({
        email: values.email,
        password: values.password,
        invitationCode,
        ...options
    })
      .then(({data = {}} = {}) => {
        store.dispatch({ 
          type: ActionTypes.APP_REGISTER_SET, 
          payload: data 
        })
        store.dispatch({ 
          type: ActionTypes.SET_FAVORITES, 
          payload: []
        })
        
      }, (error) => {
        store.dispatch({ type: ActionTypes.APP_REGISTER_ERROR, payload: {registerError: true}})
      });
}

export const resetPassword = values => {
    let options = getState();

    return apiClient.resetPassword({
        email: values.email
    })
}


export const setPassword = values => {
  const params = lib.getUrlParams(window.location.search)
  
  return apiClient.setPassword({
      password: values.password,
      token: params.token
  })
}

export const logout = () => {
  store.dispatch({ type: ActionTypes.APP_LOGOUT_SET })
  localStorage.clear()
  window.location.href = "/login"
}

export const reset = () => {
  store.dispatch({ type: ActionTypes.APP_LOGIN_RESET, payload: {loginError: false, registerError: false} });
}


export const getSessionWithToken = (token)=>{
  apiClient.getSessionWithToken(token).then(({data})=>{
    store.dispatch({ type: ActionTypes.APP_LOGIN_SET, payload: data })
    window.location.href = "/"
  })
}