import React from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Icon, Input, Form, Divider, Card, Dropdown, Button } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import Header from './Header'
import AppFooter from 'components/AppFooter'
import logo from '../../assets/react.svg'
import { openChangeMenu } from '../../actions/admin'
import './index.less'
import FormItem from 'antd/lib/form/FormItem';
import Logo from 'components/Logo'
import './index.less'
const { Sider, Content } = Layout
const { SubMenu } = Menu

class Admin extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleHeaderOnToggle = this.handleHeaderOnToggle.bind(this)
    this.handleMenuItemOnClick = this.handleMenuItemOnClick.bind(this)
    this.state = {
      collapsed: false,
    }
  }

  handleHeaderOnToggle() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  handleMenuItemOnClick(item) {
    this.props.history.push(`/admin/${item.key}`)
  }

  render() {
    const { match, children, openKeys } = this.props
    const selectedKey = match.path.split('/').splice(-1)[0]
    const Panel = this.props.panel

    return (
      <Layout className='admin-page'>
        {Panel && (
          <Sider
            className='admin-sider'
            collapsible
            collapsed={this.state.collapsed}
            trigger={null}
            width={356} // 356
          >
            <div className='logo'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>
            {Panel && (
              <Panel />
            )}
          </Sider>
        )}
        <Layout>
          <Header showLogo={this.props.showLogo || !Panel} collapsed={this.state.collapsed} handleHeaderOnToggle={this.handleHeaderOnToggle} />
          <div className='main-body'>
            <Content>
              {children}
            </Content>
          </div>
          <AppFooter />
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  const { openKeys } = state.admin || ['general']
  return { openKeys }
}

const mapDispatchToProps = dispatch => ({
  handleMenuOnOpenChange: openKeys => dispatch(openChangeMenu(openKeys)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin))
