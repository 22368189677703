import React, {Component} from 'react'
import * as Ant from 'antd'
import { DownOutlined } from '@ant-design/icons';

import './index.less'
class Dropdown extends Component {
    constructor(){
        super()
        this.state = {

        }
        this.handleSelect = this.handleSelect.bind(this)
    }
    handleSelect({key}, e = {}){
        const item = this.props.data[key] || {}
        const {value, name} = item
        
        this.setState({
            name,
            value
        }, ()=>{
            if(this.props.onChange){
                const _e = {
                    ...e,
                    target: {
                        value
                    }
                }
                this.props.onChange(_e)
            }
        })
    }
    componentWillReceiveProps(nextProps){
        if(this.props.value != nextProps.value){
            const item = (this.props.data || []).find((item)=>(
                item.value == nextProps.value
            ))

            if(item){
                this.setState({
                    name: item.name,
                    value: item.value
                })
            }else{
                this.setState({
                    name: undefined,
                    value: undefined
                })
            }
        }
    }
    render(){
        const menu = (
            <Ant.Menu onClick={this.handleSelect}>
                {this.props.data.map((item, i)=>(
                    <Ant.Menu.Item key={i}>{item.name}</Ant.Menu.Item>
                ))}
            </Ant.Menu>
        )
        const className=['scaffold']
        this.props.fullWidth && className.push('full')
        return (
            <Ant.Dropdown overlay={menu}>
                <Ant.Button
                    type={this.props.type}
                    style={{ width: '100%' }}
                    className={className.join(' ')}
                >
                    <div className='inner-button'>
                        {this.state.name || this.props.placeholder} 
                    </div>
                    <DownOutlined />
                </Ant.Button>
            </Ant.Dropdown>
        )
    }
}

export default Dropdown