import React from 'react'
import { Layout, Menu, Modal, Icon } from 'antd'
import { connect } from 'react-redux'
import { logout } from '../../actions/app'
import { withRouter, Link } from 'react-router-dom'
import Logo from 'components/Logo'
import actions from 'admin/actions/index'

const { Header } = Layout

class CustomisedHeader extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleMenuItemOnClick = this.handleMenuItemOnClick.bind(this)
    this.handleSelectPage = this.handleSelectPage.bind(this)
    this.state = {
      currentPage: 'scores'
    }
  }

  handleMenuItemOnClick(item) {
    switch (item.key) {
      case 'logout': {
        const handleAppLogOut = () => { 
          this.props.handleAppLogOut() 
          this.props.history.push('/login')
        }
        Modal.confirm({
          title: 'Logout',
          content: 'Are you sure you want to log out?',
          onOk() {
            handleAppLogOut()
          },
          onCancel() {
            // console.log('Cancel')
          },
        })
        break
      }
      case 'login': {
        this.props.history.push('/login')
        break
      }

      default:
    }
  }

  get currentPage(){
    let page = 'scores';
    if(this.props.match.path.split('/')[1] == 'the-big-five') page = 'the-big-five/results'
    return page;
  }
  handleSelectPage(e){
    switch(e.key){
      case 'logout': {
        const handleAppLogOut = () => { 
          this.props.handleAppLogOut() 
          this.props.history.push('/login')
        }
        return Modal.confirm({
          title: 'Logout',
          content: 'Are you sure you want to log out?',
          onOk() {
            handleAppLogOut()
          },
          onCancel() {
            // console.log('Cancel')
          },
        })
      }
      default:
        this.props.history.push(`/${e.key}`)
    }
  }
  get validAdmins(){
    return actions.admins.isValidAdmin()
  }
  render() {
    return (
      <Header className='admin-header'>
        {this.props.showLogo && (
            <div className='logo header-logo'>
              <Link to='/'>
                <Logo />
              </Link>
            </div>
        )}
        {this.props.session.id ? (
          <Menu
            mode='horizontal'
            style={{ lineHeight: '64px' }}
            selectedKeys={[this.currentPage]} 
            onClick={this.handleSelectPage}
            defaultSelectedKeys={['scores']}
          >
            {this.validAdmins && (
              <Menu.Item key='admin'>Admin</Menu.Item>
            )}
            <Menu.Item key='scores'>Odds Calculator</Menu.Item>
            <Menu.Item key='logout'>Logout</Menu.Item>
            {/* <Menu.Item key='the-big-five/results'>B5 Assessment</Menu.Item> */}
          </Menu>
        ) : (
          <Menu
            mode='horizontal'
            selectable={false}
            style={{ lineHeight: '64px' }}
            onClick={this.handleMenuItemOnClick}
            className='button'
          >
            
                <Menu.Item key='login'>Login</Menu.Item>
          </Menu>
        )}
      </Header>
    )
  }
}

const mapStateToProps = (state) => ({
  session: {
    ...(state.session || {})
  }
})

const mapDispatchToProps = dispatch => ({
  handleAppLogOut: () => dispatch(logout()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomisedHeader)
)
