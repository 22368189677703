import {Component, Fragment} from 'react'
import actions from "../../../actions";
import { Form, Col, Row, Steps, Card, Button, AutoComplete, Input } from 'antd'
import MainLayout from 'components/MainLayout'
import {Link} from 'components/elements'
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Item from "antd/lib/list/Item";
import { withRouter } from 'react-router';
const Step = Steps.Step

class MainComponent extends Component{
    constructor(props){
        super(props)
        this.state = {}
        this.handleFinish = this.handleFinish.bind(this)
        this.handleFormulaFinish = this.handleFormulaFinish.bind(this)
    }
    async handleFinish(values){
        const {data} = await actions.invitations.create(values)
        
        this.setState({
            invitationCode: data.invitationCode
        })
    }
    async handleFormulaFinish(values){
        const {data} = await actions.admins.setFormula(values)
        
        this.setState({
            response: data
        })
    }
    componentDidMount(){
        if(!actions.admins.isValidAdmin()){
            this.props.history.push('/')
        }
    }
     
    render(){
        return (
            <Row>
                <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 0}}>
                    
                        <Card>

                            Generate Invitation Code
                            <Form onFinish={this.handleFinish}>
                                Enter Email Address
                                <Form.Item
                                    name='email'
                                    placeholder='Enter Email Address'
                                >
                                    <Input  />
                                </Form.Item>
                                <br />
                                <Button htmlType='submit'>Generate</Button>
                                <br />
                                {this.state.invitationCode && (
                                    <Fragment>
                                        <h2>Invitation Code.  Please email to the invited party</h2>
                                        <h2>
                                            {this.state.invitationCode}
                                        </h2>
                                    </Fragment>
                                )}
                                <br />
                            </Form>
                        </Card>
                </Col>
            </Row>
        )
    }
}
export default withRouter(connect((state)=>(state))(MainComponent))