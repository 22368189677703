import React from 'react'
import './index.less'

class DocumentView extends React.Component {
  render() {
    return (
      <div className='container'>
        <div className='document' dangerouslySetInnerHTML={{__html: this.props.content}} />
      </div>
    );
  }
}

export default DocumentView