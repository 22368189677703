import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  SET_FAVORITES,
  APP_REGISTER_SET
} from '../constants/actionTypes'

export default (state = [], action) => {
  switch (action.type) {
    case APP_REGISTER_SET:
      return []
    case SET_FAVORITES:
      return action.payload || []
    case ADD_FAVORITE:
      return [...state, action.payload]
    case REMOVE_FAVORITE:
        return state.filter((_)=>
            _.id !== action.payload.id
        ) 
    default:
      return state
  }
}
