import {
    APP_LOGIN_SET,
    UPDATE_SURVEY,
    APP_LOGOUT_SET,
    APP_LOGIN_ERROR,
    APP_LOGIN_RESET,
    APP_REGISTER_ERROR,
    APP_REGISTER_SET,
    APP_SET_INVITATION_CODE
  } from '../constants/actionTypes'
  
  export default (state = [], action) => {
    switch (action.type) {
      case APP_LOGIN_SET:
        return action.payload
      case APP_REGISTER_SET:
        return action.payload
      case UPDATE_SURVEY:
        return {
          ...state,
          answers: action.payload
        }
      case APP_SET_INVITATION_CODE:
        console.log(action)
        return {
          invitationCode: action.payload
        }
      case APP_LOGOUT_SET:
        return {}
      case APP_LOGIN_ERROR:
        return action.payload;
      case APP_LOGIN_RESET:
        return action.payload;
      case APP_REGISTER_ERROR:
        return action.payload;
      default:
        return state
    }
  }
  