// Nameing: PAGE_ACTION_STATUS

export const UPDATE_SURVEY = 'survey/update'

export const APP_LOGIN_SET = 'app/login/set'
export const APP_LOGOUT_SET = 'app/logout/set'
export const APP_LOGIN_ERROR = 'app/login/error'
export const APP_LOGIN_RESET = 'app/login/reset'
export const APP_REGISTER_SET = 'app/register/set'
export const APP_REGISTER_ERROR = 'app/register/error'
export const APP_SET_INVITATION_CODE = 'app/invitation/code'

export const LOGIN_EDITFORM_CHANGE = 'login/editForm/change'
export const LOGIN_LOGIN_LOAD = 'login/login/load'
export const LOGIN_LOGIN_SUCCEED = 'login/login/succeed'
export const LOGIN_LOGIN_FAIL = 'login/login/fail'

export const ADMIN_OPENSUBMENU_CHANGE = 'admin/openSubMenu/change'

export const USERLIST_FETCHITEMS_LOAD = 'userLists/fetchItems/load'
export const USERLIST_FETCHITEMS_SUCCEED = 'userLists/fetchItems/succeed'
export const USERLIST_FETCHITEMS_FAIL = 'userLists/fetchItems/fail'
export const USERLIST_CHANGETABLE_CHANGE = 'userLists/changeTable/change'
export const USERLIST_SEARCHTABLE_EDIT = 'userLists/searchTable/edit'
export const USERLIST_SEARCHTABLE_SEARCH = 'userLists/searchTable/search'

export const USERFORM_ENTER_RESET = 'userForm/enter/reset'
export const USERFORM_EDITFORM_CHANGE = 'userForm/editForm/change'
export const USERFORM_FETCHITEM_LOAD = 'userForm/fetchItem/load'
export const USERFORM_FETCHITEM_SUCCEED = 'userForm/fetchItem/succeed'
export const USERFORM_FETCHITEM_FAIL = 'userForm/fetchItem/fail'
export const USERFORM_CREATEITEM_LOAD = 'userForm/createItem/load'
export const USERFORM_CREATEITEM_SUCCEED = 'userForm/createItem/succeed'
export const USERFORM_CREATEITEM_FAIL = 'userForm/createItem/fail'
export const USERFORM_EDITITEM_LOAD = 'userForm/editItem/load'
export const USERFORM_EDITITEM_SUCCEED = 'userForm/editItem/succeed'
export const USERFORM_EDITITEM_FAIL = 'userForm/editItem/fail'
export const USERFORM_DELETEITEM_LOAD = 'userForm/deleteItem/load'
export const USERFORM_DELETEITEM_SUCCEED = 'userForm/deleteItem/succeed'
export const USERFORM_DELETEITEM_FAIL = 'userForm/deleteItem/fail'


export const SET_SCHOOLS = 'schools/set'
export const ADD_SCHOOLS = 'schools/add'

export const ADD_FAVORITE = 'favorites/add'
export const SET_FAVORITES = 'favorites/set'
export const REMOVE_FAVORITE = 'favorites/remove'
export const SET_SCHOOLS_FILTER = 'schools/filter/set'
export const RESET_SCHOOLS_FILTER = 'schools/filter/reset'
export const SET_SCORE_VALUES = 'set/scores'


export const SET_SURVEY_VALUES = 'survey-values/set'
