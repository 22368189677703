import store, {actionTypes} from 'store'
import apiClient from '../apiClient'
import _ from 'lodash'

export const setSurvey = (values)=>{
    // console.log(apiClient.survey.update)
    return apiClient.survey.update(values)
}

export const getSurvey = (userID)=>{
    return apiClient.survey.fetch(userID)
}
