import {
    SET_SCHOOLS_FILTER,
    RESET_SCHOOLS_FILTER
  } from '../constants/actionTypes'
  
export default (state = {}, action) => {
    switch(action.type){
        case SET_SCHOOLS_FILTER:
            if(action.payload){
                return {
                    ...state,
                    [`${action.target}`]: action.payload
                }
            }else{
                state = {
                    ...state,
                };

                delete state[action.target];

                return state;
            }
        case RESET_SCHOOLS_FILTER:
            return {}
        default:
            return state
    }
}