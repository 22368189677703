import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { Row, Col, Input, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import MainLayout from 'components/MainLayout/index'
import SectionHeader from 'components/SectionHeader'
import SectionHeaderTemplate from 'components/SectionHeaderTemplate'
import SectionContent from 'components/SectionContent'
import { CreateButton } from 'components/AppButton'
import Table from './Table'
import apiClient from 'apiClient'
import _ from 'lodash'

import {
  fetchItems,
  changeTable,
  searchTable,
  editSearch,
} from 'actions/userList'

const { Search } = Input
const pageTitle = 'Users'
const storeKey = 'userList'
const createPath = '/admin/users/create'
const editPath = '/admin/users'

class ItemList extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
        this.handleTableOnChange = this.handleTableOnChange.bind(this)
        this.handleSearchOnChange = this.handleSearchOnChange.bind(this)
        this.handleSearchOnClick = this.handleSearchOnClick.bind(this)
        this.handleSearchOnCancel = this.handleSearchOnCancel.bind(this)
        this.state = {
            item: {}
        }
        this.fetchItem = this.fetchItem.bind(this)
    }

    componentDidMount() {
        //this.fetchItems()
        const { isSearching } = this.props
        !isSearching && this.props.editSearch({ search: null })
        this.fetchItem(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isFetchItemsLoading && nextProps.fetchItemsError) {
            message.error(nextProps.fetchItemsError)
        }

        if(nextProps.match.params.id != this.props.match.params.id){
            this.fetchItem(nextProps)
        }
    }

    fetchItem(nextProps){
        const id = nextProps.match.params.id
        apiClient.fetchUser({ id }).then(({data}={})=>{
            this.setState({
                item: data
            })
        })
    }

    fetchItems() {
        const {
            pagination = {},
            sorter = {},
            search,
            isSearching,
        } = this.props
        this.props.fetchItems({
            current: pagination.current,
            pageSize: pagination.pageSize,
            sort: sorter.columnKey,
            order: sorter.order,
            search: (isSearching && search) || null,
        })
    }

    handleCreateButtonOnClick(e) {
        e.preventDefault()
        this.props.history.push(createPath)
    }

    async handleTableOnChange(pagination, filters, sorter) {
        const { columnKey, field, order } = sorter
            await this.props.changeTable({
            pagination,
            filters,
            sorter: {
                columnKey,
                field,
                order,
            },
        })
        this.fetchItems()
    }

    handleSearchOnChange(e) {
        e.preventDefault()
        const search = e.target.value
        this.props.editSearch({ search })
        if (!search) {
            this.props.searchTable({ isSearching: false })
        }
    }

    async handleSearchOnClick() {
        await this.props.searchTable({ isSearching: true })
        await this.props.changeTable({})
        this.fetchItems()
    }

    async handleSearchOnCancel(e) {
        e.preventDefault()
        await this.props.editSearch({ search: null })
        await this.props.searchTable({ isSearching: false })
        await this.props.changeTable({})
        this.fetchItems()
    }

    get scores(){
        return _.sortBy(this.state.item.scores || [], 'score').reverse()
    }

    render() {
        return (
        <div>
            <MainLayout>
            <SectionHeader>
                <SectionHeaderTemplate
                    breadcrumbRoutes={[{ path: '/admin', title: 'Home' }, { title: pageTitle }]}
                    title={pageTitle}
                />
            </SectionHeader>
            <SectionContent>
                <Link to={'/admin/users'}>back</Link>
                <br />
                {this.state.item.id ? (
                    <div>
                        <div style={{float: 'right'}}>
                            <b>{this.state.item.id}</b>
                        </div>
                        <h3>
                            {this.state.item.firstName}
                            &nbsp; 
                            {this.state.item.lastName}
                        </h3>
                        <br />
                        <h3>
                        {this.state.item.email}
                        </h3>
                        <br />
                        <Table 
                            dataSource={this.scores} 
                            bordered
                        />
                    </div>
                ):(
                    <div style={{textAlign: 'center', fontSize: '1.4em'}}>
                        Loading
                        <br />
                        <LoadingOutlined style={{fontSize: '2em'}}  />
                    </div>
                )}
            </SectionContent>
            </MainLayout>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
  const items = [
    {
      id: '1',
      firstName: 'Carson',
      lastName: 'Wright'
    }
  ]
  return {
    items
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: params => dispatch(fetchItems(params)),
  changeTable: params => dispatch(changeTable(params)),
  editSearch: search => dispatch(editSearch(search)),
  searchTable: search => dispatch(searchTable(search)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemList))
