import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Yields from './yields/index'
import store from './store'
import qs from 'qs';
import {sessionActions} from "./actions/index"
const search = String(window.location.search)
const params = qs.parse(search.slice(1, search.length))

if(params.token){
  sessionActions.getSessionWithToken(params.token)
}

if (process.env.NODE_ENV !== 'production') {
  console.log('===== Development =====') // eslint-disable-line
} else {
  console.log('===== Production =====') // eslint-disable-line
}

ReactDOM.render(
  <Provider store={store}>
    <Yields />
  </Provider>,
  document.getElementById('root'),
)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./yields', () => {
    const NextApp = require('./yields').default // eslint-disable-line
    ReactDOM.render(
      <Provider store={store}>
        <NextApp />
      </Provider>,
      document.getElementById('app'),
    )
  })
}
