import store, {actionTypes} from 'store'
import * as apiClient from '../apiClient'
import _ from 'lodash'

export const setSurveyValues = (payload)=>{
    store.dispatch({
        type: actionTypes.SET_SURVEY_VALUES,
        payload
    })
}

export const saveSurveyValues = ()=>{
    if(store.getState().session.id){
        return apiClient.setSurvey({survey: store.getState().survey}).then(({data})=>{
            const payload = data;
            store.dispatch({
                type: actionTypes.UPDATE_SURVEY,
                payload
            })
        })
    }
}

export const getSurvey = ()=>{
    apiClient.survey().then((results)=>{
        store.dispatch({
            type: actionTypes.SET_SURVEY_VALUES,
            payload: _.get(results, 'data.data.survey')
        })
    })
}
