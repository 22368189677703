import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './reducers'

import * as constants from './constants'
const {actionTypes} = constants
const win = window

const configureStore = () => {
  let initialState;
  try{
    initialState = JSON.parse(sessionStorage.getItem('state'))
  }catch(error){
    console.log(error)
  }
  
  initialState = initialState || {
    app: {}
  }

  const middlewares = [thunkMiddleware]
  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(require('redux-immutable-state-invariant').default()) // eslint-disable-line
    middlewares.push(require('redux-logger').default) // eslint-disable-line
  }

  const storeEnhancers = compose(
    applyMiddleware(...middlewares),
    (win && win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ? win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f,
  )

  const store = createStore(reducer, initialState, storeEnhancers)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default // eslint-disable-line
      store.replaceReducer(nextReducer)
    })
  }
  window.store = store;
  
  store.subscribe(()=>{
    const data = JSON.parse(JSON.stringify(store.getState()))
    data.schoolsFilter = {}
    sessionStorage.setItem('state',
      JSON.stringify(data)
    )  
  })
  return store
}

export default configureStore()

export {
  actionTypes
}
