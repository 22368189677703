import React from 'react'
import { Layout } from 'antd'
import {Link} from 'react-router-dom'
import './index.less'

const { Footer } = Layout

class CustomisedFooter extends React.Component {
  render() {
    return (
      <Footer className='app-footer'>
        Copyright - <a href='https://admissions.company'>The Admissions Company</a>, LLC 2013 - 2023 - 
        <Link to='/privacy'> Privacy Policy</Link> - 
        <Link to='/terms'> Terms & Conditions</Link> - 
        <Link to='/eula'> EULA</Link>
        <br/>
        Need help? Contact us at <a href='mailto: info@admissions.company'>support@mitty.app</a>.
      </Footer>
    )
  }
}

export default CustomisedFooter
