import {
    CheckOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import actions from 'admin/actions/index';
import { withRouter } from 'react-router-dom';
import {
    Descriptions,
    Row,
    Col,
    Card
} from 'antd'
import moment from 'moment'

export default withRouter((props)=>{
    const [state, setState] = useState({user: {authentications:[]}})
    useEffect(()=>{
        actions.users.fetch().then((data)=>{

            setState({
                user: data.find((row)=>row.id == props.match.params.id)
            })
        });
    }, [])
    
    return (
        <div>
            {(Object(state.user).authentications || []).map((attempt)=>(
                <Row>
                    <Col lg={24}>
                        <Card>

                            <Descriptions title="" bordered>
                                <Descriptions.Item label="Attempt At">
                                    <div style={{fontSize: '1.2em'}}>
                                        {moment(attempt.createdAt).format('MM/DD/YY hh:mm A')}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Failure/Succes">
                                    <div style={{fontSize: '1.5em'}}>
                                        {attempt.success ? (
                                            <span style={{color: '#00aa00'}}>
                                                <CheckOutlined />
                                            </span>
                                        ) : (
                                            <span style={{color: '#aa0000'}}>
                                                <WarningOutlined />
                                            </span>
                                        )}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="IP Address">
                                    <div style={{fontSize: '1.5em'}}>
                                        {attempt.ipaddress}
                                        
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item label="Physical Location">
                                    <div style={{fontSize: '1.5em'}}>
                                        {(attempt.location && attempt.location.latitude) ? (
                                            <a href={
                                                `https://www.google.com/maps/place/@${attempt.location.latitude},${attempt.location.longitude},13z/data=!4m9!1m2!2m1!1sRestaurants!3m5!1s0x89c7e3ccffa8302d:0x707bb1f4168ee306!8m2!3d39.4136141!4d-76.407178!15sCgtSZXN0YXVyYW50c1oNIgtyZXN0YXVyYW50c5IBDWJhcl9hbmRfZ3JpbGzgAQA`
                                            }>
                                                Location
                                            </a>
                                        ):(
                                            'Location Unavailable'
                                        )}
                                    </div>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
            ))}
        </div>
    )
})