import React from 'react'
import Dropdown from '../Dropdown'

export default (props = {})=>{
    const metroArea = [
        "Atlanta",
        "Boston",
        "Chicago",
        "Dallas",
        "Denver",
        "Detroit",
        "Houston",
        "Los Angeles",
        "Miami",
        "Minneapolis - St. Paul",
        "New York",
        "Philadelphia",
        "Phoenix",
        "San Diego",
        "San Francisco",
        "Seattle",
        "St. Louis",
        "Tampa",
        "Washington DC"
    ].map((_)=> ({
        name: _,
        value: _
    }))

    return <Dropdown 
        data={metroArea}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        type={props.type} 
        value={props.value}
        placeholder={props.placeholder || 'Select a Metro Area'}
    />
}