import {Component, Fragment} from 'react'
import actions from "../../../actions";
import { Form, Col, Row, Steps, Card, Button, AutoComplete, Input } from 'antd'
import MainLayout from 'components/MainLayout'
import {Link} from 'components/elements'
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import _ from 'lodash'
import Item from "antd/lib/list/Item";
import { withRouter } from 'react-router';
const Step = Steps.Step

export default (props)=>(
    <Form onFinish={this.handleFormulaFinish}>
        Set Formula Version
        <br />
        Email:
        <Form.Item
            name='email'
            placeholder='User Email'
        >
            <Input  />
        </Form.Item>
        <br />
        Formula:
        <Form.Item
            name='formula'
            placeholder='Formula Version'
        >
            <Input  />
        </Form.Item>
        <br />
        <Button htmlType='submit'>Submit</Button>
        <br />
        {this.state.response && (
            <Fragment>
                <h2>Updated</h2>
                <h2>
                    {_.get(this, 'state.response.user.email')}
                    <br />
                    {_.get(this, 'state.response.user.formula')}
                </h2>
            </Fragment>
        )}
        <br />
    </Form>
)