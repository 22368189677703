export default (props, school, options)=>{
    const selectedNames = props.favorites.map((school)=> school.name)

    if(selectedNames.includes(school.name)){
      return false
    }
    
    if(options.search){
      if(props.schoolsFilter.name){
        if(!`${school.name}`.toLowerCase().includes(props.schoolsFilter.name.toLowerCase())){
          return false
        }
      }
    }
    if(options.filters){
      if(props.schoolsFilter.selectivity != undefined){
        switch(props.schoolsFilter.selectivity){
          case 5:
            if(school.admission_rate > 20){
              return false
            }
            break;
          case 4:
            if(school.admission_rate < 21 || school.admission_rate > 30){
              return false
            }
            break;
          case 3:
            if(school.admission_rate < 31 || school.admission_rate > 40){
              return false
            }
            break;
          case 2:
            if(school.admission_rate < 41 || school.admission_rate > 50){
              return false
            }
            break;
          case 1:
            if(school.admission_rate < 50){
              return false
            }
            break;
        }
      }
  
      if(props.schoolsFilter.metro){
        if(`${props.schoolsFilter.metro}`.toLowerCase() !==`${school.city}`.toLocaleLowerCase()){
          return false
        }
      }
      if(props.schoolsFilter.state){
        if(`${props.schoolsFilter.state}`.toLowerCase() !==`${school.state}`.toLocaleLowerCase()){
          return false
        }else{
          console.log(props.schoolsFilter.state, school.state)
        }
      }
      if(props.schoolsFilter.sat){
        if(props.schoolsFilter.sat !== Math.round(school.avg_sat)){
          return false
        }
      }
      if(props.schoolsFilter.act){
        if(props.schoolsFilter.act !== Math.round(school.avg_act)){
          return false
        }
      }
    }
    return true
}