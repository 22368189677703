import Academics from './Academics'
import PersonalInformation from './PersonalInformation'
import React, {Component} from 'react'
import actions from '../../../actions'
import {Form} from 'antd'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

class EditUsers extends Component{
    constructor(props){
        super(props)
        this.formRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {}
    }
    handleSubmit(name, {forms}){
        const academics = forms.Academics.getFieldsValue(true)

        const personalInformation = forms.PersonalInformation.getFieldsValue(true)
        // if(this.state.SATACTTest == 'SAT'){
        // delete values.act
        // }else{
        // delete values.satHighestLanguageScore 
        // delete values.satHighestMathScore
        // }
        // values.testType=this.state.SATACTTest || 'SAT'
        const userID = this.props.match.params.id
        const response = actions.surveys.setSurvey({id: userID, survey: {
            academics, personalInformation
        }})
        
        if(response){
            response.then(()=>{;
                this.props.history.push(`/admin/users/${userID}/scores`)
            })
        }
    }
    componentDidMount(){
        this.loadData()
    }
    componentDidUpdate(previousProps){
        if(_.get(this.props, 'match.params.id') != _.get(previousProps, 'match.params.id')){
            this.setState({
                answers: undefined
            }, ()=>{
                this.loadData()
            })

        }
    }
    loadData(){
        const userID = this.props.match.params.id
        const response = actions.surveys.getSurvey({id: userID}).then(({data})=>{
            console.log('loading data', data)
            this.setState({
                answers: Object(data.survey)
            })
        }).catch(()=>{
            this.setState({
                error: 'user unavailable'
            })
        })
    }
    render(){
        return (
            <div>
                {this.state.answers?(
                    <Form.Provider onFormFinish={this.handleSubmit}>
                        <PersonalInformation survey={this.state.answers} />
                        <Academics survey={this.state.answers} />
                    </Form.Provider>
                ):(
                    <div>
                        Loading...
                    </div>
                )}
            </div>
        )
    }
}    

export default withRouter(EditUsers)
