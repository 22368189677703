import React, {Component} from 'react'
import {Input} from "antd";

class ControlledInput extends Component {
    constructor(props){
        super()
        this.state = {
            value: props.value
        }
        this.handleChange = this.handleChange.bind(this)
    }
    
    componentWillReceiveProps(nextProps){
        if(this.props.value != nextProps.value){
            this.setState({value: nextProps.value})
        }
    }
    handleChange(e){
        const value = e.target.value
        this.setState({value: e.target.value}, ()=>{
            this.props.onChange && this.props.onChange({target: {value}})
        })
    }

    render(){
        return <Input className={this.props.className} value={this.state.value} onChange={this.handleChange}/>
    }
}

export default ControlledInput