import axios from 'axios'
import getParams from 'lib/getParams'
import store from 'store'

const client = (options ={})=>{
    const c = {
        ...options,
        baseURL: "https://admissions-company.appspot.com/",  
    }
    
    if(window.location.port == '3000' && window.location.hostname=="localhost"){
        c.baseURL = 'http://localhost:3333'
    }

    if(window.location.hostname=='admissions-company-staging.web.app'){
        c.baseURL='https://staging-dot-admissions-company.appspot.com'
    }
    
    if(window.location.search.includes('version=')){
        const params = getParams()

        c.baseURL=`https://${params.version}.appspot.com`
    }
    
    if(store.getState().session.token){
        c.headers = {
            'Authorization': `Bearer ${store.getState().session.token}`
        }
    }

    return axios.create(c)
}

export const verifyInvitationCode = (invitationCode)=>{
    return client().get(`/admin/invitations/${invitationCode}`)
}

export const universities = (params)=>{
    return client().get('/universities', params)
}

export const survey = ()=>{
    return client().get('/survey')
}

export const setSurvey = (params)=>{
    return client().post('/survey', params)
}

export const favorites = ()=>{
    return client().get('/favorites')
}

export const addToFavorites = (params)=>{
    return client().post('/favorites', params)
}

export const scores = ()=>{
    return client().get('/scores')
}

export const register = (params)=>{
    return client().post('/users', params)
}

export const resetPassword = (params)=>{
    return client().get(`/sessions/password?email=${params.email}`, params)
}

export const setPassword = (params)=>{
    const token = params.token
    
    delete params.token
    return client({
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).put('/users/set-password', params)
}

export const login = (params)=>{
    return client().post('/sessions', params)
}
export const results = (params)=>{
    return client().post('/results', params)
}
export const setFormula = (params)=>{
    return client().put('/admin/set-formula', params)
}
export const saveTheBigFive = (params)=>{
    return client().post('/the-big-five', params)
}
export const fetchTheBigFive = (params)=>{
    return client().get('/the-big-five')
}
export const fetchUsers = (params)=>{
    return client().get('/users')
}

export const fetchUser = (params = {})=>{
    return client().get(`/users/${params.id}`)
}

export const getSessionWithToken = (token)=>{
    return client({
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).put(`/sessions/exchange-token`)
}



const apiClient = {
    universities,
    scores,
    register,
    login,
    results,
    favorites,
    addToFavorites,
    setSurvey,
    resetPassword,
    saveTheBigFive,
    fetchUsers,
    fetchUser
}

window.apiClient = apiClient
export default apiClient