import React from 'react'
import { Form, Divider, Card, Input, Tabs, AutoComplete, Button, Col, Row } from 'antd'
import { ControlledInput, MetroArea, States, SAT, ACT, GPA, Selectivity } from 'components/elements'
import { connect } from 'react-redux';
import { filterActions } from 'actions'
import {debounce} from 'debounce'

export default connect(state=>({schoolsFilter: state.schoolsFilter}))((props)=>{
  const TabPane = Tabs.TabPane

  const handleSelectSchool = (value = '')=>{
    filterActions.setFilter('name', value)
  }

  const handleState = (e)=>{
    filterActions.setFilters(
      ['state', e.target ? e.target.value : e || ''],
      ['metro', undefined]
    )
  }

  const handleMetro = (e)=>{
    filterActions.setFilters(
      ['metro', e.target.value],
      ['state', undefined]
    )
  }

  const handleSAT = (e)=>{
    filterActions.setFilters(
      ['sat', e.target.value],
      ['act', undefined]
    )
  }

  const handleACT = (e)=>{
    filterActions.setFilters(
      ['act', e.target.value],
      ['sat', undefined]
    )
  }

  const handleGPA = (e)=>{
    filterActions.setFilter('gpa', e.target.value)
  }
  const handleSelectivity = (e)=>{
    filterActions.setFilter('selectivity', e.target.value)
  }
  const nameSearch = debounce((value)=>{
    filterActions.setFilter('name', value);
  }, 200)

  const handleNameSearch = (e)=>{
    const value = e.target.value
    nameSearch(value)
  }

  const clearValues = ()=>{
    filterActions.resetFilters()
  }

  return (
    <div className='filters'>
      <Form onSubmit={(e)=> e.preventDefault()}>
        <Card className={'tile'}>
          <label>
            Selectivity Level
          </label>
          <Selectivity type='primary' fullWidth={true} onChange={handleSelectivity} value={props.schoolsFilter.selectivity} />
        </Card>
        <Card className='no-padding tile'>
          <label>
            Location
          </label>
          <Tabs defaultActiveKey="1"   state={{ size: 'large' }}>
            <TabPane tab="States" key="1">
              <States fullWidth={true} type='primary' onChange={handleState} value={props.schoolsFilter.state || ''} />
            </TabPane>
            <TabPane tab="Metro Area" key="2">
              <MetroArea fullWidth={true} type='primary' onChange={handleMetro} value={props.schoolsFilter.metro || ''} />
            </TabPane>
          </Tabs>
        </Card>
        <Card className='no-padding tile' >
          <label>
            Test Type
          </label>
          <Tabs defaultActiveKey="1"   state={{ size: 'large' }}>
            <TabPane tab="SAT" key="1">
              <SAT fullWidth={true} type='primary' onChange={handleSAT} value={props.schoolsFilter.sat} />
            </TabPane>
            <TabPane tab="ACT" key="2">
              <ACT fullWidth={true} type='primary' onChange={handleACT} value={props.schoolsFilter.act} />
            </TabPane>
          </Tabs>
        </Card>
        <Card className={'tile'}>
          <label>
            GPA
          </label>
          <GPA fullWidth={true} type='primary' onChange={handleGPA} value={props.schoolsFilter.gpa} />
        </Card>
        <Card className={'tile text-center'}>
          <Button onClick={clearValues}>Clear Filters</Button>
        </Card>
      </Form>
    </div>
  )
})