
  
  export default (state = null, action) => {
    switch (action.type) {
      case 'invitationSet':
        return action.payload
      case 'app/logout/set':
        return null;
      default:
        return state
    }
  }
  