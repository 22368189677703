import React from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import './index.less'

class LandingHeader extends React.Component {
  render() {
    return (
      <Layout.Header className="landing-header">
        <div className="logo">
          <Link to='/'>
            <Logo />
          </Link> 
        </div>
        <Menu mode="horizontal" selectable={false}>
          <Menu.Item>
            <Link to='/login'>Log in</Link>
          </Menu.Item> 
        </Menu>
      </Layout.Header>
    ); 
  }
}

export default LandingHeader