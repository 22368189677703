import React from 'react'
import { connect } from 'react-redux'
import { Descriptions, Col, Row, Steps, Card, List, Breadcrumb } from 'antd'
import MainLayout from 'components/MainLayout'
import {Link} from 'components/elements'
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Chart from './Chart'
import Charts from './Charts/index'
import _ from 'lodash'
import filtering from '../../../../lib/filtering'
import './index.less'
import store from '../../../../store'
import { withRouter } from 'react-router-dom'
import actions from '../../../actions'
import Panel from './Panel'
import {
  LoadingOutlined,
} from '@ant-design/icons';
const Step = Steps.Step

class Scores extends React.Component {
  constructor() {
    super()
    this.state = {
      end: 10,
      schoolSearch: '',
      showSearchOptions: false
    }
    this.moreSchools = this.moreSchools.bind(this)
    this.lessSchools = this.lessSchools.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleShowSearchOptionsToggle = this.handleShowSearchOptionsToggle.bind(this)
    this.navigate = this.navigate.bind(this)
    this.fetchFavorites = this.fetchFavorites.bind(this)
    this.fetchScores = this.fetchScores.bind(this)
    this.removeFromFavorites = this.removeFromFavorites.bind(this)
    this.handleLock = this.handleLock.bind(this)
    this.handleUnlock = this.handleUnlock.bind(this)
  } 
  componentDidMount(){
    this.fetchFavorites()
  }
  moreSchools(e) {
    e.preventDefault()
    this.setState({
      end: this.state.end + 10
    })
  }
  lessSchools(e) {
    e.preventDefault()
    this.setState({
      end: this.state.end - 10 
    })
  }
  // componentDidMount(){
  //   favorites.getFavorites()
  //   surveyActions.getSurvey()
  // }
  get hasScores(){
    const academics = _.get(store.getState(), 'session.answers.data.survey', {}).academics
    const personalInformation = _.get(store.getState(), 'session.answers.data.survey', {}).personalInformation
    
    if(academics && personalInformation){
      return true
    }
    return false
  }
  fetchUsers(){
    actions.users.fetch().then((data)=>{
      this.setState({
          users: data,
          isFetchingUsers: false
      })
    })
  }
  fetchFavorites(){
    console.log('starting')
    const userID = this.props.match.params.id
    actions.favorites.getFavorites({userID}).then(({data})=>{
      
      this.setState({
          favorites: data || []
      })
    })
  }
  get user(){
    return (this.state.users || []).find(({id})=>(
      id == this.props.match.params.id
    )) || {}
  }
  fetchScores(){
    // if(this.hasScores && !this.scores){
      const userID = this.props.match.params.id
      if(userID && (this.state.favorites || []).length > 0){
        console.log('the id should not be blank', userID)
        actions.scores.getScoreValues(userID).then(({data})=>{
          this.setState({
            values: data,
            isFetching: false
          })
        }).catch(()=>{
          setTimeout(()=>{
            this.setState({isFetching: false})
          }, 1000)
        })
        actions.scores.getScoreValues(userID).then(({data})=>{
          this.setState({
            values: data,
            isFetching: false
          })
        }).catch(()=>{
          setTimeout(()=>{
            this.setState({isFetching: false})
          }, 1000)
        })
      }else{
        setTimeout(()=>{
          this.setState({isFetching: false})
        }, 1000)
      }
    // }else{
    //   this.waitFetchScores()
    // }
  }
  componentDidUpdate(previousProps){
    if(!this.state.users && !this.state.isFetchingUsers){
      return this.setState({
        isFetchingUsers: true
      },()=>{
        this.fetchUsers()
      })
    }
    
    if(!this.state.isFetching && !this.state.values && (this.state.fetchScoresX || 0) < 6){
      return this.setState({
        isFetching: true,
        fetchScoresX: (this.state.fetchScoresX || 0) + 1
      },()=>{
        this.fetchScores()
      })
    }else if((this.state.fetchScoresX || 0) >= 6  && !this.state.failedToFetchResults){
      return this.setState({
        failedToFetchResults: true
      })
    }
    if(this.props.match.params.id != previousProps.match.params.id){
      this.setState({
        fetchScoresX: 0,
        failedToFetchResults: false
      })
      this.fetchUsers()
    }
    
    if(this.props.match.params.id != previousProps.match.params.id){
      
      this.fetchFavorites()
    }
  }
  addToFavorites(school, e){
    e.preventDefault()
    
    // favorites.addToFavorites(school)
  }
  removeFromFavorites(school){
    const favorites = this.state.favorites.filter((_school)=>
      _school.id != school.id
    )
    const userID = this.props.match.params.id
    this.setState({
      favorites
    })
    actions.favorites.setFavorites({
      userID,
      favorites
    }).then(()=>{
      this.fetchScores()
    })
    // favorites.removeFromFavorites(school)
  }
  school(id){
    return this.props.schools.find((school)=>(
      school.id == id
    )) || {}
  }
  get filteredSchools(){
    const schoolIDs = this.props.favorites.map((_)=> _.id)
    return this.props.schools.filter((school)=>(
      filtering(this.props, school, {filters: true})
    )).filter((school)=>
      !schoolIDs.includes(school.id)
    ).slice(0, 14).map((school)=>({
      key: school.id,
      value: school.name
    })).sort((a, b)=>
      a.value < b.value ? -1 : 1
    )
  }
  get searchedSchools(){
    const schoolIDs = this.props.favorites.map((_)=> _.id)
    return this.props.schools.filter((school)=>(
      filtering(this.props, school, {search: true})
    )).filter((school)=>
      !schoolIDs.includes(school.id)
    ).slice(0, this.state.end).map((school)=>({
      key: school.id,
      value: school.name
    })).sort((a, b)=>
      a.value < b.value ? -1 : 1
    )
  }

  handleSelect(undefined, {key: id}){
    if(id){
      const school = this.school(id)
      const favorites = _.get(this, 'state.favorites', []) || [];
      
      favorites.push(school)
      
      actions.favorites.setFavorites({
        userID: this.user.id,
        favorites
      }).then(()=>{
        this.fetchScores()
      })
      this.setState({
        schoolSearch: ''
      })
    }
  }
  handleSearch(value){
    this.setState({
      schoolSearch: value
    })
    if(this.filter){
      clearTimeout(this.filter)
    }
    this.filter = setTimeout(()=>{
      // filterActions.setFilter('name', value);
    }, 300)
  }
  handleShowSearchOptionsToggle(){
    this.setState({
      showSearchOptions: !this.state.showSearchOptions
    })
  }

  get firstName(){
    return this.user.firstName || 'No First Name'
  }
  get lastName(){
    return this.user.lastName || 'No Last Name'
  }
  get email(){
    return this.user.email || 'No Email'
  }
  navigate(path){
    this.props.history.push(path)
  }
  get scores(){
    return this.state.values || []
  }
  get academics(){
    return _.get(this.user, 'survey.academics', {})
  }
  get personalInformation(){
    return _.get(this.user, 'survey.personalInformation')
  }
  school($id){
    return this.props.schools.find(({id})=>id == $id) || {}
  }
  handleLock(){
    actions.users.lock({id: this.user.id}).then((data)=>{
      const users = this.state.users.map((user)=>{
        if(user.id == data.id){
          return data
        }else{
          return user
        }
      })
      this.setState({
          users
      })
    })
  }
  handleUnlock(){
    actions.users.unlock({id: this.user.id}).then((data)=>{
      const users = this.state.users.map((user)=>{
        if(user.id == data.id){
          return data
        }else{
          return user
        }
      })
      this.setState({
          users
      })
    })
  }
  render() {
    let scores;
    const favorites = _.get(this, 'states.favorites', []);
    scores = favorites.map((row, i)=>{
      return ({
        id: row.id,
        name: _.get(this, `props.scores[${row.id}].name`, row.name),
        value: _.get(this, `props.scores[${row.id}].score`),
        // difficulty: _.shuffle(['Very', 'Moderate', 'Somewhat'])[0],
        state: _.get(this, `props.scores[${row.id}].state`, row.state)
      })
    }).sort((a, b)=> a.name < b.name ? -1 : 1 )

    return (
      <div>
        <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item><Link to={'/admin/users'}>Users</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Scores</Breadcrumb.Item>
          </Breadcrumb>
        {this.state.showSearchOptions && (
          <Row class='advanced-panel'>
            <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 1}}>
              <Panel direction={'horizontal'}/>
              <div className='filtered-results'>
                <Card bodyStyle={{padding: '0px'}}>
                  <List
                    bordered
                    dataSource={this.filteredSchools}
                    renderItem={item => (
                      <List.Item onClick={this.handleSelect.bind(null, null,  item)}>
                        <PlusOutlined /> {item.value}
                      </List.Item>
                    )}
                  />
                </Card>
              </div>
              <br />
            </Col>
          </Row>
        )}

        {this.user.isLocked ? (
          <a href='#' onClick={this.handleUnlock}>Unlock</a>
        ):(
          <a href='#' onClick={this.handleLock}>Lock</a>
        )}
        <h2>
          {this.firstName} {this.lastName} {this.email && `(${this.email})`}
        </h2>
        <br />
        {/* <Row type='flex'>
          <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 0}}>
            <div style={{padding: 10, width: '100%', position: 'relative'}}>
              <div style={{position: 'relative', display: 'inline-block', width: 'calc(100%)'}}>
                <label>Search to Add Schools</label>
                <AutoComplete
                  style={{width: '100%'}}
                  options={this.searchedSchools}
                  onSelect={this.handleSelect}
                  onSearch={this.handleSearch}
                  placeholder="Search for a School"
                  value={this.state.schoolSearch}
                />
              </div>
              // {<div className={'more-options'} onClick={this.handleShowSearchOptionsToggle}>
              //   Advanced Search
              // </div>
            </div>
          </Col>
        </Row> */}

        <Row type='flex'>
          <Col xs={{span: 24, order: 0}} lg={{span: 12, order: 1}}>
          <div  style={{textAlign: 'left'}}>
            {this.scores.length != 0 ?(
                <Charts
                  universities={this.scores}
                  // onRemoveSchool={this.removeFromFavorites}
                />
                /* <Chart
                  data={scores}
                  onRemoveSchool={this.removeFromFavorites}
                /> */
              ):(
                  <div>
                    {!this.state.failedToFetchResults ? (
                      <div style={{fontSize: '2em', color: '#555'}}>
                        <br />
                        <br />
                        <br />
                        <br />
                        Loading Scores
                        <br />
                        <LoadingOutlined style={{fontSize: '2em'}} />
                      </div>
                    ):(
                      <div>Could not load scores for this user</div>
                    )}
                  </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Row>
              <Col xs={24} lg={12}>
                {this.academics && (
                  <Card style={{width: '100%', marginLeft: '0px'}}>
                    <Descriptions title="Academic Info" bordered>
                      <Descriptions.Item label="School Name" span={3}>{this.academics.name}</Descriptions.Item>
                      <Descriptions.Item label="School City" span={3}>{this.academics.city}</Descriptions.Item>
                      <Descriptions.Item label="State" span={3}>{this.academics.state}</Descriptions.Item>
                      <Descriptions.Item label="Class Rank" span={3}>{this.academics.classRank}</Descriptions.Item>
                      <Descriptions.Item label="Curriculum Difficulty" span={3}>{this.academics.curriculumDifficulty}</Descriptions.Item>
                      <Descriptions.Item label="GPA" span={3}>{this.academics.gpa}</Descriptions.Item>
                      <Descriptions.Item label="Grade" span={3}>{this.academics.grade}</Descriptions.Item>
                      <Descriptions.Item label="Graduation Year" span={3}>{this.academics.graduationYear}</Descriptions.Item>
                      <Descriptions.Item label="Test Type" span={3}>{this.academics.testType}</Descriptions.Item>
                      <Descriptions.Item label="Sat Highest Language Score">{this.academics.satHighestLanguageScore}</Descriptions.Item>
                      <Descriptions.Item label="Sat Highest Math Score">{this.academics.satHighestMathScore}</Descriptions.Item>
                    </Descriptions>
                  </Card>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={12}>
                  {this.personalInformation && (
                    <Card style={{width: '100%', marginLeft: '0px'}}>
                      <Descriptions title="Personal Info" bordered>
                        <Descriptions.Item label="First Name" span={2}>{this.personalInformation.firstName}</Descriptions.Item>
                        <Descriptions.Item label="Last Name" span={2}>{this.personalInformation.lastName}</Descriptions.Item>
                        <Descriptions.Item label="Ethnicity" span={3}>{this.personalInformation.ethnicity}</Descriptions.Item>
                        <Descriptions.Item label="Legacy" span={3}>
                          {(!_.isNil(this.personalInformation.father) || !_.isNil(this.personalInformation.mother)) && (
                            <CheckOutlined />
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Father" span={2}>
                          {(this.personalInformation.father || []).map((schoolID)=> 
                            this.school(schoolID).name
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Mother" span={2}>
                          {(this.personalInformation.mother || []).map((schoolID)=> 
                            <div>
                              {this.school(schoolID).name}
                            </div>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Gender'} span={24}>
                          {this.personalInformation.gender}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Leadership'} span={24}>
                          {this.personalInformation.leadership}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Local Organizations'} span={24}>
                          {this.personalInformation.localOrganizations && (
                            <CheckOutlined />
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label='National Organizations' span={24}>
                          {this.personalInformation.nationalOrganizations}
                        </Descriptions.Item>
                        <Descriptions.Item label='Organization Awards' span={24}>
                          {this.personalInformation.organizationAwards}
                        </Descriptions.Item>
                        <Descriptions.Item label='School Awards' span={24}>
                          {this.personalInformation.schoolAwards}
                        </Descriptions.Item>
                        <Descriptions.Item label='State Regional' span={24}>
                          {this.personalInformation.stateRegional}
                        </Descriptions.Item>
                        <Descriptions.Item label='Zipcode'>
                          {this.personalInformation.zipcode}
                        </Descriptions.Item>
                      </Descriptions>
                    </Card>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
    {/* // </MainLayout> */}
  }
}

const connected = withRouter(connect((state)=>(
  state
))(Scores))
export default connected
