import filtering from './filtering'
import getParams from './getParams';

export const _filtering = filtering
export const times = (length)=>{
    let values = [];
    for(let i=0; i < length; i++){
        values.push(i)
    }

    return values;
}

export const getUrlParams = (search) => {
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    let params = {}
    hashes.map(hash => {
        let [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })

    return params
}

export {
    getParams
};