import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Radio, Form, Icon, Divider, Col, Row, Steps, Card, Input, Tabs, Checkbox, DatePicker, Select, Switch } from 'antd'
import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity, Upload, Button } from 'components/elements'
import MainLayout from '../../components/MainLayout'
import SectionContent from 'components/SectionContent'
import {Link} from 'components/elements'
import moment from 'moment'
import * as utils from 'utils'
import results from './results'
import questions from './questions'
import * as Router from 'react-router-dom'
import {favorites, theBigFiveActions} from 'actions'
import _ from 'lodash'
const TabPane = Tabs.TabPane
const Step = Steps.Step
const {RangePicker} = DatePicker;

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;

const responseTypes = [
    'Disagree',
    'Slightly Disagree',
    'Neutral',
    'Slightly Agree',
    'Agree'
]

class Academics extends React.Component {
  constructor(){
    super()

    this.state = {
      answers: {}
    }
    this.onAnswer = this.onAnswer.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.redoTest = this.redoTest.bind(this)
  }
  componentDidMount(){
    theBigFiveActions.fetch().then(({data}={})=>{
      this.setState({
        answers: data,
        isLoaded: true
      },()=>{
        this.processResults(()=>{
          if(!this.isAnswered){
            this.props.history.push('/the-big-five/questions')
          }
        })
      })
    }).catch((error)=>{
      this.props.history.push('/the-big-five/questions')
    })
  }
  processResults(cb){
        const _results = results(this.state.answers)

        this.setState({
            results: _results
        }, cb)
  }
  onAnswer(id, e){
    const answers = this.state.answers
    answers[id] = e.target.value

    this.setState({
        answers
    })
  }
  handleSubmit(e){
    e.preventDefault()

    theBigFiveActions.save(this.state.answers).then((data)=>{
      this.processResults()
      this.props.history.push('/the-big-five/results')
    })
  }
  get isAnswered(){
    const values = Object.values(this.state.results || {})
    return values.length > 0 && values.reduce((acc, value)=>
      acc && value
    , true)
  }
  get results(){
    return this.state.results || {}
  }
  
  get currentPage(){
    return [
      '/the-big-five/questions', '/the-big-five/results'
    ].indexOf(
      this.props.history.location.pathname
    )
  }
  redoTest(){
    this.props.history.push('/the-big-five/questions')
  }
  componentDidUpdate(){
    if(this.isLoaded && !this.isAnswered && this.props.history.location.pathname == '/the-big-five/results'){
      this.props.history.push('/the-big-five/questions')
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    const selectedNames = this.props.favorites.map((school)=> school.name)
    let data = this.props.schools
    data = data.filter((school)=>
      !selectedNames.includes(school.name)
    ).slice(0, this.state.end)

   const rank =   [{
      name: 'Top 1',
      value: 1
    },
    {
      name: 'Top 5',
      value: 5
    },
    {
      name: 'Top 10',
      value: 10
    },
    {
      name: 'Top 25',
      value: 25
    },
    {
      name: 'Top 50',
      value: 50
    },
    {
      name: 'Not Available',
      value: -1
    },
    {
      name: 'Decline to Answer',
      value: -2
    }]

    const year = parseInt(moment().format('YYYY'))
    const years = utils.times(5).map((i)=>({ name: year + i, value: year + i}))

    const plainOptions = ['Apple', 'Pear', 'Orange'];
    const options = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange' },
    ];
    const optionsWithDisabled = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange', disabled: false },
    ];

    return (
      <MainLayout>
        <Form onSubmit={this.handleSubmit}>
          <Card>
            <Steps  size="small" current={this.currentPage}>
              <Step title="Questions" />
              <Step title='Results' />
            </Steps>
            <Router.Route path='/the-big-five/results' render={()=>(
              <Fragment>
                <br />
                <br />
                <Link to='/the-big-five/questions' className='ant-btn ant-btn-primary'>
                  Previous
                </Link>
              </Fragment>
            )} />
          </Card>
          <br />
          <br />
          <Row gutter={20} style={{margin: '0px 10px'}}>
            <Col sm={{span: 24}}>
                <Card>
                    <Router.Switch>
                      <Router.Route path='/the-big-five/results' render={()=>(
                        <div>
                          <Row>
                            <Col lg={6} md={12} xs={24} style={{padding: '1em' }}>
                              {this.results['Extroversion'] && (
                                <div>
                                  <h2 className={'text-center'}>Extroversion</h2>
                                  You {this.results['Extroversion'] < 16 ? (
                                    'tend to be outgoing and energetic. You are also assertive, talkative, with positive emotions, and seek the company of others.'
                                  ): (
                                    'tend to be solitary and reserved. You often are reflective as a person.'
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col lg={6} md={12} xs={24} style={{padding: '1em' }}>
                              {this.results['Agreeableness'] && (
                                <div>
                                  <h2 className={'text-center'}>Agreeableness</h2>
                                  You {this.results['Agreeableness'] < 16 ? (
                                    'tend to be friendly, compassionate, and cooperative. You are also often trusting and helpful nature.'
                                  ): (
                                    'tend to be analytic and detached. You are also often competitive or challenging.'
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col lg={6} md={12} xs={24} style={{padding: '1em' }}>
                              {this.results['Conscientiousness'] && (
                                <div>
                                  <h2 className={'text-center'}>Conscientiousness</h2>
                                  You {this.results['Conscientiousness'] < 16 ? (
                                    'are easy-going and perhaps careless. You are also flexible and spontaneous.'
                                  ): (
                                    'are efficient and organized. You tend to be self-disciplined and dependable.'
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col lg={6} md={12} xs={24} style={{padding: '1em' }}>
                              {this.results['Neuroticism'] && (
                                <div>
                                  <h2 className={'text-center'}>Neuroticism</h2>
                                  You {this.results['Neuroticism'] < 16 ? (
                                    'have a high level of emotion as a reaction to stress. You may correlate with perceiving situations as threatening.'
                                  ): (
                                    'tend to be calm, emotionally stable, without consistent negative feelings. '
                                  )}
                                </div>
                              )}
                            </Col>
                            <Col lg={6} md={12} xs={24} style={{padding: '1em' }}>
                              {this.results['Openness to Experience'] && (
                                <div>
                                  <h2 className={'text-center'}>Openness to Experience</h2>
                                  You {this.results['Openness to Experience'] < 16 ? (
                                    'are consistent and cautious, seeking fulfillment in perseverance, tending to be pragmatic and data-driven'
                                  ): (
                                    'tend to be inventive and curious.  You have a preference for variety over routine, and seeking profound experiences.'
                                  )}
                                </div>
                              )}
                            </Col>
                          </Row>
                          <Row>
                              <Col>
                                <Button onClick={this.redoTest}>
                                  Redo Test
                                </Button>
                              </Col>
                          </Row>
                        </div>
                      )} />
                      
                      <Router.Route path='/the-big-five/questions' render={()=>(
                        <div>
                          {questions.map((question, i)=>
                              <div style={{display: 'block'}}>
                                  <strong>
                                      {i + 1}. {question}
                                  </strong>
                                  <br />
                                  <Radio.Group onChange={this.onAnswer.bind(null, i + 1)}>
                                      {responseTypes.map((option, i)=>
                                          <Radio name={`big-five-question-${i}`} value={i + 1}>
                                              {option}
                                          </Radio>
                                      )}
                                  </Radio.Group>
                                  <br />
                                  <br />
                              </div>
                          )}
                          Please note that the Big 5 personality test, as used on Mitty, is not designed to be a comprehensive personality test.  Nor is it intended to be used as a diagnostic tool.  Mitty uses the results of the Big 5 test in order to better help its users in the process of searching for the right college.
                          <br />
                          <Button type="primary" htmlType="submit" className="login-form-button">
                            Submit
                          </Button>
                        </div>
                      )} />
                    </Router.Switch>
                </Card>
            </Col>
          </Row>

        </Form>
      </MainLayout>
    )
  }
}

const connected = connect((state)=>(
  state
), ()=>({
  addToFavorites: favorites.addToFavorites,
  removeFromFavorites: favorites.removeFromFavorites
}))(Academics)
export default connected;
