import React from 'react'
import { Card, Layout } from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import TermsFields from './TermsFields'
import AccountFields from './AccountFields'
import AppFooter from '../../components/AppFooter'
import LandingHeader from '../../components/LandingHeader'
import './index.less'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0
    }
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }
  
  nextStep() {
    this.setState({
      currentStep: this.state.currentStep + 1
    });
  }

  previousStep() {
    this.setState({
      currentStep: this.state.currentStep - 1
    });
  }
  
  render() {
    if (this.props.session.id) {
      return <Redirect to={{ pathname: '/scores' }} />
    }

    let currentStepElement;
    switch (this.state.currentStep) {
      case 0:
        currentStepElement = 
          <TermsFields 
            nextStep={this.nextStep} 
          />
        break;
      case 1:
        currentStepElement =   
          <AccountFields
            onSubmit={this.props.handleFormOnSubmit}
            formFieldValues={this.props.formFieldValues}
            isLoading={this.props.isLoginLoading}
            previousStep={this.previousStep}
          />;
        break;
    }

    return (
      <Layout>
        <div className="hero">
          <LandingHeader />
          <div className="main">
            <div className="main-content">
              <Card className='login-form'>
                <div className='header'>
                  <h1>Signup for Mitty</h1>
                </div>
                {currentStepElement}
              </Card>
            </div>
          </div>
        </div>
        <div style={{clear: "both"}}></div>
        <AppFooter />
      </Layout>
    )
  }
}

const mapStateToProps = (state) => (state)

const mapDispatchToProps = ()=> ({})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
