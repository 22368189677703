import React from 'react'
import { Button, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'

export const CreateButton = props => (
  <Button type='primary' {...props}><PlusOutlined />Create</Button>
)

export const TableRowEditButton = props => (
  <Link to={props.to}>
    <EditOutlined style={{ marginRight: '6px' }} /> Edit
  </Link>
)

export const TableRowButton = props => (
  <Link to={props.to}>
    {/* <Icon type={props.type} style={{ marginRight: '6px' }} /> */}
  {props.value}</Link>
)

export class TableRowDeleteButton extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleOnClick = this.handleOnClick.bind(this)
  }

  handleOnClick() {
    const handleOnOk = () => { this.props.deleteAction() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      onOk() {
        handleOnOk()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }
  render() {
    return <a href='#' onClick={this.handleOnClick}><MinusOutlined style={{ marginRight: '6px' }} />Delete</a>
  }
}
