import React, {Component} from 'react'
import _ from 'lodash'
import StopLight from './StopLight'
import {Card} from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import './index.less'
class Charts extends Component {
    render(){
        return (
            <div className={'results'}>
                {_.get(this, 'props.universities', []).map((university)=>{
                    return <div className={'school-score'} key={university.id}>
                        {/* <div className='remove-school' onClick={this.props.onRemoveSchool.bind(null, university)}>
                            <CloseCircleOutlined />
                        </div> */}
                        <StopLight score={university.score}  />
                        <div className='score-name'>
                            {university.name}
                        </div>
                    </div>
                })}
            </div>
        )
    }
}

export default Charts