import _ from 'lodash'
import {
    SET_SURVEY_VALUES,
    APP_LOGOUT_SET
  } from '../constants/actionTypes'
  
  const survey =  (state = {}, action) => {
    
    switch (action.type) {
      case SET_SURVEY_VALUES:
        return {
            ...state, 
            ..._.get(action, 'payload', {})
        }
      case APP_LOGOUT_SET:
        return {}
      default:
        return state
    }
  }
  
  export default survey