import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Icon, Alert } from 'antd'
import { MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import {sessionActions} from 'actions'
const FormItem = Form.Item

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {}
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleOnChange(e) {
    if (this.props.session.loginError) {
      sessionActions.reset();
    }
  }

  handleOnSubmit(values) {
    sessionActions.resetPassword(values).then(()=>{
      this.setState({
        sent: true
      })
    })
  }
  render() {

    let loginAlert;
    if (this.props.session.loginError) {
      loginAlert = <Alert type='error' message='Invalid username or password.' />;
    }
    return (
      <Form onFinish={this.handleOnSubmit}>
        {loginAlert}
        <br />
        {this.state.sent ? (
          <div>
            <h2 style={{color: "#00aa00"}}>Password reset email sent! <CheckCircleOutlined /></h2>
            <br />
          </div>
        ) : (
          <Fragment>
            <FormItem
              name='email'
              rules={
                [{ required: true, type: 'email', message: 'Please input a valid email.' }]
              }
            >
              <Input prefix={<MailOutlined  style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' onChange={this.handleOnChange} />
            </FormItem>
            <FormItem>
              <Button
                type='primary'
                htmlType='submit'
                style={{ width: '100%' }}
                size='large'
              >
                Reset Password
              </Button>
            </FormItem>
          </Fragment>
        )}
      </Form>
    )
  }
}

const CustomizedForm = LoginForm

export default connect((state) => state)(CustomizedForm);