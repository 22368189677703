import axios from 'axios'
import store from 'store'

const client = ()=>{
    const c = {
        baseURL: "https://admissions-company.appspot.com/",  
    }
    if(window.location.port == '3000'){
        c.baseURL = 'http://localhost:8080'
    }
    
    if(store.getState().session.token){
        c.headers = {
            'Authorization': `Bearer ${store.getState().session.token}`
        }
    }

    return axios.create(c)
}



const apiClient = {
    invitations: {
        create:(params = {})=>{
            return client().post(`/admin/invitations`, params)
        }
    },
    users: {
        fetch:(params = {})=>{
            return client().get(`/admin/users`, params)
        },
        update:(params = {})=>{
            return client().put(`/admin/users/${params.id}`, params)
        }
    },
    scores: {
        fetch:(params = {})=>{
            return client().get(`/admin/users/${params.id}/scores?id=${params.id}`)
        }
    },
    survey: {
        update:(params = {})=>{
            return client().put(`/admin/users/${params.userID}/answers`, params)
        },
        fetch:(params = {})=>{
            return client().get(`/admin/users/${params.userID}/answers?id=${params.id}`)
        }
    },
    favorites: {
        set:(params = {})=>{
            return client().put(`/admin/users/${params.userID}/favorites?id=${params.userID}`, params)
        },
        fetch:(params = {})=>{
            return client().get(`/admin/users/${params.userID}/favorites?id=${params.userID}`)
        }
    }
}

window.apiClient = apiClient
export default apiClient