import React from 'react'
import { connect } from 'react-redux'
import { Form, Divider, Col, Row, Steps, Card, Input, Tabs, Checkbox, DatePicker, Radio } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity, Upload, Button } from 'components/elements'
import MainLayout from '../../components/MainLayout'
import SectionContent from 'components/SectionContent'
import Panel from './Panel'
import {Link} from 'components/elements'
import moment from 'moment'
import * as utils from 'utils'
import {favorites} from 'actions'
import {surveyActions} from 'actions'
const TabPane = Tabs.TabPane
const Step = Steps.Step

const {RangePicker} = DatePicker;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

class Academics extends React.Component {
  constructor(){
    super()

    this.state = {
      end: 10,
      SATTests: [],
      APExams: [],
      SATCount: 0,
      APCount: 0,
      value: 1,
      organizations: [],
      organizationCount: 0
    }


    this.handleLeadership = this.handleLeadership.bind(this)
    this.handleOrganization = this.handleOrganization.bind(this)
    this.removeOrganization = this.removeOrganization.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOpenCloseOrganizationDates = this.handleOpenCloseOrganizationDates.bind(this)
  }
  moreSchools(e){
    e.preventDefault()

    this.setState({
      end: this.state.end + 10 
    })
  }
  addToFavorites(school){
    this.props.addToFavorites(school)
  }
  removeFromFavorites(school){
    this.props.removeFromFavorites(school)
  }
  calculateOdds(){
    this.props.history.push('/academics')
  }
  addToSATTests(type){
    const SATCount = this.state.SATCount + 1
    this.setState({
      SATTests: [...this.state.SATTests, {id: SATCount}],
      SATCount
    }, ()=>{
      this[`SAT-${type}-${this.state.SATTests.length - 1}`].input.focus()
    })
  }
  addToAPExams(type){
    const APCount = this.state.APCount + 1
    this.setState({
      APExams: [...this.state.APExams, {id: APCount}],
      APCount
    }, ()=>{
      this[`AP-${type}-${this.state.APExams.length - 1}`].input.focus()
    })
  }
  removeSATTest(i){
    const SATTests = [...this.state.SATTests]
    SATTests.splice(i , 1);

    this.setState({
      SATTests
    })
  }
  removeAPExam(i){
    const APExams = [...this.state.APExams]
    APExams.splice(i, 1);
    
    this.setState({
      APExams
    })
  }
  handleLeadership(values){
    this.setState({
      value: values.reduce((a, _, i)=> a + 1, 1)
    }, ()=>{
      surveyActions.setSurveyValues({
        leadership: this.state.value
      })
    })
  }
  componentWillUpdate(_,a){
    
  }
  handleSubmit(e){
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values)=>{
      if(!err){
        surveyActions.setSurveyValues({
          leadership: {
            ...values,
            localOrganizations: !!values.localOrganizations,
            nationalOrganizations: !!values.nationalOganizations,
            organizationAwards: !!values.organizationAwards,
            stateRegional: !!values.stateRegional,
            schoolAwards: !!values.schoolAwards,
          }
        })
        
        this.props.history.push('/personal-information')
      }
    })
  }
  removeOrganization(i){
    const organizations = [...this.state.organizations]
    organizations.splice(i, 1);
    
    this.setState({
      organizations
    })
  }
  handleOrganization(type, e){
    e && e.preventDefault && e.preventDefault()

    const organizationCount = this.state.organizationCount + 1
    this.setState({
      organizations: [...this.state.organizations, {id: organizationCount}],
      organizationCount
    }, ()=>{
      const i = this.state.organizations.length - 1
      if(this[`organization-${type}-${i}`].input){
        this[`organization-${type}-${i}`].input.focus();
      }else{
        this[`organization-${type}-${i}`].focus();
        this.setState({
          [`organization-dates-open-${i}`]: true
        })
      }
    })
  }
  handleOpenCloseOrganizationDates(i, value){
    this.setState({
      [`organization-dates-open-${i}`]: value
    })
  }
  render() {
    const selectedNames = this.props.favorites.map((school)=> school.name)
    let data = this.props.schools
    data = data.filter((school)=>
      !selectedNames.includes(school.name)
    ).slice(0, this.state.end)
    const { getFieldDecorator } = this.props.form
    
    const grades =[
      {name: 9, value: 9},
      {name: 10, value: 10},
      {name: 11, value: 11},
      {name: 12, value: 12}
    ]
    
   const rank =   [{
      name: 'Top 1',
      value: 1
    },
    {
      name: 'Top 5',
      value: 5
    },
    {
      name: 'Top 10',
      value: 10
    },
    {
      name: 'Top 25',
      value: 25
    },
    {
      name: 'Top 50',
      value: 50
    },
    {
      name: 'Not Available',
      value: -1
    },
    {
      name: 'Decline to Answer',
      value: -2
    }]

    const year = parseInt(moment().format('YYYY'))
    const years = utils.times(5).map((i)=>({ name: year + i, value: year + i}))

    const plainOptions = ['Apple', 'Pear', 'Orange'];
    const options = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange' },
    ];
    const optionsWithDisabled = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange', disabled: false },
    ];    

    return (
      <MainLayout>
        <Form onSubmit={this.handleSubmit}>
          <Card>
            <Steps  size="small" current={2}>
              <Step title="Your Schools" />
              <Step title="Academics" />
              <Step title="Leadership" />
              <Step title='Personal Information' />
              <Step title='Legacy Information' />
            </Steps>
            <br />
            <br />
            <Link to='/personal-information' className='ant-btn ant-btn-primary'>
              Next
            </Link>
            <br />
            <br />
            Want to make changes? &nbsp;
            <Link to='/academics'>
              Previous
            </Link>
          </Card>
          <br />
          <Row gutter={20} style={{margin: '0px 10px'}}>
            <Col xs={{span: 24}}>
              <Card>
                <h2 className='text-center'>
                  Leadership Qualities
                </h2>
                <br />
                <div className='text-center'>
                  {getFieldDecorator(`curriculumDifficulty`, {
                    rules: [{ required: true, message: 'Please input a valid value.' }],
                  })(
                    <RadioGroup>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </RadioGroup>
                  )}
                </div>
                <br />
                Leadership qualities are demonstrated in various ways. Have you won any awards or recognition at or outside school? 
                Do you have a leadership position in any school or extra-curricular activity or group? 
                An example of the highest level of leadership quality would be an individual who has won multiple awards, 
                is the captain of at least one sports team, and holds a leadership position within another school/extracurricular activity. 
                Another example is someone who has demonstrated extraordinary civic or volunteer activities, 
                going so far above and beyond others so as to be recognized for his/her efforts outside the school system.
                <br />
                <br />
                <br />
                <h2 className='text-center'>
                  Jobs/Internships
                </h2>
                {this.state.organizations.map((row, i)=>
                  <React.Fragment key={`organization-${row.id}`}>
                    <br />
                    <Row gutter={12}>
                      <Col span={2}>
                        <Button type='unstyled' onClick={this.removeOrganization.bind(null, i)}>
                          <CloseCircleOutlined />
                        </Button>
                      </Col>
                      <Col span={22}>
                        {getFieldDecorator(`organizationName${row.id}`, {
                          rules: [{ required: true, message: 'Please input a valid value.' }],
                        })(
                          <Input placeholder='Organization Name' ref={(_)=>{ this[`organization-name-${i}`] = _}} />
                        )}
                        <br />
                        <br />
                        {getFieldDecorator(`organizationDates${row.id}`, {
                          rules: [{ required: true, message: 'Please input a valid value.' }],
                        })(
                          <RangePicker 
                            ref={(_)=>{ this[`organization-dates-${i}`] = _}} 
                            open={this.state[`organization-dates-open-${i}`]}
                            onOpenChange={this.handleOpenCloseOrganizationDates.bind(null, i)} 
                          />
                        )}
                      </Col>
                    </Row>
                    <br />
                    <br />
                  </React.Fragment>
                )}
                <Row gutter={12}>
                  <br />
                    <Col span={2}>
                    </Col>
                    <Col span={22}>
                      <Input placeholder='Organization Name' onClick={this.handleOrganization.bind(null, 'name')} />
                      <br />
                      <br />
                      <div className='cover-input'>
                        <RangePicker  />
                        <div className='cover' onClick={this.handleOrganization.bind(null, 'dates')} ></div>
                      </div>
                      <br />
                      <br />
                    </Col>
                </Row>
                <br />
                <h2 className='text-center'>Awards</h2>
                <div className='text-center'>
                  <br />
                  <div className='text-left display-block'>
                    {getFieldDecorator(`schoolAwards`, {
                      rules: [{ required: false, message: 'Please input a valid value.' }],
                      initialValue: this.props.survey.leadership.schoolAwards
                    })(
                      <Checkbox>
                        School Awards?
                      </Checkbox>
                    )}
                    <br />
                    {getFieldDecorator(`localOrganizations`, {
                      rules: [{ required: false, message: 'Please input a valid value.' }],
                      initialValue: this.props.survey.leadership.localOrganizations
                    })(
                      <Checkbox>
                        Local Organizations
                      </Checkbox>
                    )}
                    <br />
                    {getFieldDecorator(`stateRegional`, {
                      rules: [{ required: false, message: 'Please input a valid value.' }],
                      initialValue: this.props.survey.leadership.stateRegional
                    })(
                      <Checkbox>
                        State/Regional
                      </Checkbox>
                    )}
                    <br />
                    {getFieldDecorator(`organizationAwards`, {
                      rules: [{ required: false, message: 'Please input a valid value.' }],
                      initialValue: this.props.survey.leadership.organizationAwards
                    })(
                      <Checkbox>
                        Organizations
                      </Checkbox>
                    )}
                    <br />
                    {getFieldDecorator(`nationalOrganizations`, {
                      rules: [{required: false, message: 'Please input a valid value.' }],
                      initialValue: this.props.survey.leadership.nationalOrganizations
                    })(
                      <Checkbox>
                        National Organizations
                      </Checkbox>
                    )}
                  </div>
                </div>
                <br />
                <br /> 
                <div className='text-center'>
                  <Button className='ant-btn ant-btn-primary' htmlType='submit'>
                    Next
                  </Button>
                </div>
              </Card>  
            </Col>
          </Row>
        
        </Form>
      </MainLayout>
    )
  }
}

const connected = connect((state)=>(
  state
), ()=>({
  addToFavorites: favorites.addToFavorites,
  removeFromFavorites: favorites.removeFromFavorites
}))(Academics)

export default connected
