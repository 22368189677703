import { combineReducers } from 'redux'
import app from './app'
import home from './home'
import login from './login'
import admin from './admin'
import schools from './schools'
import favorites from './favorites'
import schoolsFilter from './schoolsFilter'
import survey from './survey'
import scores from './scores'
import session from './session'
import invitationCode from './invitationCode'

export default combineReducers({
  app,
  home,
  login,
  admin,
  invitationCode,
  schools,
  favorites,
  schoolsFilter,
  survey,
  scores,
  session
})
