const getParams = ()=>{
        const args = window.location.search.slice(1).split("&")
        let params = {}
        for(let arg of args){
            const [key, value] = arg.split("=")
            params[key] = value
        }

        return params
}

export default getParams;