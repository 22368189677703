import React from 'react'
import { Layout } from 'antd'
import DocumentView from '../../components/DocumentView'
import TermsContent from '../../assets/legal/TermsContent'
import AppFooter from '../../components/AppFooter'
import LandingHeader from '../../components/LandingHeader'

class Terms extends React.Component {
  render() {
    return (
      <Layout>
        <LandingHeader />
        <DocumentView content={TermsContent} />
        <AppFooter /> 
      </Layout>
    );
  }
}

export default Terms;