import React, {Component, Fragment} from "react"
import { CaretLeftOutlined } from '@ant-design/icons';
import './index.less'

const StopLight = (props)=>(
    <div className='lights'>
        <div className='light low'></div>
        <div className='light medium-low'></div>
        <div className='light medium'></div>
        <div className='light medium-high'></div>
        <div className='light high'></div>
        <div className='indicator' style={{left: `${props.score}%`}} />
    </div>
)
export default StopLight