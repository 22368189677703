import {Statistic} from 'antd'
import { connect } from 'react-redux'
import {useEffect, useState} from 'react'
import actions from '../../actions'
import {Card} from 'antd'

const Dashboard = (props)=>{
    const [state, setState] = useState({users: []});
    useEffect(()=>{
        actions.users.fetch().then((data)=>{
            setState({
                users: data
            })
        });
    }, [])
    return (
        <div>
            Statistics
            <br />
            <br />
            <Card style={{display: 'inline-block'}}>
                <Statistic title="Active Users" value={state.users.length} />
            </Card>
        </div>
    )
}

export default Dashboard