import * as ActionTypes from '../constants/actionTypes'

export const login = values => (dispatch) => {
  localStorage.setItem('token', values.token)
  dispatch({ type: ActionTypes.APP_LOGIN_SET, token: values.token })
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('token')
  dispatch({ type: ActionTypes.APP_LOGOUT_SET })
}
