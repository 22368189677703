import React from 'react'
import { Card, Layout } from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Form from './Form'
import AppFooter from '../../components/AppFooter'
import { Link } from 'react-router-dom'
import Logo from 'components/Logo'
import './index.less'

class Login extends React.Component {
  render() {
    if (this.props.session.id) {
      return <Redirect to={{ pathname: '/scores' }} />
    }
    return (
      <Layout>
        <div className="hero">
          <Layout.Header className="landing-header">
            <div className="logo">
              <Link to='/'>
                <Logo />
              </Link>
            </div>
          </Layout.Header>
          <div className="main">
            <div className="main-content">
              <Card className='login-form'>
                <div className='header'>
                  <h1>Reset Password To Login To Mitty</h1>
                </div>
                <Form
                  onSubmit={this.props.handleFormOnSubmit}
                  formFieldValues={this.props.formFieldValues}
                  isLoading={this.props.isLoginLoading}
                />
                <br />
                Need an account?
                <br />
                <Link to='/register'>Register</Link>
              </Card>
            </div>
          </div>
          <AppFooter />
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = ()=> ({})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
