import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Button, Alert, Row, Col } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import {sessionActions} from 'actions'
import GoogleLoginButton from './GoogleLoginButton';
const FormItem = Form.Item

class LoginForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.formRef = React.createRef()
  }

  handleOnChange(e) {
    if (this.props.session.loginError) {
      sessionActions.reset();
    }
  }

  handleOnSubmit(values) {
    sessionActions.login(values)
  }
  render() {
    let loginAlert; 
    if (this.props.session.loginError) {
      loginAlert = <Alert type='error' message='Invalid username or password.' />; 
    } 
    return (
      <Form ref={this.formRef} onFinish={this.handleOnSubmit}>
        {loginAlert}
        <br />
        <FormItem
          name='email'
          required={true}
          type='email'
          message='Please input a valid email.'
        >
          <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Email' onChange={this.handleOnChange} />
        </FormItem>
        <FormItem
          name='password'
          required={true}
          message='Please input a valid password.'
        >
          <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Password' type='password' onChange={this.handleOnChange} />
        </FormItem>
        <Link to='/password-reset'>
          Forgot your Password
        </Link>
        <FormItem>
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%' }}
            size='large'
          >
            Login
          </Button>
        </FormItem>
      </Form>
    )
  }
}

const CustomizedForm = LoginForm

export default connect((state) => state)(CustomizedForm);