import React from 'react'
import { Form, Button, Checkbox } from 'antd'
import DocumentView from '../../components/DocumentView'
import PrivacyContent from '../../assets/legal/PrivacyContent'
import EulaContent from '../../assets/legal/EulaContent'
import TermsContent from '../../assets/legal/TermsContent'
import './index.less'
const FormItem = Form.Item


class TermsFieldsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agreed: false
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  
  handleOnChange(e) {
    this.setState({
      agreed: !this.state.agreed
    });
  }

  render() {
    return (
      <Form>
        <div className='terms'>
          <DocumentView content={EulaContent} />
          <DocumentView content={PrivacyContent} />
          <DocumentView content={TermsContent} />
        </div>
        <br />
        <Checkbox checked={this.state.agreed} onChange={this.handleOnChange}>
          I agree
        </Checkbox>
        <br />
        <br />
        <Button
          type='primary'
          size='large'
          disabled={!this.state.agreed}
          onClick={this.props.nextStep}
        >
          Next Step
        </Button>
      </Form>
    )
  }
}

const TermsFields = TermsFieldsForm;

export default TermsFields; 
