import React from 'react'
import { Table, Modal } from 'antd'

class ItemListTable extends React.Component {
  constructor(props, context) {
    super(props, context)
  }


  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      }, {
        title: 'Score',
        dataIndex: 'score',
        key: 'score'
      }, {
        title: 'State',
        dataIndex: 'state',
        key: 'state'
      }
    ]
    return (
      <Table
        rowKey={record => `item-row-${record.id}`}
        columns={columns}
        {...this.props}
      />
    )
  }
}

export default ItemListTable
