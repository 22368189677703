export default `
  <h1 class="c2" id="h.gjdgxs"><span>Terms and Conditions</span></h1>
  <p class="c2"><span class="c0">Last updated: January 01, 2019</span></p>
  <p class="c2"><span class="c0">Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and
      Conditions&quot;) carefully before using the https://mitty.app/ website (the &quot;Service&quot;) operated by
      Admissions Company, LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</span></p>
  <p class="c2"><span class="c0">Your access to and use of the Service is conditioned upon your acceptance of and
      compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the
      Service.</span></p>
  <p class="c2"><span class="c0">By accessing or using the Service you agree to be bound by these Terms. If you
      disagree with any part of the terms then you do not have permission to access the Service.</span></p>
  <h2 class="c3"><span class="c4">Purchases</span></h2>
  <p class="c2"><span class="c0">If you wish to purchase any product or service made available through the Service
      (&quot;Purchase&quot;), you may be asked to supply certain information relevant to your Purchase including,
      without limitation, your credit card number, the expiration date of your credit card, your billing address, and
      your shipping information.</span></p>
  <p class="c2"><span class="c0">You represent and warrant that: (i) you have the legal right to use any credit card(s)
      or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is
      true, correct and complete.</span></p>
  <p class="c2"><span class="c0">The service may employ the use of third party services for the purpose of facilitating
      payment and the completion of Purchases. By submitting your information, you grant us the right to provide the
      information to these third parties subject to our Privacy Policy.</span></p>
  <p class="c2"><span class="c0">We reserve the right to refuse or cancel your order at any time for reasons including
      but not limited to: product or service availability, errors in the description or price of the product or
      service, error in your order or other reasons.</span></p>
  <p class="c2"><span class="c0">We reserve the right to refuse or cancel your order if fraud or an unauthorized or
      illegal transaction is suspected.</span></p>
  <h2 class="c3"><span class="c4">Availability, Errors and Inaccuracies</span></h2>
  <p class="c2"><span class="c0">We are constantly updating product and service offerings on the Service. We may
      experience delays in updating information on the Service and in our advertising on other web sites. The
      information found on the Service may contain errors or inaccuracies and may not be complete or current. Products
      or services may be mispriced, described inaccurately, or unavailable on the Service and we cannot guarantee the
      accuracy or completeness of any information found on the Service.</span></p>
  <p class="c2"><span class="c0">We therefore reserve the right to change or update information and to correct errors,
      inaccuracies, or omissions at any time without prior notice.</span></p>
  <h2 class="c3"><span class="c4">Subscriptions</span></h2>
  <p class="c2"><span class="c0">Some parts of the Service are billed on a subscription basis
      (&quot;Subscription(s)&quot;). You will be billed in advance on a recurring and periodic basis (&quot;Billing
      Cycle&quot;). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription
      plan you select when purchasing a Subscription.</span></p>
  <p class="c2"><span class="c0">At the end of each Billing Cycle, your Subscription will automatically renew under the
      exact same conditions unless you cancel it or Admissions Company, LLC cancels it. You may cancel your
      Subscription renewal either through your online account management page or by contacting Admissions Company, LLC
      customer support team.</span></p>
  <p class="c2"><span class="c0">A valid payment method, including credit card or PayPal, is required to process the
      payment for your Subscription. You shall provide Admissions Company, LLC with accurate and complete billing
      information including full name, address, state, zip code, telephone number, and a valid payment method
      information. By submitting such payment information, you automatically authorize Admissions Company, LLC to
      charge all Subscription fees incurred through your account to any such payment instruments.</span></p>
  <p class="c2"><span class="c0">Should automatic billing fail to occur for any reason, Admissions Company, LLC will
      issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the
      full payment corresponding to the billing period as indicated on the invoice.</span></p>
  <h2 class="c3"><span class="c4">Fee Changes</span></h2>
  <p class="c2"><span class="c0">Admissions Company, LLC, in its sole discretion and at any time, may modify the
      Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the
      then-current Billing Cycle.</span></p>
  <p class="c2"><span class="c0">Admissions Company, LLC will provide you with a reasonable prior notice of any change
      in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes
      effective.</span></p>
  <p class="c2"><span class="c0">Your continued use of the Service after the Subscription fee change comes into effect
      constitutes your agreement to pay the modified Subscription fee amount.</span></p>
  <h2 class="c3"><span class="c4">Refunds</span></h2>
  <p class="c2"><span class="c0">Except when required by law, paid Subscription fees are non-refundable.</span></p>
  <h2 class="c3"><span class="c4">Accounts</span></h2>
  <p class="c2"><span class="c0">When you create an account with us, you guarantee that you are above the age of 18,
      and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete,
      or obsolete information may result in the immediate termination of your account on the Service.</span></p>
  <p class="c2"><span class="c0">You are responsible for maintaining the confidentiality of your account and password,
      including but not limited to the restriction of access to your computer and/or account. You agree to accept
      responsibility for any and all activities or actions that occur under your account and/or password, whether your
      password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any
      breach of security or unauthorized use of your account.</span></p>
  <p class="c2"><span class="c0">You may not use as a username the name of another person or entity or that is not
      lawfully available for use, a name or trademark that is subject to any rights of another person or entity other
      than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or
      obscene.</span></p>
  <p class="c2"><span class="c0">We reserve the right to refuse service, terminate accounts, remove or edit content, or
      cancel orders in our sole discretion.</span></p>
  <h2 class="c3"><span class="c4">Copyright Policy</span></h2>
  <p class="c2"><span class="c0">We respect the intellectual property rights of others. It is our policy to respond to
      any claim that Content posted on the Service infringes on the copyright or other intellectual property rights
      (&quot;Infringement&quot;) of any person or entity.</span></p>
  <p class="c2"><span class="c0">If you are a copyright owner, or authorized on behalf of one, and you believe that the
      copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via
      email to support@mitty.app, with the subject line: &quot;Copyright Infringement&quot; and include in your claim a
      detailed description of the alleged Infringement as detailed below, under &quot;DMCA Notice and Procedure for
      Copyright Infringement Claims&quot;</span></p>
  <p class="c2"><span class="c0">You may be held accountable for damages (including costs and attorneys&#39; fees) for
      misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on
      your copyright.</span></p>
  <h2 class="c3"><span class="c4">DMCA Notice and Procedure for Copyright Infringement Claims</span></h2>
  <p class="c2"><span class="c0">You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA)
      by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further
      detail):</span></p>
  <p class="c5"><span class="c0">an electronic or physical signature of the person authorized to act on behalf of the
      owner of the copyright&#39;s interest;</span></p>
  <p class="c5"><span class="c0">a description of the copyrighted work that you claim has been infringed, including the
      URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;</span></p>
  <p class="c5"><span class="c0">identification of the URL or other specific location on the Service where the material
      that you claim is infringing is located;</span></p>
  <p class="c5"><span class="c0">your address, telephone number, and email address;</span></p>
  <p class="c5"><span class="c0">a statement by you that you have a good faith belief that the disputed use is not
      authorized by the copyright owner, its agent, or the law;</span></p>
  <p class="c2 c6"><span class="c0">a statement by you, made under penalty of perjury, that the above information in
      your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner&#39;s
      behalf.</span></p>
  <p class="c2"><span class="c0">You can contact our Copyright Agent via email at support@mitty.app</span></p>
  <h2 class="c3"><span class="c4">Intellectual Property</span></h2>
  <p class="c2"><span class="c0">The Service and its original content, features and functionality are and will remain
      the exclusive property of Admissions Company, LLC and its licensors. The Service is protected by copyright,
      trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not
      be used in connection with any product or service without the prior written consent of Admissions Company, LLC.</span></p>
  <h2 class="c3"><span class="c4">Links To Other Web Sites</span></h2>
  <p class="c2"><span class="c0">Our Service may contain links to third party web sites or services that are not owned
      or controlled by Admissions Company, LLC</span></p>
  <p class="c2"><span class="c0">Admissions Company, LLC has no control over, and assumes no responsibility for the
      content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings
      of any of these entities/individuals or their websites.</span></p>
  <p class="c2"><span class="c0">You acknowledge and agree that Admissions Company, LLC shall not be responsible or
      liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
      use of or reliance on any such content, goods or services available on or through any such third party web sites
      or services.</span></p>
  <p class="c2"><span class="c0">We strongly advise you to read the terms and conditions and privacy policies of any
      third party web sites or services that you visit.</span></p>
  <h2 class="c3"><span class="c4">Termination</span></h2>
  <p class="c2"><span class="c0">We may terminate or suspend your account and bar access to the Service immediately,
      without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation,
      including but not limited to a breach of the Terms.</span></p>
  <p class="c2"><span class="c0">If you wish to terminate your account, you may simply discontinue using the Service.</span></p>
  <p class="c2"><span class="c0">All provisions of the Terms which by their nature should survive termination shall
      survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
      limitations of liability.</span></p>
  <h2 class="c3"><span class="c4">Indemnification</span></h2>
  <p class="c2"><span class="c0">You agree to defend, indemnify and hold harmless Admissions Company, LLC and its
      licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any
      and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited
      to attorney&#39;s fees), resulting from or arising out of a) your use and access of the Service, by you or any
      person using your account and password, or b) a breach of these Terms.</span></p>
  <h2 class="c3"><span class="c4">Limitation Of Liability</span></h2>
  <p class="c2"><span class="c0">In no event shall Admissions Company, LLC, nor its directors, employees, partners,
      agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive
      damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
      resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or
      content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized
      access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including
      negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage,
      and even if a remedy set forth herein is found to have failed of its essential purpose.</span></p>
  <h2 class="c3"><span class="c4">Disclaimer</span></h2>
  <p class="c2"><span class="c0">Your use of the Service is at your sole risk. The Service is provided on an &quot;AS
      IS&quot; and &quot;AS AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether
      express or implied, including, but not limited to, implied warranties of merchantability, fitness for a
      particular purpose, non-infringement or course of performance.</span></p>
  <p class="c2"><span class="c0">Admissions Company, LLC its subsidiaries, affiliates, and its licensors do not warrant
      that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any
      errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the
      results of using the Service will meet your requirements.</span></p>
  <h2 class="c3"><span class="c4">Exclusions</span></h2>
  <p class="c2"><span class="c0">Some jurisdictions do not allow the exclusion of certain warranties or the exclusion
      or limitation of liability for consequential or incidental damages, so the limitations above may not apply to
      you.</span></p>
  <h2 class="c3"><span class="c4">Governing Law</span></h2>
  <p class="c2"><span class="c0">These Terms shall be governed and construed in accordance with the laws of Maryland,
      United States, without regard to its conflict of law provisions.</span></p>
  <p class="c2"><span class="c0">Our failure to enforce any right or provision of these Terms will not be considered a
      waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the
      remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
      regarding our Service, and supersede and replace any prior agreements we might have had between us regarding the
      Service.</span></p>
  <h2 class="c3"><span class="c4">Changes</span></h2>
  <p class="c2"><span class="c0">We reserve the right, at our sole discretion, to modify or replace these Terms at any
      time. If a revision is material we will provide at least 30 days notice prior to any new terms taking effect.
      What constitutes a material change will be determined at our sole discretion.</span></p>
  <p class="c2"><span class="c0">By continuing to access or use our Service after any revisions become effective, you
      agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use
      the Service.</span></p>
  <h2 class="c3"><span class="c4">Contact Us</span></h2>
  <p class="c2"><span class="c0">If you have any questions about these Terms, please contact us.</span></p>
`