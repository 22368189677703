export default (answers)=>({
    Extroversion: (
        20 + answers[1] 
        - answers[6] 
        + answers[11] 
        - answers[16] 
        + answers[21] 
        - answers[26] 
        + answers[31] 
        - answers[36] 
        + answers[41] 
        - answers[46]
    ),
    Agreeableness: (
        14 - answers[2] 
        + answers[7]
        - answers[12]
        + answers[17]
        - answers[22]
        + answers[27]
        - answers[32]
        + answers[37]
        + answers[42]
        + answers[47]
    ),
    Conscientiousness: (
        14 + answers[3]
        - answers[8]
        + answers[13] 
        - answers[18] 
        + answers[23] 
        - answers[28] 
        + answers[33] 
        - answers[38] 
        + answers[43] 
        + answers[48]
    ),
    Neuroticism: (
        38 - answers[4]
        + answers[9]
        - answers[14] 
        + answers[19] 
        - answers[24] 
        - answers[29] 
        - answers[34] 
        - answers[39] 
        - answers[44] 
        - answers[49]
    ),
    OpennessToExperience: (
        8 + answers[5] 
        - answers[10] 
        + answers[15] 
        - answers[20] 
        + answers[25] 
        - answers[30] 
        + answers[35] 
        + answers[40] 
        + answers[45] 
        + answers[50]
    )
})