import store, {actionTypes} from 'store'
import * as apiClient from '../apiClient'

export const fetch = (data)=>{
    if(store.getState().session.id){
        return apiClient.fetchTheBigFive(data)
    }else{
        return new Promise((resolve, reject)=>{
            reject({error: 'not logged in'})
        })
    }
}

export const save = (data)=>{
    if(store.getState().session.id){
        return apiClient.saveTheBigFive(data)
    }
}
