import {
  SET_SCORE_VALUES
} from '../constants/actionTypes'

export default (state = [], action) => {
  switch (action.type) {
    case SET_SCORE_VALUES:
      return action.payload.reduce((acc, row)=>({
        ...acc,
        [`${row.id}`]: row
      }), {})
    default:
      return state
  }
}
