import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import { Form, Col, Row, Steps, Card, Button, AutoComplete, List } from 'antd'
import MainLayout from 'components/MainLayout'
import {Link} from 'components/elements'
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Chart from './Chart'
import Charts from './Charts/index'
import _ from 'lodash'
import filtering from '../../lib/filtering'
import './index.less'
import store from '../../store'

import {scoreActions, favorites, filterActions, surveyActions} from 'actions'
import Panel from './Panel'
const Step = Steps.Step

class Scores extends React.Component {
  constructor() {
    super()
    this.state = {
      end: 10,
      schoolSearch: '',
      showSearchOptions: false
    }
    this.moreSchools = this.moreSchools.bind(this)
    this.lessSchools = this.lessSchools.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleShowSearchOptionsToggle = this.handleShowSearchOptionsToggle.bind(this)
  } 
  moreSchools(e) {
    e.preventDefault()
    this.setState({
      end: this.state.end + 10
    })
  }
  lessSchools(e) {
    e.preventDefault()
    this.setState({
      end: this.state.end - 10 
    })
  }
  componentDidMount(){
    favorites.getFavorites()
    surveyActions.getSurvey()
  }
  get hasScores(){
    const academics = _.get(store.getState(), 'session.answers.data.survey', {}).academics
    const personalInformation = _.get(store.getState(), 'session.answers.data.survey', {}).personalInformation
    
    if(academics && personalInformation){
      return true
    }
    return false
  }

  fetchScores(){
    if(this.hasScores && !this.scores){
      
      scoreActions.getScoreValues().then(()=>{
        
      }).catch(()=>{
        this.scores = false
        this.waitFetchScores()
      })
      this.scores = true
    }else{
      this.waitFetchScores()
    }
  }
  waitFetchScores(){
    return setTimeout(this.fetchScores.bind(this), 1000);
  }
  componentDidUpdate(){
    if(!this.timingout){
      this.timingout = this.waitFetchScores()
    }
  }
  addToFavorites(school, e){
    e.preventDefault()
    
    favorites.addToFavorites(school)
  }
  removeFromFavorites(school){
    favorites.removeFromFavorites(school)
  }
  school(id){
    return this.props.schools.find((school)=>(
      school.id == id
    ))
  }
  get rawSchools(){
    return this.props.schools || []
  }
  get filteredSchools(){
    const schoolIDs = this.props.favorites.map((_)=> _.id)
    return this.rawSchools.filter((school)=>(
      filtering(this.props, school, {filters: true})
    )).filter((school)=>
      !schoolIDs.includes(school.id)
    ).slice(0, 14).map((school)=>({
      key: school.id,
      value: school.name
    })).sort((a, b)=>
      a.value < b.value ? -1 : 1
    )
  }
  get searchedSchools(){
    const schoolIDs = this.props.favorites.map((_)=> _.id)
    return this.rawSchools.filter((school)=>(
      filtering(this.props, school, {search: true})
    )).filter((school)=>
      !schoolIDs.includes(school.id)
    ).slice(0, this.state.end).map((school)=>({
      key: school.id,
      value: school.name
    })).sort((a, b)=>
      a.value < b.value ? -1 : 1
    )
  }

  handleSelect(undefined, {key: id}){
    if(id){
      const school = this.school(id)
      favorites.addToFavorites(school)
      this.setState({
        schoolSearch: ''
      })
    }
  }
  handleSearch(value){
    this.setState({
      schoolSearch: value
    })
    if(this.filter){
      clearTimeout(this.filter)
    }
    this.filter = setTimeout(()=>{
      filterActions.setFilter('name', value);
    }, 300)
  }
  handleShowSearchOptionsToggle(){
    this.setState({
      showSearchOptions: !this.state.showSearchOptions
    })
  }
  render() {
    let scores;
    // = (this.props.scores || 
    scores = _.get(this, 'props.favorites', []);
    scores = (scores || []).map((row, i)=>{
      return ({
        id: row.id,
        name: _.get(this, `props.scores[${row.id}].name`, row.name),
        value: _.get(this, `props.scores[${row.id}].score`),
        // difficulty: _.shuffle(['Very', 'Moderate', 'Somewhat'])[0],
        state: _.get(this, `props.scores[${row.id}].state`, row.state)
      })
    }).sort((a, b)=> a.value < b.value ? 1 : -1 )
    
    return <MainLayout>
        <Card>
          <Steps  size="small" current={0}>
            <Step title='Select Schools' />
            <Step title='Academics' />
            <Step title='Personal Information' />
            <Step title='Results' />
          </Steps>
          <br />
          <br />
          {this.props.favorites.length != 0 ? (
            <Link to='/profile' className='ant-btn ant-btn-primary'>
              Next
            </Link>
          ):(
            <Fragment>
              <Button disabled={true}>
                Next
              </Button> select schools to advance.
            </Fragment>
          )}
          <br />
          <br />
        </Card>
        <br />
        {this.state.showSearchOptions && (
          <Row class='advanced-panel'>
            <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 1}}>
              <Panel direction={'horizontal'}/>
              <div className='filtered-results'>
                <Card bodyStyle={{padding: '0px'}}>
                  <List
                    bordered
                    dataSource={this.filteredSchools}
                    renderItem={item => (
                      <List.Item onClick={this.handleSelect.bind(null, null,  item)}>
                        <PlusOutlined /> {item.value}
                      </List.Item>
                    )}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        )}
        <Row type='flex'>
          <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 0}}>
            <div style={{padding: 10, width: '100%', position: 'relative'}}>
              <div style={{position: 'relative', display: 'inline-block', width: 'calc(100%)'}}>
                <AutoComplete
                  style={{width: '100%'}}
                  options={this.searchedSchools}
                  onSelect={this.handleSelect}
                  onSearch={this.handleSearch}
                  placeholder="Search for a School"
                  value={this.state.schoolSearch}
                />
              </div>
              {/* <div className={'more-options'} onClick={this.handleShowSearchOptionsToggle}>
                Advanced Search
              </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <div>
          <Row type='flex'>
            <h2 className='selected-schools'>
              Selected Schools
            </h2>
            {this.props.favorites.length !==0?(
              this.props.favorites.map((university)=>(
                <div className='schools'>
                  <div className='remove-school' onClick={this.removeFromFavorites.bind(null, university)}>
                    <CloseCircleOutlined />
                  </div>
                  {university.name}
                </div>
              ))
            ):(
              <div style={{position: 'relative', width: '100%'}} className='text-center'>
                <h2 style={{color: '#777'}}>No schools selected</h2>
              </div>
            )}
          </Row>
          <div className='center-text'>
            {this.props.favorites.length != 0 ? (
              <Link to='/profile' className='ant-btn ant-btn-primary center-block'>
                  Next
              </Link>
            ):(
              <Button disabled={true}>
                Next
              </Button>
            )}
          </div>
        </div>
      </Row>
    </MainLayout>
  }
}

const connected = connect((state)=>(
  state
))(Scores)
export default connected
