export default `
  <h1 class="c5" id="h.gjdgxs"><span>Privacy Policy</span></h1>
  <p class="c5"><span class="c2">Effective date: January 01, 2019</span></p>
  <p class="c5"><span class="c2">Admissions Company, LLC (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates
      the https://mitty.app/ website and the Mitty mobile application (hereinafter referred to as the
      &quot;Service&quot;).</span></p>
  <p class="c5"><span class="c2">This page informs you of our policies regarding the collection, use, and disclosure of
      personal data when you use our Service and the choices you have associated with that data.</span></p>
  <p class="c5"><span class="c2">We use your data to provide and improve the Service. By using the Service, you agree
      to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy
      Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</span></p>
  <h2 class="c3"><span class="c0">Definitions</span></h2>
  <p class="c1"><span class="c11">Service</span></p>
  <p class="c1"><span class="c2">Service means the https://mitty.app/ website and the Mitty mobile application operated
      by Admissions Company, LLC</span></p>
  <p class="c1"><span class="c11">Personal Data</span></p>
  <p class="c1"><span class="c2">Personal Data means data about a living individual who can be identified from those
      data (or from those and other information either in our possession or likely to come into our possession).</span></p>
  <p class="c1"><span class="c11">Usage Data</span></p>
  <p class="c1"><span class="c2">Usage Data is data collected automatically either generated by the use of the Service
      or from the Service infrastructure itself (for example, the duration of a page visit).</span></p>
  <p class="c1"><span class="c11">Cookies</span></p>
  <p class="c1"><span class="c2">Cookies are small files stored on your device (computer or mobile device).</span></p>
  <p class="c1"><span class="c11">Data Controller</span></p>
  <p class="c1"><span class="c2">Data Controller means the natural or legal person who (either alone or jointly or in
      common with other persons) determines the purposes for which and the manner in which any personal information
      are, or are to be, processed.</span></p>
  <p class="c1"><span class="c2">For the purpose of this Privacy Policy, we are a Data Controller of your Personal
      Data.</span></p>
  <p class="c1"><span class="c11">Data Processors (or Service Providers)</span></p>
  <p class="c1"><span class="c2">Data Processor (or Service Provider) means any natural or legal person who processes
      the data on behalf of the Data Controller.</span></p>
  <p class="c1"><span class="c2">We may use the services of various Service Providers in order to process your data
      more effectively.</span></p>
  <p class="c1"><span class="c11">Data Subject (or User)</span></p>
  <p class="c5 c10"><span class="c2">Data Subject is any living individual who is using our Service and is the subject
      of Personal Data.</span></p>
  <h2 class="c3"><span class="c0">Information Collection and Use</span></h2>
  <p class="c5"><span class="c2">We collect several different types of information for various purposes to provide and
      improve our Service to you.</span></p>
  <h3 class="c3"><span class="c4">Types of Data Collected</span></h3>
  <h4 class="c3"><span class="c12">Personal Data</span></h4>
  <p class="c5"><span class="c2">While using our Service, we may ask you to provide us with certain personally
      identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally
      identifiable information may include, but is not limited to:</span></p>
  <ul class="c6 lst-kix_list_1-0 start">
    <li class="c7"><span class="c2">Email address</span></li>
    <li class="c7"><span class="c2">First name and last name</span></li>
    <li class="c7"><span class="c2">Address, State, Province, ZIP/Postal code, City</span></li>
    <li class="c5 c9"><span class="c2">Cookies and Usage Data</span></li>
  </ul>
  <p class="c5"><span class="c2">We may use your Personal Data to contact you with newsletters, marketing or
      promotional materials and other information that may be of interest to you. You may opt out of receiving any, or
      all, of these communications from us by following the unsubscribe link or the instructions provided in any email
      we send.</span></p>
  <h4 class="c3"><span class="c12">Usage Data</span></h4>
  <p class="c5"><span class="c2">We may also collect information that your browser sends whenever you visit our Service
      or when you access the Service by or through a mobile device (&quot;Usage Data&quot;).</span></p>
  <p class="c5"><span class="c2">This Usage Data may include information such as your computer&#39;s Internet Protocol
      address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and
      date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</span></p>
  <p class="c5"><span class="c2">When you access the Service with a mobile device, this Usage Data may include
      information such as the type of mobile device you use, your mobile device unique ID, the IP address of your
      mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device
      identifiers and other diagnostic data.</span></p>
  <h4 class="c3"><span class="c12">Location Data</span></h4>
  <p class="c5"><span class="c2">We may use and store information about your location if you give us permission to do
      so (&quot;Location Data&quot;). We use this data to provide features of our Service, to improve and customize our
      Service.</span></p>
  <p class="c5"><span class="c2">You can enable or disable location services when you use our Service at any time by
      way of your device settings.</span></p>
  <h4 class="c3"><span class="c12">Tracking Cookies Data</span></h4>
  <p class="c5"><span class="c2">We use cookies and similar tracking technologies to track the activity on our Service
      and we hold certain information.</span></p>
  <p class="c5"><span class="c2">Cookies are files with a small amount of data which may include an anonymous unique
      identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking
      technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and
      analyze our Service.</span></p>
  <p class="c5"><span class="c2">You can instruct your browser to refuse all cookies or to indicate when a cookie is
      being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</span></p>
  <p class="c5"><span class="c2">Examples of Cookies we use:</span></p>
  <ul class="c6 lst-kix_list_1-0">
    <li class="c7"><span class="c11">Session Cookies.</span><span class="c2">&nbsp;We use Session Cookies to operate
        our Service.</span></li>
    <li class="c7"><span class="c11">Preference Cookies.</span><span class="c2">&nbsp;We use Preference Cookies to
        remember your preferences and various settings.</span></li>
    <li class="c5 c9"><span class="c11">Security Cookies.</span><span class="c2">&nbsp;We use Security Cookies for
        security purposes.</span></li>
  </ul>
  <h2 class="c3"><span class="c0">Use of Data</span></h2>
  <p class="c5"><span class="c2">Admissions Company, LLC uses the collected data for various purposes:</span></p>
  <ul class="c6 lst-kix_list_1-0">
    <li class="c7"><span class="c2">To provide and maintain our Service</span></li>
    <li class="c7"><span class="c2">To notify you about changes to our Service</span></li>
    <li class="c7"><span class="c2">To allow you to participate in interactive features of our Service when you choose
        to do so</span></li>
    <li class="c7"><span class="c2">To provide customer support</span></li>
    <li class="c7"><span class="c2">To gather analysis or valuable information so that we can improve our Service</span></li>
    <li class="c7"><span class="c2">To monitor the usage of our Service</span></li>
    <li class="c7"><span class="c2">To detect, prevent and address technical issues</span></li>
    <li class="c5 c9"><span class="c2">To provide you with news, special offers and general information about other
        goods, services and events which we offer that are similar to those that you have already purchased or enquired
        about unless you have opted not to receive such information</span></li>
  </ul>
  <h2 class="c3"><span class="c0">Legal Basis for Processing Personal Data under the General Data Protection Regulation
      (GDPR)</span></h2>
  <p class="c5"><span class="c2">If you are from the European Economic Area (EEA), Admissions Company, LLC legal basis
      for collecting and using the personal information described in this Privacy Policy depends on the Personal Data
      we collect and the specific context in which we collect it.</span></p>
  <p class="c5"><span class="c2">Admissions Company, LLC may process your Personal Data because:</span></p>
  <ul class="c6 lst-kix_list_1-0">
    <li class="c7"><span class="c2">We need to perform a contract with you</span></li>
    <li class="c7"><span class="c2">You have given us permission to do so</span></li>
    <li class="c7"><span class="c2">The processing is in our legitimate interests and it is not overridden by your
        rights</span></li>
    <li class="c7"><span class="c2">For payment processing purposes</span></li>
    <li class="c5 c9"><span class="c2">To comply with the law</span></li>
  </ul>
  <h2 class="c3"><span class="c0">Retention of Data</span></h2>
  <p class="c5"><span class="c2">Admissions Company, LLC will retain your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the
      extent necessary to comply with our legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</span></p>
  <p class="c5"><span class="c2">Admissions Company, LLC will also retain Usage Data for internal analysis purposes.
      Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the
      security or to improve the functionality of our Service, or we are legally obligated to retain this data for
      longer periods.</span></p>
  <h2 class="c3"><span class="c0">Transfer of Data</span></h2>
  <p class="c5"><span class="c2">Your information, including Personal Data, may be transferred to - and maintained on -
      computers located outside of your state, province, country or other governmental jurisdiction where the data
      protection laws may differ from those of your jurisdiction.</span></p>
  <p class="c5"><span class="c2">If you are located outside the United States and choose to provide information to us,
      please note that we transfer the data, including Personal Data, to the United States and process it there.</span></p>
  <p class="c5"><span class="c2">Your consent to this Privacy Policy followed by your submission of such information
      represents your agreement to that transfer.</span></p>
  <p class="c5"><span class="c2">Admissions Company, LLC will take all the steps reasonably necessary to ensure that
      your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data
      will take place to an organization or a country unless there are adequate controls in place including the
      security of your data and other personal information.</span></p>
  <h2 class="c3"><span class="c0">Disclosure of Data</span></h2>
  <h3 class="c3"><span class="c4">Disclosure for Law Enforcement</span></h3>
  <p class="c5"><span class="c2">Under certain circumstances, Admissions Company, LLC may be required to disclose your
      Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or
      a government agency).</span></p>
  <h3 class="c3"><span class="c4">Legal Requirements</span></h3>
  <p class="c5"><span class="c2">Admissions Company, LLC may disclose your Personal Data in the good faith belief that
      such action is necessary to:</span></p>
  <ul class="c6 lst-kix_list_1-0">
    <li class="c7"><span class="c2">To comply with a legal obligation</span></li>
    <li class="c7"><span class="c2">To protect and defend the rights or property of Admissions Company, LLC</span></li>
    <li class="c7"><span class="c2">To prevent or investigate possible wrongdoing in connection with the Service</span></li>
    <li class="c7"><span class="c2">To protect the personal safety of users of the Service or the public</span></li>
    <li class="c5 c9"><span class="c2">To protect against legal liability</span></li>
  </ul>
  <h2 class="c3"><span class="c0">Security of Data</span></h2>
  <p class="c5"><span class="c2">The security of your data is important to us but remember that no method of
      transmission over the Internet or method of electronic storage is 100% secure. While we strive to use
      commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</span></p>
  <h2 class="c3"><span class="c0">Our Policy on &quot;Do Not Track&quot; Signals under the California Online Protection
      Act (CalOPPA)</span></h2>
  <p class="c5"><span class="c2">We do not support Do Not Track (&quot;DNT&quot;). Do Not Track is a preference you can
      set in your web browser to inform websites that you do not want to be tracked.</span></p>
  <p class="c5"><span class="c2">You can enable or disable Do Not Track by visiting the Preferences or Settings page of
      your web browser.</span></p>
  <h2 class="c3"><span class="c0">Your Data Protection Rights under the General Data Protection Regulation (GDPR)</span></h2>
  <p class="c5"><span class="c2">If you are a resident of the European Economic Area (EEA), you have certain data
      protection rights. Admissions Company, LLC aims to take reasonable steps to allow you to correct, amend, delete
      or limit the use of your Personal Data.</span></p>
  <p class="c5"><span class="c2">If you wish to be informed about what Personal Data we hold about you and if you want
      it to be removed from our systems, please contact us.</span></p>
  <p class="c5"><span class="c2">In certain circumstances, you have the following data protection rights:</span></p>
  <p class="c1"><span class="c11">The right to access, update or delete the information we have on you.</span><span
      class="c2">&nbsp;Whenever made possible, you can access, update or request deletion of your Personal Data
      directly within your account settings section. If you are unable to perform these actions yourself, please
      contact us to assist you.</span></p>
  <p class="c1"><span class="c11">The right of rectification.</span><span class="c2">&nbsp;You have the right to have
      your information rectified if that information is inaccurate or incomplete.</span></p>
  <p class="c1"><span class="c11">The right to object.</span><span class="c2">&nbsp;You have the right to object to our
      processing of your Personal Data.</span></p>
  <p class="c1"><span class="c11">The right of restriction.</span><span class="c2">&nbsp;You have the right to request
      that we restrict the processing of your personal information.</span></p>
  <p class="c1"><span class="c11">The right to data portability.</span><span class="c2">&nbsp;You have the right to be
      provided with a copy of the information we have on you in a structured, machine-readable and commonly used
      format.</span></p>
  <p class="c5 c10"><span class="c11">The right to withdraw consent.</span><span class="c2">&nbsp;You also have the
      right to withdraw your consent at any time where Admissions Company, LLC relied on your consent to process your
      personal information.</span></p>
  <p class="c5"><span class="c2">Please note that we may ask you to verify your identity before responding to such
      requests.</span></p>
  <p class="c5"><span class="c2">You have the right to complain to a Data Protection Authority about our collection and
      use of your Personal Data. For more information, please contact your local data protection authority in the
      European Economic Area (EEA).</span></p>
  <h2 class="c3"><span class="c0">Service Providers</span></h2>
  <p class="c5"><span class="c2">We may employ third party companies and individuals to facilitate our Service
      (&quot;Service Providers&quot;), provide the Service on our behalf, perform Service-related services or assist us
      in analyzing how our Service is used.</span></p>
  <p class="c5"><span class="c2">These third parties have access to your Personal Data only to perform these tasks on
      our behalf and are obligated not to disclose or use it for any other purpose.</span></p>
  <h3 class="c3"><span class="c4">Analytics</span></h3>
  <p class="c5"><span class="c2">We may use third-party Service Providers to monitor and analyze the use of our
      Service.</span></p>
  <p class="c1"><span class="c11">Google Analytics</span></p>
  <p class="c1"><span class="c2">Google Analytics is a web analytics service offered by Google that tracks and reports
      website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared
      with other Google services. Google may use the collected data to </span><span>contextualize</span><span class="c2">&nbsp;and
    </span><span>personalize</span><span class="c2">&nbsp;the ads of its own advertising network.</span></p>
  <p class="c5 c10"><span class="c2">For more information on the privacy practices of Google, please visit the Google
      Privacy Terms web page: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;ust=1547281367950000">https://policies.google.com/privacy?hl=en</a></span></p>
  <h3 class="c3"><span class="c4">Behavioral Remarketing</span></h3>
  <p class="c5"><span class="c2">Admissions Company, LLC uses remarketing services to advertise on third party websites
      to you after you visited our Service. We and our third-party vendors use cookies to inform, optimize and serve
      ads based on your past visits to our Service.</span></p>
  <p class="c1"><span class="c11">Google Ads (AdWords)</span></p>
  <p class="c1"><span class="c2">Google Ads (AdWords) remarketing service is provided by Google Inc.</span></p>
  <p class="c1"><span class="c2">You can opt-out of Google Analytics for Display Advertising and </span><span>customize</span><span
      class="c2">&nbsp;the Google Display Network ads by visiting the Google Ads Settings page: </span><span class="c2"><a
        class="c8" href="https://www.google.com/url?q=http://www.google.com/settings/ads&amp;sa=D&amp;ust=1547281367951000">http://www.google.com/settings/ads</a></span></p>
  <p class="c1"><span class="c2">Google also recommends installing the Google Analytics Opt-out Browser Add-on - </span><span
      class="c2"><a class="c8" href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout&amp;sa=D&amp;ust=1547281367951000">https://tools.google.com/dlpage/gaoptout</a></span><span
      class="c2">&nbsp;- for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the
      ability to prevent their data from being collected and used by Google Analytics.</span></p>
  <p class="c1"><span class="c2">For more information on the privacy practices of Google, please visit the Google
      Privacy Terms web page: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Den&amp;sa=D&amp;ust=1547281367951000">https://policies.google.com/privacy?hl=en</a></span></p>
  <p class="c1"><span class="c11">Twitter</span></p>
  <p class="c1"><span class="c2">Twitter remarketing service is provided by Twitter Inc.</span></p>
  <p class="c1"><span class="c2">You can opt-out from Twitter&#39;s interest-based ads by following their instructions:
    </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://support.twitter.com/articles/20170405&amp;sa=D&amp;ust=1547281367952000">https://support.twitter.com/articles/20170405</a></span></p>
  <p class="c1"><span class="c2">You can learn more about the privacy practices and policies of Twitter by visiting
      their Privacy Policy page: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://twitter.com/privacy&amp;sa=D&amp;ust=1547281367952000">https://twitter.com/privacy</a></span></p>
  <p class="c1"><span class="c11">Facebook</span></p>
  <p class="c1"><span class="c2">Facebook remarketing service is provided by Facebook Inc.</span></p>
  <p class="c1"><span class="c2">You can learn more about interest-based advertising from Facebook by visiting this
      page: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://www.facebook.com/help/164968693837950&amp;sa=D&amp;ust=1547281367953000">https://www.facebook.com/help/164968693837950</a></span></p>
  <p class="c1"><span class="c2">To opt-out from Facebook&#39;s interest-based ads, follow these instructions from
      Facebook: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://www.facebook.com/help/568137493302217&amp;sa=D&amp;ust=1547281367953000">https://www.facebook.com/help/568137493302217</a></span></p>
  <p class="c1"><span class="c2">Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising
      established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating
      companies through the Digital Advertising Alliance in the USA </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;ust=1547281367954000">http://www.aboutads.info/choices/</a></span><span
      class="c2">, the Digital Advertising Alliance of Canada in Canada </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=http://youradchoices.ca/&amp;sa=D&amp;ust=1547281367954000">http://youradchoices.ca/</a></span><span
      class="c2">&nbsp;or the European Interactive Digital Advertising Alliance in Europe </span><span class="c2"><a
        class="c8" href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&amp;sa=D&amp;ust=1547281367955000">http://www.youronlinechoices.eu/</a></span><span
      class="c2">, or opt-out using your mobile device settings.</span></p>
  <p class="c5 c10"><span class="c2">For more information on the privacy practices of Facebook, please visit
      Facebook&#39;s Data Policy: </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://www.facebook.com/privacy/explanation&amp;sa=D&amp;ust=1547281367955000">https://www.facebook.com/privacy/explanation</a></span></p>
  <h3 class="c3"><span class="c4">Payments</span></h3>
  <p class="c5"><span class="c2">We may provide paid products and/or services within the Service. In that case, we use
      third-party services for payment processing (e.g. payment processors).</span></p>
  <p class="c5"><span class="c2">We will not store or collect your payment card details. That information is provided
      directly to our third-party payment processors whose use of your personal information is governed by their
      Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security
      Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
      PCI-DSS requirements help ensure the secure handling of payment information.</span></p>
  <p class="c5"><span class="c2">The payment processors we work with are:</span></p>
  <p class="c1"><span class="c11">Stripe</span></p>
  <p class="c1"><span class="c2">Their Privacy Policy can be viewed at </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://stripe.com/us/privacy&amp;sa=D&amp;ust=1547281367956000">https://stripe.com/us/privacy</a></span></p>
  <p class="c1"><span class="c11">Authorize.net</span></p>
  <p class="c1"><span class="c2">Their Privacy Policy can be viewed at </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://www.authorize.net/company/privacy/&amp;sa=D&amp;ust=1547281367957000">https://www.authorize.net/company/privacy/</a></span></p>
  <p class="c1"><span class="c11">Square</span></p>
  <p class="c5 c10"><span class="c2">Their Privacy Policy can be viewed at </span><span class="c2"><a class="c8" href="https://www.google.com/url?q=https://squareup.com/legal/privacy-no-account&amp;sa=D&amp;ust=1547281367957000">https://squareup.com/legal/privacy-no-account</a></span></p>
  <h2 class="c3"><span class="c0">Links to Other Sites</span></h2>
  <p class="c5"><span class="c2">Our Service may contain links to other sites that are not operated by us. If you click
      a third party link, you will be directed to that third party&#39;s site. We strongly advise you to review the
      Privacy Policy of every site you visit.</span></p>
  <p class="c5"><span class="c2">We have no control over and assume no responsibility for the content, privacy policies
      or practices of any third party sites or services.</span></p>
  <h2 class="c3"><span class="c0">Children&#39;s Privacy</span></h2>
  <p class="c5"><span class="c2">Our Service does not address anyone under the age of 18 (&quot;Children&quot;).</span></p>
  <p class="c5"><span class="c2">We do not knowingly collect personally identifiable information from anyone under the
      age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data,
      please contact us. If we become aware that we have collected Personal Data from children without verification of
      parental consent, we take steps to remove that information from our servers.</span></p>
  <h2 class="c3"><span class="c0">Changes to This Privacy Policy</span></h2>
  <p class="c5"><span class="c2">We may update our Privacy Policy from time to time. We will notify you of any changes
      by posting the new Privacy Policy on this page.</span></p>
  <p class="c5"><span class="c2">We will let you know via email and/or a prominent notice on our Service, prior to the
      change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.</span></p>
  <p class="c5"><span class="c2">You are advised to review this Privacy Policy periodically for any changes. Changes to
      this Privacy Policy are effective when they are posted on this page.</span></p>
  <h2 class="c3"><span class="c0">Contact Us</span></h2>
  <p class="c5"><span class="c2">If you have any questions about this Privacy Policy, please contact us:</span></p>
  <ul class="c6 lst-kix_list_1-0">
    <li class="c7"><span class="c2">By email: support@mitty.app</span></li>
    <li class="c5 c9"><span class="c2">By visiting this page on our website: https://mitty.app/support</span></li>
  </ul>
`