import {
  SET_SCHOOLS,
  ADD_SCHOOLS
} from '../constants/actionTypes'

export default (state = [], action) => {
  switch (action.type) {
    case SET_SCHOOLS:
      return action.payload
    case ADD_SCHOOLS:
      return [...state, action.payload]
    default:
      return state
  }
}
