import React, { Component } from 'react';

import {Link} from 'react-router-dom'

export default (props)=>{
    const onClick = (...args)=>{
        if(props.href){
            [...args].reverse()[0].preventDefault();
        }

        return props.onClick(...args);
    }

    const _props = {...props}
    if(props.onClick) _props.onClick = onClick

    return (
        <Link to={props.to || '#'} {..._props}>
            {props.children}
        </Link>
    )
}