import {Component, Fragment} from 'react'
import actions from "../../../actions";
import { Form, Col, Row, Steps, Card, Button, List, Input, Avatar, Breadcrumb } from 'antd'
import { connect } from 'react-redux';
import _ from 'lodash'
import { withRouter } from 'react-router';
import { UserOutlined } from '@ant-design/icons';

class MainComponent extends Component{
    constructor(props){
        super(props)
        this.state = {}
        this.handleFinish = this.handleFinish.bind(this)
    // this.handleSelectUser = this.handleSelectUser.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
    }
    async handleFinish(values){
        const data = await actions.users.fetch(values)    
        
        this.setState({
            invitationCode: data.invitationCode
        })
    }
    
    componentDidMount(){
        if(!actions.admins.isValidAdmin()){
            this.props.history.push('/')
        }
        actions.users.fetch().then((data)=>{
            this.setState({
                users: data
            })
        })
    }
    // handleSelectUser(user, e){
    //     e.preventDefault()
    //     this.props.history.push(`/admin/users/search/${user.id}`)
    // }
    get users(){
        return (this.state.users || []).filter((user)=>{
            if(this.state.search){
                const criteria = `${user.firsName} ${user.lastName} ${user.email}`.toLowerCase()
                if(criteria.includes(this.state.search.toLowerCase())){
                    return true
                }
                return false
            }else{
                return true;
            }
        })
    }
    handleSearch(e){
        e.preventDefault()
        this.setState({
            search: e.target.value
        })
    }
    render(){
        return (
            <Row>
                <Col xs={{span: 24, order: 1 }} lg={{span: 24, order: 0}}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Users</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card>
                        <div>
                            Search Criteria
                            <Form.Item
                                name='search'
                                placeholder='Search'
                                onChange={this.handleSearch}
                            >
                                <Input  />
                            </Form.Item>
                        </div>
                        <List
                            itemLayout="horizontal"
                            dataSource={_.get(this, 'users', [])}
                            renderItem={(user)=> (
                                <List.Item
                                    actions={[
                                        // <a key="list-loadmore-edit" href={`/admin/edit/${user.id}`}>edit</a>, 
                                        // <a key="list-loadmore-more" href={`/admin/users/${user.id}/edit`}>edit</a>,
                                        <a key="list-loadmore-more" href={`/admin/users/${user.id}/authentications`}>authentications</a>,
                                        <a key="list-loadmore-more" href={`/admin/users/${user.id}/details`}>view</a>
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={<UserOutlined />}
                                        title={
                                            // <a href='#' onClick={this.handleSelectUser.bind(null, user)}>
                                                (`${user.firstName} ${user.lastName}`)
                                            // </a>
                                        }
                                        description={user.email}
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }
}
export default withRouter(connect((state)=>(state))(MainComponent))