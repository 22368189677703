import apiClient from '../apiClient'
import store, {actionTypes} from 'store'

export const fetch = async (params)=>{
    const results = await apiClient.users.fetch(params)

    return results.data
}

export const lock = async (options)=>{
    const results = await apiClient.users.update({...options, isLocked: true})

    return results.data
}

export const unlock = async (options)=>{
    const results = await apiClient.users.update({...options, isLocked: false})

    return results.data
}