import React from 'react'
import { connect } from 'react-redux'
import { Form, Radio, Divider, Col, Row, Steps, Card, Input, Tabs, Checkbox, DatePicker, Select, Switch } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons';
import { Dropdown, MetroArea, States, SAT, ACT, GPA, Selectivity, Upload, Button } from 'components/elements'
import {Link} from 'components/elements'
import moment from 'moment'
import * as utils from 'utils'
import {favorites} from 'actions'
import _ from 'lodash'
const TabPane = Tabs.TabPane
const Step = Steps.Step

const {RangePicker} = DatePicker;
const RadioGroup = Radio.Group;

const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;


class PersonalInformation extends React.Component {
  constructor(props){
    super(props)
    let languageID = 0
    
    const languages = Object.keys(props.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('language')){
        let id = a.replace('language-', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(languages.length != 0){
      languageID = _.sortBy(languages, 'id').reverse()[0].id
    }


    let organizationCount = 0;
    const organizations = Object.keys(props.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('organizationName')){
        let id = a.replace('organizationName', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(organizations.length != 0){
      organizationCount = _.sortBy(organizations, 'id').reverse()[0].id
    }

    this.state = {
      end: 10,
      SATTests: [],
      APExams: [],
      SATCount: 0,
      APCount: 0,
      value: 1,
      languages,
      languageID,
      organizations,
      organizationCount,
      firstPersonToCollege: _.get(props, 'survey.personalInformation.firstPersonToCollege'),
      mother: this.mother,
      father: this.father
    }


    this.handleLeadership = this.handleLeadership.bind(this)
    this.addLanguage = this.addLanguage.bind(this)
    this.removeFromLanguage = this.removeFromLanguage.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFirstPersonToCollege = this.handleFirstPersonToCollege.bind(this)
    this.handleOrganization = this.handleOrganization.bind(this)
    this.handleOpenCloseOrganizationDates = this.handleOpenCloseOrganizationDates.bind(this)
    this.removeOrganization = this.removeOrganization.bind(this)
    this.legacyMother = this.legacyMother.bind(this)
    this.legacyFather = this.legacyFather.bind(this)
  }
  
  get formRef(){
    return this.props.formRef
  }

  handleSubmit(values){
    this.props.handleSubmit(values)
  }
  moreSchools(e){
    e.preventDefault()

    this.setState({
      end: this.state.end + 10 
    })
  }
  addToFavorites(school){
    this.props.addToFavorites(school)
  }
  removeFromFavorites(school){
    this.props.removeFromFavorites(school)
  }
  calculateOdds(){
    this.props.history.push('/academics')
  }
  addToSATTests(type){
    const SATCount = this.state.SATCount + 1
    this.setState({
      SATTests: [...this.state.SATTests, {id: SATCount}],
      SATCount
    }, ()=>{
      this[`SAT-${type}-${this.state.SATTests.length - 1}`].input.focus()
    })
  }
  addToAPExams(type){
    const APCount = this.state.APCount + 1
    this.setState({
      APExams: [...this.state.APExams, {id: APCount}],
      APCount
    }, ()=>{
      this[`AP-${type}-${this.state.APExams.length - 1}`].input.focus()
    })
  }
  removeSATTest(i){
    const SATTests = [...this.state.SATTests]
    SATTests.splice(i , 1);

    this.setState({
      SATTests
    })
  }
  removeAPExam(i){
    const APExams = [...this.state.APExams]
    APExams.splice(i, 1);
    
    this.setState({
      APExams
    })
  }
  handleLeadership(values){
    this.setState({
      value: values.reduce((a, _, i)=> a + 1, 1)
    })
  }

  get mother(){
    return _.get(this, 'props.survey.personalInformation.mother', [])
  }
  
  get father(){
    return _.get(this, 'props.survey.personalInformation.father', [])
  }
  
  componentWillReceiveProps(nextProps){
    let languageID = this.state.languageID
    let organizationCount = this.state.organizationCount
    
    const _languages = Object.keys(this.props.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('language')){
        let id = a.replace('language-', '')
        id = _.invoke(id, 'parseInt', languageID + 1)
        acc.push({id})
      }
      return acc
    }, [])

    const nextLanguages = Object.keys(nextProps.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('language')){
        let id = a.replace('language-', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    let languages = this.state.languages


    let organizations = this.state.organizations
    const _organizations= Object.keys(this.props.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('organizationName')){
        let id = a.replace('organizationName', '')
        id = _.invoke(id, 'parseInt', languageID + 1)
        acc.push({id})
      }
      return acc
    }, [])

    const nextOrganizations = Object.keys(nextProps.survey.personalInformation || {}).reduce((acc, a)=>{
      if(a.includes('organizationName')){
        let id = a.replace('organizationName', '')
        id = parseInt(id)
        acc.push({id})
      }
      return acc
    }, [])

    if(_organizations.join(' ') !== nextOrganizations.join(' ')){
      organizations = nextOrganizations
    }
    if(_organizations.length != 0){
      organizationCount = Object(Object(_.sortBy(organizations, 'id').reverse())[0]).id
    }

    let firstPersonToCollege = this.state.firstPersonToCollege
    if(_.get(this.props, 'survey.personalInformation.firstPersonToCollege') == _.get(nextProps, 'survey.personalInformation.firstPersonToCollege')){
      firstPersonToCollege = _.get(nextProps, 'survey.personalInformation.firstPersonToCollege')
    }

    if(_languages.join(' ') !== nextLanguages.join(' ')){
      languages = nextLanguages
    }
    if(_languages.length != 0){
      languageID = _.sortBy(_languages, 'id').reverse()[0].id
    }

    let mother = []
    if(_.get(nextProps, 'survey.personalInformation.mother', []) != _.get(this, 'survey.personalInformation.mother', [])){
      mother = _.get(nextProps, 'survey.personalInformation.mother', [])
    }
    
    let father = [];
    if(_.get(nextProps, 'survey.personalInformation.father', []) != _.get(this, 'survey.personalInformation.father', [])){
      father = _.get(nextProps, 'survey.personalInformation.father', [])
    }

    this.setState({
      languages,
      languageID,
      organizations,
      organizationCount,
      firstPersonToCollege
    })
    
  }
  addLanguage(){
    const languageID = this.state.languageID + 1
    this.setState({
      languages: [...this.state.languages, {id: languageID}],
      languageID
    }, ()=>{
      this[`language-${this.state.languages.length - 1}`].input.focus()
    })
  }
  removeFromLanguage(i){
    const languages = [...this.state.languages]
    languages.splice(i, 1);
    
    this.setState({
      languages
    })
  }
  handleFirstPersonToCollege(value){
    this.setState({
      firstPersonToCollege: value
    })
  }

  removeOrganization(id){
    let organizations = [...this.state.organizations]
    organizations = organizations.filter((row)=> row.id != id)
    
    this.setState({
      organizations
    })
  }
  handleOrganization(type, e){
    e && e.preventDefault && e.preventDefault()

    const organizationCount = this.state.organizationCount + 1
    
    this.setState({
      organizations: [...this.state.organizations, {id: organizationCount}],
      organizationCount
    }, ()=>{
      const i = this.state.organizations.length - 1
      if(_.get(this[`organization-${type}-${organizationCount}`],'input')){
        _.invoke(this[`organization-${type}-${organizationCount}`], 'input.focus')
      }else{
        _.invoke(this[`organization-${type}-${organizationCount}`], 'focus')
        this.setState({
          [`organization-dates-open-${organizationCount}`]: true
        })
      }
    })
  }
  handleOpenCloseOrganizationDates(i, value){
    this.setState({
      [`organization-dates-open-${i}`]: value
    })
  }
  legacyMother(ids){
    this.setState({
      mother: ids
    })
  }
  legacyFather(ids){
    this.setState({
      father: ids
    })
  }
  get favorites(){
    return _.get(this, 'survey.favorites', [])
  }
  render() {
    window.currentState = this.props

    const selectedNames = _.get(this, 'props.survey.favorites', []).map((school)=> school.name)
    let data = this.props.schools
    data = (data || []).filter((school)=>
      !selectedNames.includes(school.name)
    ).slice(0, this.state.end)

    const grades =[
      {name: 9, value: 9},
      {name: 10, value: 10},
      {name: 11, value: 11},
      {name: 12, value: 12}
    ]
    
   const rank =   [{
      name: 'Top 1',
      value: 1
    },
    {
      name: 'Top 5',
      value: 5
    },
    {
      name: 'Top 10',
      value: 10
    },
    {
      name: 'Top 25',
      value: 25
    },
    {
      name: 'Top 50',
      value: 50
    },
    {
      name: 'Not Available',
      value: -1
    },
    {
      name: 'Decline to Answer',
      value: -2
    }]

    const year = parseInt(moment().format('YYYY'))
    const years = utils.times(5).map((i)=>({ name: year + i, value: year + i}))

    const plainOptions = ['Apple', 'Pear', 'Orange'];
    const options = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange' },
    ];
    const optionsWithDisabled = [
      { label: 'Apple', value: 'Apple' },
      { label: 'Pear', value: 'Pear' },
      { label: 'Orange', value: 'Orange', disabled: false },
    ];    
    
    return (
        <Form name={'PersonalInformation'}>
          <Row gutter={20}>
            <Col xs={24}>
              <Card>
                <h1 style={{textAlign: 'center'}}>Personal personalInformation</h1>
                <br />
                <Row gutter={18}>
                  <Col md={12} xs={24}>
                    <h3>First Name</h3>
                    <Form.Item 
                      name='firstName'
                      rules={[{ required: true, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.firstName')}
                    >

                      <Input placeholder='First Name' />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <h3>Last Name</h3>
                    <Form.Item
                      name='lastName'
                      rules={[{ required: true, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.lastName')}
                    >
                      <Input placeholder='Last Name' />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <h3>Zipcode</h3>
                <Row gutter={18}>
                  <Col md={12} xs={24}>
                    <Form.Item
                      name='zipcode'
                      rules={[{ required: true, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.zipcode')}
                    >
                      <Input placeholder='Zipcode' />
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <Row gutter={18}>
                  <Col md={12} xs={24}>
                    <h3>Gender (Optional)</h3>
                    <br />
                    <Form.Item
                      name='gender'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.gender')}
                    >
                      <Select placeholder='Gender'>
                        <Option value="">Gender</Option>
                        <Option value="Prefer Not to Answer">Prefer Not to Answer</Option>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Non Binary">Non Binary</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24}>
                    <h3>Ethnicity (Optional)</h3>
                    <br />
                    <Form.Item
                      name='ethnicity'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.ethnicity')}
                    >
                      <Select placeholder='Ethnicity'>
                        <Option value="">Ethnicity</Option>
                        <Option value="Prefer Not to Answer">Prefer Not to Answer</Option>
                        <Option value="Asian">Asian</Option>
                        <Option value="African American">African American</Option>
                        <Option value="Hispanic or Latino">Hispanic or Latino</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <br />
                <br />
                <h3>Languages</h3>
                {this.state.languages.map((language, i)=>
                  <Row gutter={18} key={`languages-${language.id}`}>
                    <Col span={24}>
                      <Button type='unstyled' onClick={this.removeFromLanguage.bind(null, i)}>
                        <CloseCircleOutlined />
                      </Button>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={`language-${language.id}`}
                        rules={[{ required: true, message: 'Please input a valid value.' }]}
                        initialValue={_.get(this,  `props.survey.personalInformation['language-${language.id}']`)}
                      >
                        <Input ref={(_)=> this[`language-${i}`] = _ } placeholder='Language' />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <br />
                <Row gutter={18}>
                  <Col span={24}>
                  </Col>
                  <Col span={24}>
                    <Input onFocus={this.addLanguage} placeholder='Language' />
                  </Col>
                </Row>
                <br />
                <br />
                <br />
                <h2 className='text-center'>
                  Leadership Qualities
                </h2>
                <br />
                <div className='text-center'>
                  <Form.Item 
                    name='leadership'
                    rules={[{ required: true, message: 'Please input a valid value.' }]}
                    initialValue={_.get(this,  `props.survey.personalInformation.leadership`)}
                  >

                    <RadioGroup>
                      <Radio value={1}>1</Radio>
                      <Radio value={2}>2</Radio>
                      <Radio value={3}>3</Radio>
                      <Radio value={4}>4</Radio>
                      <Radio value={5}>5</Radio>
                    </RadioGroup>
                  </Form.Item>
                </div>
                <br />
                Leadership qualities are demonstrated in various ways. Have you won any awards or recognition at or outside school? 
                Do you have a leadership position in any school or extra-curricular activity or group? 
                An example of the highest level of leadership quality would be an individual who has won multiple awards, 
                is the captain of at least one sports team, and holds a leadership position within another school/extracurricular activity. 
                Another example is someone who has demonstrated extraordinary civic or volunteer activities, 
                going so far above and beyond others so as to be recognized for his/her efforts outside the school system.
                <br />
                <br />
                <br />
                <h2 className='text-center'>
                  Jobs/Internships
                </h2>
                {this.state.organizations.map((row, i)=>
                  <React.Fragment key={`organization-${row.id}`}>
                    <br />
                    <Row gutter={12}>
                      <Col xs={24}>
                        <Button type='unstyled' onClick={this.removeOrganization.bind(null, row.id)}>
                        <CloseCircleOutlined />
                        </Button>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name={`organizationName${row.id}`}
                          rules={[{ required: true, message: 'Please input a valid value.' }]}
                          initialValue={_.get(this,  `props.survey.personalInformation['organizationName${row.id}']`)}
                        >
                          <Input placeholder='Organization Name' ref={(a)=>{ this[`organization-name-${row.id}`] = a}} />
                        </Form.Item>
                        <br />
                        <br />
                        <Form.Item
                          name={`organizationDates${row.id}`}
                          rules={[{ required: true, message: 'Please input a valid value.' }]}
                          initialValue={_.get(this,  `props.survey.personalInformation['organizationDates${row.id}']`, []).map((a)=>moment(a))}
                        >
                          <RangePicker 
                            ref={(_)=>{ this[`organization-dates-${row.id}`] = _}} 
                            open={this.state[`organization-dates-open-${row.id}`]}
                            onOpenChange={this.handleOpenCloseOrganizationDates.bind(null, row.id)} 
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <br />
                    <br />
                  </React.Fragment>
                )}
                <Row gutter={12}>
                  <br />
                    <Col span={24}>
                    </Col>
                    <Col span={24}>
                      <Input placeholder='Organization Name' onClick={this.handleOrganization.bind(null, 'name')} />
                      <br />
                      <br />
                      <div className='cover-input'>
                        <RangePicker  />
                        <div className='cover' onClick={this.handleOrganization.bind(null, 'dates')} ></div>
                      </div>
                      <br />
                      <br />
                    </Col>
                </Row>
                <br />
                <h2 className='text-center'>Awards</h2>
                <div className='text-center'>
                  <br />
                  <div className='text-left display-block'>
                    <Form.Item
                      name='schoolAwards'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.schoolAwards')}
                      valuePropName='checked'
                    >
                      <Checkbox defaultChecked={_.get(this, 'props.survey.personalInformation.schoolAwards')}>
                        School Awards
                      </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item
                      name='localOrganizations'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.localOrganizations')}
                      valuePropName={'checked'}
                    >
                      <Checkbox defaultChecked={_.get(this, 'props.survey.personalInformation.localOrganizations')}>
                        Local Organizations
                      </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item
                      name='stateRegional'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.stateRegional')}
                      valuePropName='checked'
                    >
                      <Checkbox defaultChecked={_.get(this, 'props.survey.personalInformation.stateRegional')}>
                        State/Regional
                      </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item
                      name='organizationAwards'
                      rules={[{ required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.organizationAwards')}
                      valuePropName='checked'
                    >
                      <Checkbox defaultChecked={_.get(this, 'props.survey.personalInformation.organizationAwards')}>
                        Organizations
                      </Checkbox>
                    </Form.Item>
                    <br />
                    <Form.Item
                      name='nationalOrganizations'
                      rules={[{required: false, message: 'Please input a valid value.' }]}
                      initialValue={_.get(this, 'props.survey.personalInformation.nationalOrganizations')}
                      valuePropName='checked'
                    >
                      <Checkbox defaultChecked={_.get(this, 'props.survey.personalInformation.nationalOrganizations')}>
                        National Organizations
                      </Checkbox>
                    </Form.Item>
                  </div>
                </div>
                <br />
                <br />

                <h2 className='text-center'>Legacy Information</h2>
                <h3>
                  First Generation            
                </h3>
                Are you the first person in your immediate family to apply to college? &nbsp;
                <Form.Item
                  name='firstPersonToCollege'
                  rules={[{ required: false, message: 'Please input a valid value.' }]}
                  initialValue={_.get(this, 'props.survey.personalInformation.firstPersonToCollege')}
                >
                  <Switch checkedChildren="Yes" defaultChecked={!!_.get(this, 'props.survey.personalInformation.firstPersonToCollege')} unCheckedChildren="No" onChange={this.handleFirstPersonToCollege} />
                </Form.Item>
                <br />
                <br />

                {this.state.firstPersonToCollege || (
                  <React.Fragment>
                    <h3>
                      Legacy
                    </h3>
                    Have one or both of your parents attended one of the schools you're applying to?
                    <br />
                    
                    <Row gutter={18}>
                      <Col lg={12} sm={12} xs={24}>
                        <div className='text-center'>
                          <br />
                          <br />
                          <strong>
                            Father
                          </strong>
                        </div>
                          <Checkbox.Group style={{ width: '100%' }} onChange={this.legacyFather} defaultValue={this.father}>
                            {_.get(this, 'props.survey.favorites', []).map((favorite, i)=>(
                              <Row>
                                <Col>
                                  <Form.Item
                                    rules={[{ required: false, message: 'Please input a valid value.' }]}
                                    valuePropName='checked'
                                  >
                                    <Checkbox value={favorite.id}>{favorite.name}</Checkbox>
                                  </Form.Item>
                                </Col>
                              </Row>
                            ))}
                          </Checkbox.Group>
                      </Col>
                      <Col lg={12} sm={12} xs={24}>
                        <div className='text-center'>
                          <br />
                          <br />
                          <strong className='text-center'>
                            Mother
                          </strong>
                        </div>
                          <Checkbox.Group style={{ width: '100%' }} onChange={this.legacyMother} defaultValue={this.mother}>
                            {this.favorites.map((favorite, i)=>(
                              <Row>
                                <Col>
                                  <Form.Item 
                                    rules={[{ required: false, message: 'Please input a valid value.' }]}
                                    valuePropName='checked'
                                  >
                                    <Checkbox value={favorite.id}>{favorite.name}</Checkbox>
                                  </Form.Item>
                                </Col>
                              </Row>
                            ))}
                          </Checkbox.Group>
                      </Col>
                    </Row>
    
    
                  </React.Fragment>
                )}

                {/* <Checkbox value={1}>
                  Are you the first person in your family to apply to college?
                </Checkbox> */}

                {/* Have one or both of your parents attended one of the schools you're applying to? */}

                <br />
              </Card>  
            </Col>
          </Row>
        
        </Form>
    )
  }
}
const connected = connect(()=>({
  addToFavorites: favorites.addToFavorites,
  removeFromFavorites: favorites.removeFromFavorites
}))(PersonalInformation)

export default connected
