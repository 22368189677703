import React from 'react'
import Dropdown from '../Dropdown'

export default (props = {})=>{
    let data = [];
    for(let i = 36; i !== 0; i-- ){
        data.push({
            value: i,
            name: i
        })
    }

    return <Dropdown 
        data={data}
        onChange={props.onChange}
        fullWidth={props.fullWidth}
        type={props.type} 
        value={props.value}
        placeholder={props.placeholder || 'Select ACT'}
    />
}